import React, { ReactNode } from "react";
import { Badge, Flex, FlexProps } from "@chakra-ui/react";

interface InstructionStepProps {
  i18nKey?: string;
  stepNumber: number;
  children: ReactNode;
  flexProps?: FlexProps;
}

export const InstructionStep: React.FC<InstructionStepProps> = ({ i18nKey, stepNumber, children, flexProps }) => (
  <Flex {...flexProps}>
    <Badge variant="dominoInstructionsNumberList">{stepNumber}</Badge>
    {children}
  </Flex>
);
