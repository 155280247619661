import { List, ListItem, Button, Text } from "@chakra-ui/react";
import { BotFatherLink } from "../../../../../UI/atoms/botFatherLink/BotFatherLink";
import s from "../AddFirstGroupChatContainer.module.scss";
import { InstructionStep } from "../../../../../UI/atoms/instructionStep/InstructionStep";
import { TranslationWithStrongWord } from "../../../../../UI/atoms/textTranslation/TextTranslation";
import { useTranslation } from "react-i18next";

export const SettingBotStep = ({ action }: { action?: () => void }) => {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  return (
    <>
      <List className={s.instructionStepsList} spacing={4}>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={1}>
            <BotFatherLink i18nKey="groupChat.settingBot.First step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={2}>
            <TranslationWithStrongWord i18nKey="groupChat.settingBot.Seconde step">
              <Text as="span" className={s.strongWord} />
            </TranslationWithStrongWord>
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={3}>
            <TranslationWithStrongWord i18nKey="groupChat.settingBot.Third step">
              <Text as="span" className={s.strongWord} />
            </TranslationWithStrongWord>
          </InstructionStep>
        </ListItem>
      </List>
      <Button onClick={action} w="100%" mt="8px" variant="dominoViolet">
        {commonWords("Continue")}
      </Button>
    </>
  );
};
