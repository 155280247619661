/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../../common/state/store";
import { GroupReplyModel } from "../../../../../../GroupChatModel";

export interface GroupChatTextAreaBlockState {
  replyMode: GroupReplyModel;
}

const initialState: GroupChatTextAreaBlockState = {
  replyMode: { enabled: false },
};

export const groupChatTextAreaBlockSlice = createSlice({
  name: "groupChatTextAreaBlockSlice",
  initialState,
  reducers: {
    setReplyModeState: (state, action: PayloadAction<GroupReplyModel>) => {
      if (state.replyMode.message?.id !== action.payload.message?.id) {
        return {
          ...state,
          replyMode: action.payload,
        };
      }
    },
  },
});

export const { setReplyModeState } = groupChatTextAreaBlockSlice.actions;

export const selectReplyMode = (state: RootState) => state.app.groupChatTextAreaBlockState.replyMode;

export default groupChatTextAreaBlockSlice.reducer;
