/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/state/store";
import { ChatListFilterTypes, ChatListModel, ChatListSortEnum } from "../../../chatList/ChatListModel";
import { GroupChatListItemModel, GroupChatListModel } from "../../GroupChatModel";

export interface GroupChatListState {
  chatList?: GroupChatListModel;
  totalItems?: number;
  botId?: string;
  canUploadConversations?: boolean;
  search: string;
}

const initialState: GroupChatListState = {
  search: "",
};

export const groupChatListSlice = createSlice({
  name: "groupChatListSlice",
  initialState,
  reducers: {
    getGroupChatList: (
      state,
      action: PayloadAction<{
        botId: string;
        search: string;
        sort?: ChatListSortEnum;
        filter?: { type: ChatListFilterTypes; teamId?: string };
        lastId: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getGroupChatListCompleted: (state, action: PayloadAction<GroupChatListModel>) => {
      return {
        ...state,
        chatList: action.payload,
      };
    },
    getGroupChatListCount: (
      state,
      action: PayloadAction<{
        botId: string;
        search: string;
        sort?: ChatListSortEnum;
        filter?: { type: ChatListFilterTypes; teamId?: string };
        lastId: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getGroupChatListCountCompleted: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        totalItems: action.payload,
      };
    },
    updateConversationsUploading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        canUploadConversations: action.payload,
      };
    },
    setSearchGroupChatList: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        search: action.payload,
        chatList: undefined,
      };
    },
    clearStateGroupChatListInfo: state => {
      return {
        ...initialState,
      };
    },
    addNewConversation: (state, action: PayloadAction<GroupChatListItemModel>) => {
      return {
        ...state,
      };
    },
    addChat: (state, action: PayloadAction<GroupChatListItemModel>) => {
      const chatList = state?.chatList;
      return {
        ...state,
        chatList: chatList
          ? {
              ...chatList,
              items: [action.payload, ...chatList.items],
              totalItems: chatList.totalItems + 1,
            }
          : undefined,
      };
    },
    removeChat: (state, action: PayloadAction<GroupChatListItemModel>) => {
      const chatList = state?.chatList;
      return {
        ...state,
        chatList: chatList
          ? {
              ...chatList,
              items: [...chatList.items?.filter(item => item.id !== action.payload.id)],
              totalItems: chatList.totalItems > 1 ? chatList.totalItems - 1 : 0,
            }
          : undefined,
      };
    },
    updateChatInfo: (state, action: PayloadAction<GroupChatListItemModel>) => {
      return {
        ...state,
        chatList: state?.chatList
          ? {
              ...state?.chatList,
              items: state?.chatList?.items?.map(item => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
            }
          : undefined,
      };
    },
    clearGroupChatList: state => {
      return { ...initialState };
    },
  },
});

export const {
  getGroupChatList,
  getGroupChatListCount,
  setSearchGroupChatList,
  getGroupChatListCompleted,
  getGroupChatListCountCompleted,
  updateConversationsUploading,
  clearStateGroupChatListInfo,
  addChat,
  removeChat,
  addNewConversation,
  updateChatInfo,
  clearGroupChatList,
} = groupChatListSlice.actions;

export const selectGroupChatList = (state: RootState) => state.app.groupChatListState.chatList;
export const selectSearchGroupChatList = (state: RootState) => state.app.groupChatListState.search;
export const selectTotalItems = (state: RootState) => state.app.groupChatListState.totalItems;
export const selectCanUpload = (state: RootState) => state.app.groupChatListState.canUploadConversations;

export default groupChatListSlice.reducer;
