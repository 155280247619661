import { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ChatListHeader } from "../../../../chatList/components/ChatListHeader";
import { ChatPage } from "../../../../pages/chatListPage/ChatListPage";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import * as vars from "../../../../../components/layout/LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../../../LayoutHelper/ResolutionHooks";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../../organisation/OrganisationSlice";
import { clearConversationState } from "../../../../conversation/ConversationSlice";

export const ConversationPage = () => {
  const dispatch = useAppDispatch();
  const nowWidth = useNowWidthView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  useEffect(() => {
    return () => {
      dispatch(clearConversationState());
    };
  }, []);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <ChatListHeader />
      </ProtectedHeaderContainer>
      <Box
        bg="bgLight"
        height={
          nowWidth <= vars.TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= vars.MOBILE_VIEW ? "180px" : "120px"})`
            : "calc(100dvh - 60px)"
        }
      >
        <ChatPage />
      </Box>
    </Flex>
  );
};
