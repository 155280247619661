import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Button,
  Flex,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./SideBarQuestionModal.module.scss";
import { ChangeEvent, useState } from "react";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { notify } from "../../../../common/notifications/NotificationSlice";
import { NotificationModel } from "../../../../common/notifications/NotificationModel";
import { useAppDispatch } from "../../../../common/state/store";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
}

export const SidebarQuestionModal = ({ isOpenModal, setIsOpenModal }: Props) => {
  const { t } = useTranslation("questionModal");
  const dispatch = useAppDispatch();

  const values = [
    { value: "Words", label: t("Control of obscene properties") },
    { value: "Payment", label: t("Exclusion of those who have reached the end of the payment period") },
    { value: "Instruction", label: t("Onboarding for new users") },
  ];
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const trackEvent = useGAWithCustomParameters(EventCategories.Group);

  const onClose = () => {
    setCheckedItems([]);
    setInputValue("");
    setIsOpenModal(false);
  };

  const onCloseSendEvent = () => {
    onClose();
    const notification: NotificationModel = {
      message: t("Thank you! Your reply has been successfully sent."),
      type: "success",
      duration: 5000,
    };
    dispatch(notify(notification));
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? setCheckedItems([...checkedItems, e.target.value])
      : setCheckedItems(checkedItems.filter(it => it !== e.target.value));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCancelClick = () => {
    trackEvent("Group_Cancel", "", { cancel_type: "CancelButton" });
    onClose();
  };

  const handleSendClick = () => {
    let parameters: unknown[] = [];
    if (checkedItems.length > 0) {
      parameters = [...checkedItems];
    }

    if (inputValue !== "") {
      parameters = [...parameters, inputValue];
    }
    trackEvent("Group_Confirm", "", { groupbot_details: [...parameters] });
    onCloseSendEvent();
  };

  return (
    <Modal isOpen={isOpenModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className={s.Modal}>
        <ModalHeader className={s.ModalHeader}>{t("We will launch group chats soon!")}</ModalHeader>
        <ModalCloseButton
          className={s.CloseButton}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          onClick={() => {
            trackEvent("Group_Cancel", "", { cancel_type: "CloseButton" });
          }}
        />
        <ModalBody className={s.ModalBody}>
          <Box marginBottom={"16px"}>
            <Text className={s.ModalInfo}>{t("We barely managed to implement group chats.")}</Text>
            <Text className={s.ModalInfo}>{t("We will notify you when everything is ready.")}</Text>
          </Box>
          <Text marginBottom={"14px"}>{t("Choose the topics you are interested in")}:</Text>
          <Flex flexDirection={"column"}>
            {values.map(val => {
              return (
                <Flex key={val.value} className={s.InputGroup}>
                  <Checkbox
                    data-pw="group-chat-select-item"
                    variant="dominoGreen"
                    id={val.value}
                    className={s.InputGroup_checkbox}
                    value={val.value}
                    onChange={onCheckboxChange}
                    _hover={{ backgroundColor: "defaultGrey" }}
                    _focus={{ border: "none" }}
                  >
                    <Text className={s.InputGroup_label}>{val.label}</Text>
                  </Checkbox>
                </Flex>
              );
            })}
          </Flex>
          <Input
            data-pw="group-chat-input"
            placeholder={t("Enter your own version")}
            onChange={onInputChange}
            type="text"
            maxLength={50}
          />
        </ModalBody>
        <ModalFooter className={s.ModalFooter}>
          <Flex className={s.ButtonBlock}>
            <Button variant="dominoOutlineViolet" onClick={handleCancelClick}>
              {t("Cancel")}
            </Button>
            <Button variant="dominoViolet" isDisabled={checkedItems.length === 0 && inputValue === ""} onClick={handleSendClick}>
              {t("Send")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
