import { Box, Center } from "@chakra-ui/react";
import { NodeEditorSpinner } from "./components/NodeEditorSpinner/NodeEditorSpinner";
import { NodeSpinner } from "./components/NodeSpinner/NodeSpinner";
import { ScopeSpinner } from "./components/ScopeSpinner/ScopeSpinner";
import { useGetLoadingState } from "./hooks/useGetLoadingState";
import s from "./Loading.module.scss";
import { MessageListSpinner } from "./components/MessageListSpinner/MessageListSpinner";
import { Dispatch, SetStateAction } from "react";
import { ScopeSpinnerBox } from "./components/ScopeSpinnerBox/ScopeSpinnerBox";

interface Props {
  scope?: string;
  hidden?: boolean;
  isActive?: boolean;
  setIsActive?: Dispatch<SetStateAction<boolean>>;
}

export const Loading = (props: Props) => {
  const scope = props.scope || "default";
  const showSpinner = useGetLoadingState(scope);

  // useEffect(() => {
  //   if (props.setIsActive && scope === "contactList2") {
  //     props.setIsActive(showSpinner);
  //   }
  // }, [props, scope, showSpinner]);

  if (scope.startsWith("postFileEditor") || scope.startsWith("loadYamlFile")) {
    return <>{showSpinner && <NodeEditorSpinner />}</>;
  }

  if (scope.startsWith("flowBuilder")) {
    return (
      <>
        {showSpinner && (
          <div className={s.overlayOld} data-pw="loading">
            <Center>
              <svg className={s.spinner} viewBox="0 0 50 50">
                <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
            </Center>
          </div>
        )}
      </>
    );
  }

  if (scope.startsWith("postFile")) {
    return <>{showSpinner && <NodeSpinner />}</>;
  }

  if (scope === "importContacts" || scope === "messageFileUpload" || scope === "importFlow") {
    return (
      <>
        {showSpinner && (
          <Center position={"static"} data-pw="loading">
            <svg className={s.spinner} viewBox="0 0 50 50">
              <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </Center>
        )}
      </>
    );
  }

  if (scope === "contactList2") {
    if (props.setIsActive && scope === "contactList2") {
      props.setIsActive(showSpinner);
    }
    return <>{showSpinner && <ScopeSpinnerBox />}</>;
  }

  if (scope === "messageList" || scope === "messageGroupList") {
    return <>{showSpinner && <MessageListSpinner />}</>;
  }

  if (scope === "getChatList") {
    return <>{showSpinner && <MessageListSpinner isBlock />}</>;
  }

  if (
    scope === "getFlowTemplates" ||
    scope === "deleteFlow" ||
    scope === "getBotVariablesInFlow" ||
    scope === "checkVariableUsage"
  ) {
    return <>{showSpinner && <ScopeSpinnerBox />}</>;
  }

  if (scope === "getContactList") {
    return (
      <>
        {showSpinner && (
          <Box w="100%" className={s.overlay} data-pw="loading">
            <Center>
              <svg className={s.spinner} viewBox="0 0 50 50">
                <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>
            </Center>
          </Box>
        )}
      </>
    );
  }

  if (scope === "Broadcast") {
    return <>{showSpinner && <Box w="100%" className={s.overlay} data-pw="loading"></Box>}</>;
  }

  if (scope !== "default") {
    return <>{showSpinner && <ScopeSpinner />}</>;
  }

  return (
    <>
      {showSpinner && (
        <div className={s.overlay} data-pw="loading">
          <Center>
            <svg className={s.spinner} viewBox="0 0 50 50">
              <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </Center>
        </div>
      )}
    </>
  );
};
