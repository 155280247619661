import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
  Box,
  Tooltip,
  Show,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { ReactComponent as AutomationIcon } from "../../assets/icons/sidebar/automationIcon.svg";
import { ReactComponent as ContactsIcon } from "../../assets/icons/sidebar/contactsIcon.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/sidebar/chatIcon.svg";
import { ReactComponent as BotIcon } from "../../assets/icons/sidebar/botIcon.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/sidebar/dashboard.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/sidebar/sendIcon.svg";
import { ReactComponent as DropArrowMenu } from "../../assets/images/dropArrow.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/sidebar/helpIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/sidebar/profile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/sidebar/logout.svg";
import { ReactComponent as DobleArrow } from "../../assets/icons/sidebar/dobleArrow.svg";
import { ReactComponent as Organisation } from "../../assets/icons/sidebar/organisation.svg";
import { ReactComponent as UserManagement } from "../../assets/icons/sidebar/userManagement.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/alertIconCircle.svg";
import { ReactComponent as GroupChat } from "../../assets/icons/sidebar/groupChat.svg";
import { ReactComponent as Plans } from "../../assets/icons/sidebar/plans.svg";
import s from "./Sidebar.module.scss";
import { useEffect, ReactNode, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  getAllOrganisation,
  getBots,
  selectBots,
  selectCurrentBot,
  selectIsCurrentBotLoading,
  selectOrganisations,
} from "./SidebarSlice";
import { BotModel } from "./SidebarModel";
import { useTranslation } from "react-i18next";
import { getSideBarIsOpenFromStorage, setSideBarIsOpenToStorage } from "./SidebarSaga";
import { getBillingPlan, selectIsImpersonated, selectUser } from "../../common/user/UserSlice";
import { stopImpersonation } from "../userManagement/UserManagementSlice";
import { getTariffPlanAccessability } from "../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import { selectIsOrganizationTrial, selectOrganisation, selectTrialPlan } from "../organisation/OrganisationSlice";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { NewOrganisationModal } from "../organisation/components/NewOrganisationModal/NewOrganisationModal";
import { SystemNotificationContainer } from "../../common/systemNotifications/SystemNotificationContainer";
import { SidebarCurrentBot } from "./components/SidebarCurrentBot/SidebarCurrentBot";
import { SideBarBotsLimitPopover } from "./components/SideBarBotsLimitPopover/SideBarBotsLimitPopover";
import { SideBarCreateNewBot } from "./components/SideBarCreateNewBot/SideBarCreateNewBot";
import { SideBarBotList } from "./components/SideBarBotList/SideBarBotList";
import { SideBarMenuPanelMobile } from "./components/SideBarMenuPanelMobile/SideBarMenuPanelMobile";
import { SideBarBurgerLinesMobile } from "./components/SideBarBurgerLinesMobile/SideBarBurgerLinesMobile";
import { SideBarBotLoading } from "./components/SideBarBotLoading/SideBarBotLoading";
import { InputSearch } from "../../UI/molecules/inputSearch/InputSearch";
import { SidebarPlugins } from "./SidebarPluginsComponent";
import { setOrganization } from "../organisation/OrganisationSlice";
import useDebounce from "../../common/hooks/useDebounce";
import { useTabletView } from "../layout/LayoutHelper/ResolutionHooks";
import { TrialPlanComponent } from "../organisation/components/TrialPlan/TrialPlanComponent";
import { selectTours, startTour } from "../onboardingTour/OnboardingToursSlice";
import { FLOW_BUILDER_TOUR_NAME, GENERAL_TOUR_NAME } from "../onboardingTour/toursConstants";
import { useGAWithCustomParameters } from "../../common/ga/GAEventTracker";
import { AppSettings } from "../../common/AppSettings";
import { SidebarQuestionModal } from "./components/SidebarQuestionModal/SideBarQuestionModal";
import { Tour } from "../onboardingTour/OnboardingToursState";
import { logoutViewer } from "../conversationViewer/ConversationViewerSlice";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

declare const appSettings: AppSettings;
export interface NavigationLink {
  icon: ReactNode;
  label: string;
  to?: string;
  onClick?: (() => void) | ((value: any) => void);
  isActive?: boolean;
  dataPw?: string;
  targetClass?: string;
  target?: string;
}

interface Props {
  isSuperAdmin: boolean;
}

export const SidebarContainer = ({ isSuperAdmin }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const currentBot = useAppSelector(selectCurrentBot);
  const botList = useAppSelector(selectBots);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const [isOpenSideBar, setIsOpenSideBar] = useState(getSideBarIsOpenFromStorage() ?? true);
  const [mobileOpenMenu, setMobileOpenMenu] = useState(false);
  const botsCount = botList?.totalItems;

  const tours = useAppSelector(selectTours);
  const generalTourState = tours?.find((t: Tour) => t.tourName === GENERAL_TOUR_NAME);
  const showGeneralTour =
    botsCount === 1 && !generalTourState?.isCompleted && !generalTourState?.run && !generalTourState?.isSkipped;
  const showGereralHelpIndicator = !generalTourState
    ? true
    : generalTourState && !generalTourState.isCompleted && !generalTourState?.run;

  const isFlowOpen = window.location.pathname.startsWith("/automation/flows/");
  const flowBuilderTourState = tours?.find(tour => tour.tourName === FLOW_BUILDER_TOUR_NAME);
  const showFlowBuilderHelpIndicator = !flowBuilderTourState
    ? true
    : flowBuilderTourState && !flowBuilderTourState?.isCompleted && !flowBuilderTourState?.run;

  const showOnboardingTourIndicator = isFlowOpen ? showFlowBuilderHelpIndicator : showGereralHelpIndicator;
  const isSidebarOrOnboarding =
    generalTourState?.run === true ||
    (botList?.totalItems === 1 && !generalTourState?.isSkipped && !generalTourState?.isCompleted);

  const organisation = useAppSelector(selectOrganisation);
  const isImpersonated = useAppSelector(selectIsImpersonated);
  const allOrganisations = useAppSelector(selectOrganisations);
  const organisations = useAppSelector(selectOrganisation);
  const [visibleToolBot, setVisibleToolBot] = useState(true);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const loadingBots = useGetLoadingState("getBotsSidebar");
  /*  a crutch that corrects google auto-filling */
  const { isOpen: isOrganisationModalOpen, onOpen: onNewOrganisation, onClose: onOrganisationModalClose } = useDisclosure();
  const debouncedSearchText = useDebounce(search, 500);
  const searchRef = useRef(search);
  const trialPlan = useAppSelector(selectTrialPlan);
  const isTablet = useTabletView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BotCount);
  const { MaxCount, IsUnlimited } = accessability;
  //GA EVENTS
  const eventName = !isFlowOpen ? EventCategories.OnboardingOne : EventCategories.OnboardingTwo;
  const trackEvent = useGAWithCustomParameters(eventName);
  //const trackGroupEvent = useGAWithCustomParameters("Group");
  const trackBillingEvent = useGAWithCustomParameters("PlansBillings");
  const [isOpenQuestionModal, setIsOpenQuestionModal] = useState<boolean>(false);

  const botScopeLinks: NavigationLink[] = [
    {
      icon: (
        <Flex w="100%" alignItems={"center"}>
          <Icon as={AutomationIcon} boxSize="18px" mr="20px" />
          <Text>{t("Automation")}</Text>
        </Flex>
      ),
      label: `${t("Automation")}`,
      to: "/automation/flows",
      isActive: location.pathname.startsWith("/automation") ? true : false,
      dataPw: "automation",
      targetClass: "automation",
    },
    {
      icon: (
        <Flex w="100%">
          <Icon as={ChatIcon} boxSize="18px" mr="20px" />
          <Text>{t("Live Chat")}</Text>
        </Flex>
      ),
      label: `${t("Live Chat")}`,
      to: "/chats",
      isActive: location.pathname.startsWith("/chats") ? true : false,
      dataPw: "chats",
      targetClass: "chats",
    },
    ...(appSettings.showGroupChats
      ? [
          {
            icon: (
              <Flex className={s.GroupIcon}>
                <Icon as={GroupChat} boxSize="18px" mr="20px" />
                <Text>{t("Group Chats")}</Text>
              </Flex>
            ),
            label: `${t("Group Chats")}`,
            to: "/groupChats",
            isActive: location.pathname.startsWith("/groupChats") ? true : false,
            dataPw: "groupChat",
            targetClass: "groupChat",
            // onClick: () => {
            //   setIsOpenQuestionModal(true);
            //   trackGroupEvent("Group");
            // },
          },
        ]
      : []),
    {
      icon: (
        <Flex w="100%">
          <Icon as={SendIcon} boxSize="18px" mr="20px" />
          <Text>{t("Broadcasting")}</Text>
        </Flex>
      ),
      label: `${t("Broadcasting")}`,
      to: "/broadcasts",
      isActive: location.pathname.startsWith("/broadcasts") ? true : false,
      dataPw: "broadcasting",
      targetClass: "broadcasting",
    },
  ];

  const organisationScopeLinks: NavigationLink[] = [
    {
      icon: (
        <Flex w="198px">
          <Icon as={Organisation} boxSize="18px" mr="20px" />
          <Text>{t("Organisation")}</Text>
        </Flex>
      ),
      label: `${t("Organisation")}`,
      to: "/organisation/operators",
      isActive: location.pathname.startsWith("/organisation") ? true : false,
      dataPw: "organisation",
      targetClass: "organisation",
    },
    {
      icon: (
        <Flex w="100%">
          <Icon as={ContactsIcon} boxSize="18px" mr="20px" />
          <Text>{t("Contacts")}</Text>
        </Flex>
      ),
      label: `${t("Contacts")}`,
      to: "/contacts",
      isActive: location.pathname.startsWith("/contacts") ? true : false,
      dataPw: "contacts",
      targetClass: "contacts",
    },
    {
      icon: (
        <Flex w="100%">
          <Icon as={BotIcon} boxSize="18px" mr="20px" />
          <Text>{t("Bots")}</Text>
        </Flex>
      ),
      label: `${t("Bots")}`,
      to: "/bots",
      isActive: location.pathname.startsWith("/bot") ? true : false,
      dataPw: "bot",
      targetClass: "bot",
    },
    {
      icon: (
        <Flex w="100%">
          <Icon as={Dashboard} boxSize="18px" mr="20px" />
          <Text>{t("Dashboard")}</Text>
        </Flex>
      ),
      label: `${t("Dashboard")}`,
      to: "/dashboard",
      isActive: location.pathname.startsWith("/dashboard") ? true : false,
      dataPw: "dashboard",
      targetClass: "dashboard",
    },
    {
      icon: (
        <Flex w="100%">
          <Icon as={Plans} boxSize="18px" mr="20px" />
          <Text>{t("Plans and Billing")}</Text>
        </Flex>
      ),
      label: `${t("Plans and Billing")}`,
      to: appSettings.billingPortalUrl,
      onClick: () => {
        trackBillingEvent("PlansBillings_Info");
      },
      dataPw: "plans",
      targetClass: "plans",
      target: "_blank",
    },
  ];

  const userScopeLinks: NavigationLink[] = [
    ...(isSuperAdmin
      ? [
          {
            icon: <Icon as={UserManagement} boxSize="18px" mr="20px" />,
            label: `${t("User Management")}`,
            to: "/management",
            isActive: location.pathname.startsWith("/management") ? true : false,
            dataPw: "management",
          },
        ]
      : []),
    {
      icon: (
        <Box className={s.helpIcon}>
          <Icon as={HelpIcon} boxSize="18px" mr="20px" />
          {tours !== undefined && showOnboardingTourIndicator && <Box className={s.activeHelpLink} />}
        </Box>
      ),
      label: t("Help"),
      onClick: (event: { preventDefault: () => void }) => {
        event.preventDefault();
        if (isFlowOpen && !isTablet) {
          trackEvent("OnboardingTwoShown", "", { start_page: window.location.pathname });
          dispatch(startTour(FLOW_BUILDER_TOUR_NAME));
        } else {
          trackEvent("OnboardingOneShown", "", { start_page: window.location.pathname });
          dispatch(startTour(GENERAL_TOUR_NAME));
        }
      },
      dataPw: "help",
    },

    {
      icon: <Icon as={ProfileIcon} boxSize="18px" mr="20px" />,
      label: t("My Profile"),
      to: "/profile",
      isActive: location.pathname.startsWith("/profile") ? true : false,
      dataPw: "profile",
    },
    {
      icon: <Icon as={LogoutIcon} boxSize="18px" mr="20px" />,
      label: t("Logout"),
      onClick: () => {
        dispatch(logoutViewer({ operatorName: user.username }));
        sessionStorage.removeItem("currentUrl");
      },
      dataPw: "logout",
    },
  ];

  useEffect(() => {
    // Initial loading
    dispatch(getBots({ page: 1, filter: undefined }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedSearchText !== undefined) {
      dispatch(getBots({ page: 1, filter: debouncedSearchText }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, searchRef]);

  useEffect(() => {
    if (organisations) {
      dispatch(getAllOrganisation());
      dispatch(getBillingPlan());
    }
  }, [organisations, dispatch]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      if (currentBot) {
        localStorage.setItem("CurrentBot", JSON.stringify(currentBot));
      }
    });
  }, [currentBot]);

  useEffect(() => {
    if (generalTourState?.isSkipped) setMobileOpenMenu(false);
  }, [generalTourState?.isSkipped]);

  const changeCurrentBot = (bot: BotModel) => {
    setIsOpenBot(false);
    navigate(`setBot/${bot.id}`, {
      state: {
        location: location.pathname,
      },
    });
  };

  const getPage = (page: number, filter?: string) => {
    dispatch(getBots({ page: page, filter: filter }));
  };

  const getAdditionalBots = () => {
    if (botList) {
      getPage(botList?.currentPage + 1, search);
    }
  };

  useEffect(() => {
    setSideBarIsOpenToStorage(isOpenSideBar);
  }, [isOpenSideBar]);

  const toggleSideBar = () => {
    setIsOpenSideBar(current => !current);
  };

  const selectCurrentOrganisation = (id: string) => {
    dispatch(setOrganization(id));
    navigate("/");
  };

  const [isOpenBot, setIsOpenBot] = useState(false);

  return (
    <>
      <SystemNotificationContainer />
      <SidebarQuestionModal isOpenModal={isOpenQuestionModal} setIsOpenModal={setIsOpenQuestionModal} />
      <Show breakpoint="(max-width: 768px)">
        <SideBarBurgerLinesMobile mobileOpenMenu={mobileOpenMenu} setMobileOpenMenu={setMobileOpenMenu} />
      </Show>
      <Flex
        flexDirection={"column"}
        className={`${s.sideBar} ${isOpenSideBar ? s.sideBarOpen : ""} ${mobileOpenMenu ? s.sideBarMobileOpen : ""}`}
      >
        <SideBarMenuPanelMobile setMobileOpenMenu={setMobileOpenMenu} />
        {isOpenSideBar && isTrial && !!trialPlan && trialPlan.isAvailable && !isTablet && (
          <Box>
            <TrialPlanComponent trialPlan={trialPlan} />
          </Box>
        )}
        {(isCurrentBotLoading || loadingBots) && <SideBarBotLoading />}
        <Box>
          {!isCurrentBotLoading && !loadingBots && (
            <SidebarCurrentBot
              currentBot={currentBot}
              organizations={organisations}
              isOpenSideBar={isOpenSideBar}
              setIsOpenSideBar={setIsOpenSideBar}
              isOpenBot={isOpenBot}
              setIsOpenBot={setIsOpenBot}
              visibleToolBot={visibleToolBot}
              setVisibleToolBot={setVisibleToolBot}
            />
          )}

          <Flex className={`${s.flexBots} ${isOpenBot ? s.openBots : ""} ${isOpenBot && isTrial ? s.trial : ""}`}>
            {allOrganisations && allOrganisations.length > 1 ? (
              <Menu
                placement={mobileOpenMenu ? "bottom" : "right-start"}
                autoSelect={false}
                variant="dominoMenu"
                gutter={0}
                offset={[16, 0]}
              >
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton
                      data-pw="switch-organisation-btn"
                      isActive={isOpen}
                      as={Button}
                      display={isOpenBot ? "flex" : "none"}
                      variant="dominoSwitchBtn"
                      borderTop="1px solid #DAE1FB"
                      borderBottom="1px solid #DAE1FB"
                      height={{ base: "48px", md: "60px" }}
                      onClick={e => e.stopPropagation()}
                      onFocus={() => setVisibleToolBot(true)}
                      onMouseEnter={() => setVisibleToolBot(false)}
                      onMouseLeave={() => setVisibleToolBot(true)}
                      pr={0}
                      rightIcon={
                        <Icon
                          as={DropArrowMenu}
                          boxSize="14px"
                          color="midDeepBlue"
                          transform={
                            mobileOpenMenu
                              ? isOpen
                                ? "rotate(270deg)"
                                : "rotate(90deg)"
                              : isOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)"
                          }
                          transition="ease-out .2s"
                          display={isOpenSideBar ? "block" : "none"}
                          ml={isOpenSideBar ? "0" : "-12px"}
                          mr={isOpenSideBar ? "12px" : "0"}
                          className={s.arrowChangeBot}
                        />
                      }
                    >
                      <Text>{t("Switch Organisation")}</Text>
                    </MenuButton>
                    <MenuList zIndex={10} className={`${s.menuList} ${mobileOpenMenu ? "" : s.menuListAbsolute}`}>
                      <Box>
                        {allOrganisations?.map(organisation => (
                          <Flex
                            data-pw={`${organisation.name}-organisation`}
                            key={organisation.id}
                            pl={"15px"}
                            cursor="pointer"
                            height={{ base: "48px", md: "60px" }}
                            flexDirection="row"
                            alignItems={"center"}
                            maxWidth={"100%"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            fontSize={{ base: "14px", md: "15px" }}
                            _hover={{ bgColor: "#F1F4F9" }}
                            onClick={() => {
                              selectCurrentOrganisation(organisation.id);
                              onClose();
                            }}
                          >
                            {organisation.name}
                          </Flex>
                        ))}

                        <MenuItem
                          height={{ base: "48px", md: "60px" }}
                          className={s.addBotBtn}
                          onClick={onNewOrganisation}
                          data-pw="new-organisation-btn"
                        >
                          + {t("New organisation")}
                        </MenuItem>
                      </Box>
                    </MenuList>
                  </>
                )}
              </Menu>
            ) : (
              <Menu
                placement={mobileOpenMenu ? "bottom" : "right-start"}
                autoSelect={false}
                variant="dominoMenu"
                gutter={0}
                offset={[16, 0]}
              >
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton
                      onClick={onNewOrganisation}
                      data-pw="new-organisation-btn"
                      isActive={isOpen}
                      as={Button}
                      display={isOpenBot ? "flex" : "none"}
                      variant="dominoSwitchBtn"
                      borderTop="1px solid #DAE1FB"
                      borderBottom="1px solid #DAE1FB"
                      height={{ base: "48px", md: "60px" }}
                      pr={0}
                      rightIcon={
                        <Icon
                          as={DropArrowMenu}
                          boxSize="14px"
                          color="midDeepBlue"
                          transition="ease-out .2s"
                          display={isOpenSideBar ? "block" : "none"}
                          ml={isOpenSideBar ? "0" : "-12px"}
                          mr={isOpenSideBar ? "12px" : "0"}
                          className={s.arrowChangeBot}
                        />
                      }
                    >
                      <Text>{t("New organisation")}</Text>
                    </MenuButton>
                  </>
                )}
              </Menu>
            )}
            <NewOrganisationModal isOpen={isOrganisationModalOpen} onClose={onOrganisationModalClose} />
            {((botList && botList.totalItems > 1) || search) && (
              <>
                <InputSearch
                  inputGroupStyles={{ margin: "12px 13px 12px 13px", width: "auto" }}
                  placeholder={t("Search by Bots")}
                  setSearchText={setSearch}
                  searchText={search}
                />
                <SideBarBotList
                  botList={botList}
                  isOpenSideBar={isOpenSideBar}
                  loadingBots={loadingBots}
                  changeCurrentBot={changeCurrentBot}
                  getAdditionalBots={getAdditionalBots}
                />
              </>
            )}
            <Box>
              {!IsUnlimited && organisations?.totalBots && organisations?.totalBots >= MaxCount ? (
                <SideBarBotsLimitPopover />
              ) : (
                <SideBarCreateNewBot navigate={navigate} />
              )}
            </Box>
          </Flex>
        </Box>

        <Flex className={`${s.mainFlexSideBar} ${isOpenBot && s.mainFlexSideBarClose}`}>
          <Box>
            <Flex mt={"16px"} flexDirection={["column"]}>
              {botScopeLinks.map((link, index) => {
                return (
                  <NavLink key={index} to={link?.to ?? ""}>
                    <Tooltip
                      variant="dominoLight"
                      label={link.label}
                      visibility={isOpenSideBar ? "hidden" : "visible"}
                      placement={"right"}
                    >
                      <Button
                        onClick={() => {
                          if (link?.onClick) {
                            link?.onClick(Event);
                          }
                          isSidebarOrOnboarding ? setMobileOpenMenu(true) : setMobileOpenMenu(false);
                          if (showGeneralTour) {
                            dispatch(startTour(GENERAL_TOUR_NAME));
                            trackEvent("OnboardingOneShown", "", { start_page: link.to });
                          }
                        }}
                        variant="sideBar"
                        className={`${link?.targetClass} ${s.sideLink} ${link.isActive ? s.activeSideLink : ""}`}
                        data-pw={link.dataPw}
                      >
                        <>{link.icon}</>
                      </Button>
                    </Tooltip>
                  </NavLink>
                );
              })}
            </Flex>
            <Flex mt={"16px"} flexDirection={["column"]}>
              {organisationScopeLinks.map((link, index) => {
                return link?.to ? (
                  <NavLink data-pw={link.label} key={index} to={link?.to ?? ""} target={link?.target ?? "_self"}>
                    <Tooltip
                      variant="dominoLight"
                      label={link.label}
                      visibility={isOpenSideBar ? "hidden" : "visible"}
                      placement={"right"}
                    >
                      <Button
                        onClick={() => {
                          if (link?.onClick) {
                            link?.onClick(Event);
                          }
                          isSidebarOrOnboarding ? setMobileOpenMenu(true) : setMobileOpenMenu(false);
                          if (showGeneralTour) {
                            dispatch(startTour(GENERAL_TOUR_NAME));
                            trackEvent("OnboardingOneShown", "", { start_page: link.to });
                          }
                        }}
                        variant="sideBar"
                        className={`${link?.targetClass} ${s.sideLink} ${link.isActive ? s.activeSideLink : ""}`}
                        data-pw={link.dataPw}
                      >
                        <>{link.icon}</>
                      </Button>
                    </Tooltip>
                  </NavLink>
                ) : (
                  <Tooltip
                    key={index}
                    variant="dominoLight"
                    label={link.label}
                    visibility={isOpenSideBar ? "hidden" : "visible"}
                    placement={"right"}
                  >
                    <Button
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        link?.onClick;
                        setMobileOpenMenu(false);
                      }}
                      variant="sideBar"
                      className={link.isActive ? s.activeSideLink : ""}
                      data-pw={link.dataPw}
                    >
                      <>
                        {link.icon}
                        {link.label}
                      </>
                    </Button>
                  </Tooltip>
                );
              })}
              {organisation && (
                <SidebarPlugins
                  isOpenSideBar={isOpenSideBar}
                  organisation={organisation}
                  onClick={() => setMobileOpenMenu(false)}
                />
              )}
            </Flex>
            <Flex mt={"16px"} flexDirection={["column"]}>
              {userScopeLinks.map((link, index) => {
                return link?.to ? (
                  <NavLink key={index} to={link?.to ?? ""} target={link?.target ?? ""}>
                    <Tooltip
                      variant="dominoLight"
                      label={link.label}
                      visibility={isOpenSideBar ? "hidden" : "visible"}
                      placement={"right"}
                    >
                      <Button
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          link?.onClick;
                          isSidebarOrOnboarding ? setMobileOpenMenu(true) : setMobileOpenMenu(false);
                          if (showGeneralTour && !link.label?.includes("Logout")) {
                            dispatch(startTour(GENERAL_TOUR_NAME));
                            trackEvent("OnboardingOneShown", "", { start_page: link.to });
                          }
                        }}
                        variant="sideBar"
                        className={`${s.sideLink} ${link.isActive ? s.activeSideLink : ""}`}
                        data-pw={link.dataPw}
                      >
                        <>
                          {link.icon}
                          {link.label}
                        </>
                      </Button>
                    </Tooltip>
                  </NavLink>
                ) : (
                  <Tooltip
                    key={index}
                    variant="dominoLight"
                    label={link.label}
                    visibility={isOpenSideBar ? "hidden" : "visible"}
                    placement={"right"}
                  >
                    <Button
                      onClick={link?.onClick}
                      variant="sideBar"
                      className={link.isActive ? s.activeSideLink : ""}
                      data-pw={link.dataPw}
                    >
                      <>
                        {link.icon}
                        {link.label}
                      </>
                    </Button>
                  </Tooltip>
                );
              })}
              {isImpersonated && (
                <Tooltip variant="dominoLight" label={t("You are in impersonate mode")} placement={"right"}>
                  <Button onClick={() => dispatch(stopImpersonation())} variant="sideBar" className={s.impersonateModeAlert}>
                    <>
                      <Icon as={AlertIcon} boxSize="18px" mr="20px" color="red" />
                      {t("Stop Impersonation")}
                    </>
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Box>
          <Button
            variant="sideBar"
            _hover={{ bg: "bgMiddle", color: "bgMiddle" }}
            onClick={() => toggleSideBar()}
            className={`${s.sideLink} ${s.linkArrow}`}
          >
            <DobleArrow className={`${s.arrowToggle} ${isOpenSideBar ? s.arrowToggleHide : ""}`} />
          </Button>
        </Flex>
      </Flex>
      <Show breakpoint="(max-width: 768px)">
        <Box className={`${s.overlay} ${mobileOpenMenu ? s.open : ""}`} onClick={() => setMobileOpenMenu(false)}></Box>
      </Show>
    </>
  );
};
