import { Box, Button, Flex, Icon, Textarea, useMediaQuery } from "@chakra-ui/react";
import { ConversationSendMessageModel, FileUploadType } from "../../ConversationModel";
import s from "./MessageBox.module.scss";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/imageChatIcon.svg";
import { ValidationModel } from "../../../../common/ErrorModel";
import { AppSettings } from "../../../../common/AppSettings";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import { ReactComponent as PaperClipIcon } from "../../../../assets/icons/paperClipIcon.svg";
import { ReactComponent as NoteIcon } from "../../../../assets/icons/noteIcon.svg";
import { ReactComponent as ReplyIcon } from "../../../../assets/icons/replyIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { ReactComponent as UnblockIcon } from "../../../../assets/icons/revote.svg";
import { InputWrapper } from "../../../inputWrapper/InputWrapper";
import { InputType } from "../../../inputWrapper/InputWrapperModel";
import { maxLength } from "../../../../common/validation/defaultValidators";
import { ClipboardEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { insertStringIntoText } from "../../../../common/utils/insertStringIntoText";
import { CustomVariableModel, IMAGE_FILE_EXTS } from "../../../../common/AppEnums";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { OutgoingTypeDiscriminator } from "../../ConversationData";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { addFormatting } from "../../../../common/formattedText/addFormatting";
import Attachments from "./components/Attachments/Attachments";

declare const appSettings: AppSettings;

interface DefaultProps {
  message: ConversationSendMessageModel;
  variables?: CustomVariableModel[];
  validation?: ValidationModel;
  isNoteActive: boolean;
  isBlocked: boolean;
  setIsNoteActive: Dispatch<SetStateAction<boolean>>;
  cursorPosition: number;
  setCursorPosition: Dispatch<SetStateAction<number>>;
  replyMode: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  };
}

interface EventProps {
  onSetMessage: (message: ConversationSendMessageModel, cursorPosition?: number) => void;
  onSendMessage: (message: ConversationSendMessageModel) => void;
  onUploadFile: (file: File, type: FileUploadType) => void;
  onCloseReply: () => void;
  onPasteFileFromClipboard: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  onUnblockClick: () => void;
}

type Props = DefaultProps & EventProps;

export const MessageBox = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const [isMessageBoxFocus, setIsMessageBoxFocus] = useState(false);
  //We need this state for oprimization to change it locally without rerender all messages

  const inputMaxLength = props.message.photo == null && props.message.document == null ? 2000 : 1024;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isDesktop] = useMediaQuery("(min-width: 1270px)");
  const [localMessageText, setLocalMessageText] = useState("");
  const loadingFileUpload = useGetLoadingState("messageFileUpload");
  const isLoadingSendMessage = useGetLoadingState("sendMessage");
  const isLoadingUnblock = useGetLoadingState("unblockConversation");

  const [replyMode, setReplyMode] = useState<{
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }>(props.replyMode);
  const botInfo = useAppSelector(selectCurrentBot);

  const onSend = () => {
    if (props.isNoteActive) {
      props.onSendMessage({
        text: localMessageText,
        typeDiscriminator: OutgoingTypeDiscriminator.NoteTextConversation,
      });
    } else if (replyMode.enabled) {
      props.onSendMessage({
        ...props.message,
        text: localMessageText,
        replyToMessageId: replyMode.message?.id,
      });
    } else {
      props.onSendMessage({
        ...props.message,
        text: localMessageText,
      });
    }
    clearLocalMessage();
    props.onCloseReply();
    props.setIsNoteActive(false);
  };

  const clearLocalMessage = () => {
    setLocalMessageText("");
  };

  const onSetMessageText = (text: string) => {
    setLocalMessageText(text);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onSetMessage({ ...props.message, text: localMessageText }, props.cursorPosition);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMessageText]);

  useEffect(() => {
    setLocalMessageText(props.message.text);
  }, [props.message]);

  useEffect(() => {
    if (props.isBlocked) {
      const updatedMessage = {
        ...props.message,
        text: "",
        photo: undefined,
        replyToMessageId: undefined,
        document: undefined,
      };
      props.onSetMessage(updatedMessage);
      props.setIsNoteActive(false);
      props.onCloseReply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isBlocked]);

  useEffect(() => {
    setReplyMode(props.replyMode);
    if (props.replyMode.enabled) {
      setTimeout(() => {
        textareaRef?.current?.focus();
      }, 1);
      props.setIsNoteActive(false);
    }
  }, [props, props.replyMode]);

  useEffect(() => {
    if (props.isNoteActive) setReplyMode({ enabled: false });
  }, [props.isNoteActive]);

  const addVariableText = (variable: CustomVariableModel) => {
    const text = insertStringIntoText(
      localMessageText ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      props.cursorPosition,
    );
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    onSetMessageText(text);
  };

  const addEmoji = (emojiData: string) => {
    const text = insertStringIntoText(localMessageText ?? "", emojiData, props.cursorPosition);
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    onSetMessageText(text);
  };

  const removeAttachedFile = () => {
    const updatedMessage = {
      ...props.message,
      photo: undefined,
      document: undefined,
    };
    props.onSetMessage(updatedMessage);
  };

  return (
    <Box className={`${s.messageBox} loaderContainer`}>
      <Box
        className={`${s.messageArea} ${isMessageBoxFocus && s.focused}`}
        onFocus={() => setIsMessageBoxFocus(true)}
        onBlur={() => setIsMessageBoxFocus(false)}
      >
        <InputWrapper
          cursorPosition={props.cursorPosition}
          type={InputType.TextArea}
          maxLength={inputMaxLength}
          charactersNumber={localMessageText.length}
          emojiProps={{ addEmoji: addEmoji }}
          addFormatting={action => {
            textareaRef.current &&
              addFormatting(action, localMessageText, inputMaxLength, textareaRef.current, setLocalMessageText);
          }}
          variablesProps={
            props.variables && !props.isNoteActive
              ? { variables: props.variables, addCustomVariableText: addVariableText }
              : undefined
          }
          buttonBlockStyle={{
            borderRadius: "0 0 8px 8px",
            backgroundColor: props.isNoteActive ? "#FEF6DC" : "white",
            width: "100%",
          }}
          childRef={textareaRef}
          isDisabled={props.isBlocked}
        >
          {replyMode.enabled && replyMode.message && (
            <Flex className={s.replyWrapper}>
              <Flex className={s.replyMessage}>
                <Flex className={s.replyMessageFlex}>
                  <Icon as={ReplyIcon} className={s.replyIcon}></Icon>
                  {replyMode.message.photoFileId && (
                    <span className={s.replyMessagePhoto}>
                      <img alt="" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${replyMode.message.photoFileId}`} />
                    </span>
                  )}
                  <Box className={`${s.replyMessageContent} ${replyMode.message.photoFileId ? s.replyMessageContentImage : ""}`}>
                    <span className={s.replyMessageAuthor}>
                      {replyMode.message?.author === "bot" ? botInfo?.name : replyMode.message?.author}
                    </span>
                    {replyMode.message?.contact && !replyMode.message?.text ? (
                      <Box>
                        <span className={s.replyMessageContact}>{t("Contact")}</span>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {!!replyMode.message?.documentName ? (
                      <span className={`${s.replyMessageText} ${s.description}`}>{replyMode.message?.documentName}</span>
                    ) : (
                      <>
                        {replyMode.message?.photoFileId && !replyMode.message?.text ? (
                          <span className={`${s.replyMessageText} ${s.description}`}>{t("Image")}</span>
                        ) : (
                          <span className={s.replyMessageText}>{replyMode.message?.text}</span>
                        )}
                      </>
                    )}
                  </Box>
                </Flex>
                <Button className={s.replyCancelButton} onClick={props.onCloseReply}>
                  <Icon as={CrossIcon} className={s.icon}></Icon>
                </Button>
              </Flex>
            </Flex>
          )}
          <Textarea
            data-pw="message-textarea"
            onPaste={(evt: ClipboardEvent<HTMLTextAreaElement>) => props.onPasteFileFromClipboard(evt)}
            id={"message_textarea"}
            onSelect={e => {
              props.setCursorPosition(e.currentTarget.selectionStart);
            }}
            ref={textareaRef}
            className={`${s.input} ${props.isNoteActive && s.noteInputActive}`}
            isDisabled={props.isBlocked}
            placeholder={
              props.isBlocked
                ? `${t("This chat is blocked for sending messages...")}`
                : replyMode.enabled
                ? `${t("Reply here")}`
                : !props.isNoteActive
                ? `${t("Write a message...")}`
                : `${t("Enter a personal note")}`
            }
            style={{
              minHeight: replyMode.enabled && isDesktop ? "55px" : "",
            }}
            resize="none"
            variant="dominoColor"
            value={localMessageText}
            _placeholder={!props.isNoteActive ? { color: "#BCC3CD" } : { color: "#8592A3" }}
            maxLength={inputMaxLength} // we send attached images/docs as separate message
            onChange={e => onSetMessageText(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter" && !e.shiftKey && props.validation?.errors.length === 0) {
                e.preventDefault();
                onSend();
              }
            }}
          ></Textarea>
        </InputWrapper>
      </Box>
      <Loading scope="messageFileUpload" />

      {(props.message.document || props.message.photo) && (
        <Attachments removeAttachedFile={removeAttachedFile} message={props.message} />
      )}

      <Box className={s.boxActions}>
        <Box className={`${s.actionsWr}`}>
          {(Object.keys(FileUploadType) as Array<keyof typeof FileUploadType>).map(el => (
            <Box key={el}>
              <label
                data-pw={el === "photo" ? "image-btn" : "document-btn"}
                className={`${s.input} ${
                  props.message.document || props.message.photo || props.isNoteActive || props.isBlocked ? s.disabledInput : ""
                }`}
              >
                {el === "photo" ? (
                  <ImageIcon
                    className={`${s.actionIconItem} ${
                      props.message.document || props.message.photo || props.isBlocked ? s.disabled : ""
                    }`}
                  />
                ) : (
                  <PaperClipIcon
                    className={`${s.actionIconItem} ${
                      props.message.document || props.message.photo || props.isBlocked ? s.disabled : ""
                    }`}
                  />
                )}
                {!props.message.document && !props.message.photo && !props.isNoteActive && (
                  <input
                    className={s.hidden}
                    disabled={props.isBlocked}
                    onChange={e => {
                      if (e.target.files) {
                        props.onUploadFile(e.target.files[0], el === "photo" ? FileUploadType.photo : FileUploadType.document);
                      }
                    }}
                    type="file"
                    accept={el === "photo" ? IMAGE_FILE_EXTS : ""}
                  />
                )}
              </label>
            </Box>
          ))}
        </Box>
        <Box
          ml="4px"
          w="24px"
          h="24px"
          onClick={() => {
            if (props.isBlocked) return;
            props.setIsNoteActive(!props.isNoteActive);
            removeAttachedFile();
          }}
          data-pw="note-btn"
        >
          <NoteIcon className={`${props.isBlocked ? s.noteDisabled : s.noteIcon} ${props.isNoteActive && s.noteActive}`} />
        </Box>
        <Box ml={"auto"}>
          {props.isBlocked ? (
            <Button
              width={"100%"}
              variant="dominoOutlineRed"
              flexShrink={0}
              iconSpacing={"4px"}
              isLoading={isLoadingUnblock}
              loadingText={t("Unblock")}
              spinnerPlacement="start"
              leftIcon={<UnblockIcon color={"inherit"} />}
              onClick={props.onUnblockClick}
            >
              <span>{t("Unblock")}</span>
            </Button>
          ) : (
            <Button
              isDisabled={!props.validation || !!props.validation.errors.length || loadingFileUpload}
              onClick={() => onSend()}
              size={"sm"}
              variant="dominoViolet"
              data-pw="send-btn"
              isLoading={isLoadingSendMessage}
              loadingText={t("Send")}
              spinnerPlacement="end"
            >
              <span>{t("Send")}</span>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
