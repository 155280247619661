import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Show,
  Text,
  Flex,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIconTile.svg";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCanAddNewFlow, selectCanImportNewFlow, selectFlowList } from "../../AutomationSlice";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../../../sidebar/SidebarSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import h from "../../../../components/headers/HeaderStyles.module.scss";
import { ImportFlowModal } from "../ImportFlowModal/ImportFlowModal";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { FlowTemplatesModal } from "../FlowTemplatesModal/FlowTemplatesModal";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import useDebounce from "../../../../common/hooks/useDebounce";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

export const AutomationHeaderContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", {
    keyPrefix: "flowList.flowListHeader",
  });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const location = useLocation();
  const currentBot = useAppSelector(selectCurrentBot);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenTemplates, onOpen: onOpenTemplates, onClose: onCloseTemplates } = useDisclosure();

  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;
  const isImportAvailable = useAppSelector(selectCanImportNewFlow) ?? false;
  const flowList = useAppSelector(selectFlowList);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);
  const isLoadingFlowList = useGetLoadingState("getFlowList");

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
  }, [searchText, debouncedSearchText, search, setSearchParams, dispatch]);

  useEffect(() => {
    if (location.search.includes("modal=importFlow")) {
      onOpen();
    }
    if (location.search.includes("modal=newFlow")) {
      onOpenTemplates();
    }
  }, [location.search, onOpen, onOpenTemplates]);

  useEffect(() => {
    if (isOpen) {
      window.history.replaceState(window.history.state, "", "?modal=importFlow");
    } else {
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpenTemplates) {
      window.history.replaceState(window.history.state, "", "?modal=newFlow");
    } else {
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [isOpenTemplates]);

  useEffect(() => {
    if (location.pathname.includes("triggers")) {
      setSearchText("");
    }
  }, [location.pathname]);

  useDocumentTitle(t("Automation"), "Automation");

  return (
    <>
      <>
        {(!currentBot && !isCurrentBotLoading) || (flowList?.items.length === 0 && search === "" && !isLoadingFlowList) ? (
          <Text className={h.title} variant="h2" color="midDeepBlue" py="16px">
            {t("Automation")}
          </Text>
        ) : (
          <>
            <Flex flexDir={{ base: "column", md: "row" }} className={h.headerMiddle}>
              <Flex className={h.titleBlock}>
                <Text variant="h2" className={h.title}>
                  {t("Automation")}
                </Text>
                <Show breakpoint="(max-width: 767px)">
                  <Box>
                    <DotMenuContainer
                      menuItems={[
                        {
                          MenuItem: {
                            icon: <PlusIcon />,
                            text: t("New Flow"),
                            clickEvent: onOpenTemplates,
                            color: "mainPurple",
                          },
                        },
                        {
                          MenuItem: {
                            icon: <ImportIcon />,
                            text: t("Import Flow"),
                            clickEvent: onOpen,
                            color: "mainPurple",
                          },
                        },
                      ]}
                      MenuHover={{ bg: "line" }}
                    />
                  </Box>
                </Show>
              </Flex>
              {!location.pathname.includes("triggers") ? (
                <div className={h.InputSearchBlock}>
                  <InputSearch
                    classprop={h.inputStyle}
                    inputGroupStyles={{ mx: "16px", minW: "275px", ml: "auto", alignSelf: "stretch", w: "auto" }}
                    placeholder={`${t("Search by flow name")}`}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    data-pw="automation-header-search-input"
                    onFocus={() => {
                      trackEvent(FlowEvents.FlowSearch);
                    }}
                  />
                </div>
              ) : (
                <Show breakpoint="(min-width: 420px)">
                  <Box style={{ width: "39%", minWidth: "0" }} />
                </Show>
              )}
            </Flex>
            <Show breakpoint="(min-width: 768px)">
              <Flex justifyContent={"space-between"} className={h.buttonsContainer}>
                {!isImportAvailable || !canAddFlow ? (
                  <Popover trigger={"hover"}>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Button
                            size="sm"
                            variant="dominoOutlineViolet"
                            data-pw="import-flow-button"
                            className={sp.starPlanButton}
                          >
                            <Icon className={sp.starPlan} boxSize="20px" fill={"#6D5BF7"} as={StarPlan} />
                            {t("Import Flow")}
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                            <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                            <Flex alignItems="center" justifyContent="center" direction="column">
                              <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                {tp("Advanced feature")}
                              </PopoverHeader>
                              <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                {tp("Please upgrade your plan to import Flows")}
                              </PopoverBody>
                              <TariffPlanRedirectButton onClose={onClose} />
                            </Flex>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                ) : (
                  <Button
                    maxW="180px"
                    size="sm"
                    variant="dominoOutlineViolet"
                    onClick={() => {
                      onOpen();
                      trackEvent(FlowEvents.FlowImport);
                    }}
                    data-pw="import-flow-button"
                  >
                    <Flex gap={"8px"} align={"center"} fontSize="15px">
                      <ImportIcon />
                      {t("Import Flow")}
                    </Flex>
                  </Button>
                )}
                {!canAddFlow ? (
                  <Popover trigger={"hover"}>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Button
                            size="sm"
                            variant="dominoViolet"
                            px="24px"
                            className={sp.starPlanButton}
                            data-pw="new-flow-button"
                          >
                            <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                            {t("New Flow")}
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                            <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                            <Flex alignItems="center" justifyContent="center" direction="column">
                              <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                {tp("Advanced feature")}
                              </PopoverHeader>
                              <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                {tp("Please upgrade your plan to create more Flows")}
                              </PopoverBody>
                              <TariffPlanRedirectButton onClose={onClose} />
                            </Flex>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                ) : (
                  <Button
                    data-pw="new-flow-button"
                    maxW="170px"
                    size="sm"
                    variant="dominoViolet"
                    color={"#FFFFFF"}
                    px="24px"
                    onClick={() => {
                      onOpenTemplates();
                      trackEvent(FlowEvents.FlowCreate);
                    }}
                  >
                    <Flex gap={"6px"} align={"center"}>
                      <PlusIcon />
                      {t("New Flow")}
                    </Flex>
                  </Button>
                )}
              </Flex>
            </Show>
          </>
        )}
      </>
      <ImportFlowModal isOpen={isOpen} onClose={onClose} />
      <FlowTemplatesModal isOpen={isOpenTemplates} onClose={onCloseTemplates} />
    </>
  );
};
