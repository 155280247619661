import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Input,
  Text,
  Flex,
  FormControl,
  Image,
  Box,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import DownArrow from "../../../../assets/images/downArrow.svg";
import { forwardRef, useEffect, useRef, useState } from "react";
import s from "./AddVariablePopver.module.scss";
import { CustomVariableScope, CustomVariableType, AddVariablePopupModel } from "../AddVariableModel";
import { useTranslation } from "react-i18next";
import { BooleanVariableValues, CustomVariableModel } from "../../../../common/AppEnums";
import { customDateInput, DatePicker } from "../../../../UI/atoms/datePicker/DatePicker";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import dayjs from "dayjs";
import { useNowHeightView } from "../../../layout/LayoutHelper/ResolutionHooks";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onOpenPopover: () => void;
  vars?: CustomVariableModel[];
  onlyType?: CustomVariableType;
  excludeType?: CustomVariableType;
} & AddVariablePopupModel;

export const AddVariablePopover = (props: Props) => {
  const isValueNecessary = props.scope === CustomVariableScope.Bot;
  const { t } = useTranslation("translation");
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("translation", { keyPrefix: "variableTypes" }).t;
  const [newCustomVariableInfo, setNewCustomVariableInfo] = useState<CustomVariableModel>({
    key: "",
    type: props.onlyType || CustomVariableType.Text,
    scope: props.scope ?? CustomVariableScope.Flow,
    ...(isValueNecessary && { value: "" }),
    description: "",
    containerId: props.botId ?? props.flowId ?? null,
  });
  const [nameError, setNameError] = useState("");
  const [valueError, setValueError] = useState("");
  const CustomInput = forwardRef(customDateInput);
  const nowHeight = useNowHeightView();
  const locale = localStorage.getItem("i18nextLng");
  const initialFocusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.isOpen) {
      setNewCustomVariableInfo({
        key: "",
        type: props.onlyType || CustomVariableType.Text,
        scope: props.scope ?? CustomVariableScope.Flow,
        ...(isValueNecessary && { value: "" }),
        description: "",
        containerId: props.botId ?? props.flowId ?? null,
      });
      setNameError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const onCreate = () => {
    if (!fieldValidation()) {
      return;
    }
    props?.onCreate?.(newCustomVariableInfo);
    props.onClose();
  };

  const validateName = (value: string) => {
    const maxWidth = 25;
    const allowedSymbols = /^[а-яА-ЯёЁA-Za-z0-9-_]+$/;
    if (!value.replace(/\s/g, "").length) {
      setNameError("Name Field is Required");
      return false;
    }
    if (value.length > maxWidth) {
      setNameError("Name must be less than 25 characters long");
      return false;
    }
    if (!value.match(allowedSymbols)) {
      setNameError("Name can contain only letters, numbers, hyphens and underscores");
      return false;
    }
    setNameError("");
    return true;
  };

  const validateValue = (value: string | undefined) => {
    const type = newCustomVariableInfo.type;
    let isValid = true;
    let valueError = "";
    if (value) {
      if (type === CustomVariableType.Boolean) {
        isValid = ["true", "false"].includes(value);
        valueError = isValid ? "" : "Boolean Field Should Be true or false";
      }
      if (type === CustomVariableType.Number) {
        isValid = !/\D/.test(value);
        valueError = isValid ? "" : "Number Field Should contains only numbers";
      }
    }
    setValueError(valueError);
    return isValid;
  };

  const fieldValidation = () => {
    const nameValidation = validateName(newCustomVariableInfo.key);
    const valueValidation =
      newCustomVariableInfo.scope === CustomVariableScope.Bot ? validateValue(newCustomVariableInfo.value) : true;
    if (!nameValidation || !valueValidation) {
      return false;
    }
    return true;
  };

  const customVariableScopeOnChange = (scope: CustomVariableScope) => {
    const containerId = scope === CustomVariableScope.Flow ? props.flowId : null;
    if (containerId !== undefined) {
      setNewCustomVariableInfo({
        ...newCustomVariableInfo,
        scope: scope,
        containerId,
      });
    }
  };

  const getPlaceholder = () => {
    const type = newCustomVariableInfo.type;
    if (type === CustomVariableType.Array) {
      return t("flow.addVariablePopup.Enter array values, separated by commas");
    }
    if (type === CustomVariableType.Number) {
      return t("flow.addVariablePopup.Enter number");
    }
    return t("flow.addVariablePopup.Enter text");
  };

  const getValueInput = () => {
    switch (newCustomVariableInfo.type) {
      case CustomVariableType.DateTime: {
        return (
          <DatePicker
            dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
            selected={newCustomVariableInfo.value ? new Date(newCustomVariableInfo.value) : null}
            onChange={scheduledDate => {
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ") : "",
              });
            }}
            strictParsing
            placeholderText={`${ct("placeholderDateTime")}`}
            showTimeSelect
            customInput={<CustomInput h={"36px"} />}
            data-pw="date-time-input"
          />
        );
      }
      case CustomVariableType.Date: {
        return (
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={newCustomVariableInfo.value ? new Date(newCustomVariableInfo.value) : null}
            onChange={scheduledDate => {
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : "",
              });
            }}
            strictParsing
            placeholderText={`${ct("placeholderDate")}`}
            customInput={<CustomInput h={"36px"} />}
            data-pw="date-time-input"
          />
        );
      }
      case CustomVariableType.Boolean: {
        return (
          <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
            <MenuButton className={s.menuButton} onClick={e => e.stopPropagation()} data-pw="boolean-input">
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex w={"100%"} justifyContent="space-between" alignItems={"center"} ml={"1%"}>
                  {newCustomVariableInfo.value?.length ? ct(newCustomVariableInfo.value) : ct("Not Set")}
                  <Image boxSize={"24px"} src={DownArrow}></Image>
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList>
              {Object.keys(BooleanVariableValues).map((el, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={el}
                    onClick={() => {
                      setNewCustomVariableInfo({
                        ...newCustomVariableInfo,
                        value: el !== BooleanVariableValues["Not Set"] ? el : "",
                      });
                    }}
                    data-pw="boolean-menu-item"
                  >
                    {ct(el)}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }
      case CustomVariableType.Array:
      case CustomVariableType.Number:
      case CustomVariableType.Text:
      case CustomVariableType.Order:
      default: {
        return (
          <Input
            h={"36px"}
            value={newCustomVariableInfo.value}
            onChange={e =>
              setNewCustomVariableInfo({
                ...newCustomVariableInfo,
                value: e.target.value,
              })
            }
            onBlur={() => validateValue(newCustomVariableInfo.value)}
            type={newCustomVariableInfo.type}
            placeholder={getPlaceholder()}
            data-pw="text-input"
          />
        );
      }
    }
  };

  const isFlow = newCustomVariableInfo.scope === CustomVariableScope.Flow;
  useEffect(() => {
    props.isOpen && initialFocusRef.current?.focus();
  }, [isFlow, props.isOpen]);

  const popover = useRef<HTMLElement | null>(null);
  const [translateY, setTranslateY] = useState("");
  useEffect(() => {
    if (nowHeight > 0) {
      setTranslateY(blockHeight());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowHeight, props.isOpen]);

  const blockHeight = () => {
    if (popover?.current && popover.current.getBoundingClientRect().bottom >= nowHeight) {
      return `-${popover.current.getBoundingClientRect().bottom - nowHeight + 40}px`;
    } else {
      return `${translateY}`;
    }
  };

  const includedTypes = () => {
    const types = !!props.excludeType
      ? Object.keys(CustomVariableType).filter(k => k !== props.excludeType)
      : Object.keys(CustomVariableType);
    return types;
  };

  return (
    <>
      <Popover
        closeOnBlur={false}
        placement="left"
        flip={false}
        isOpen={props.isOpen}
        onClose={props.onClose}
        initialFocusRef={initialFocusRef}
      >
        <PopoverTrigger>
          <Button
            variant="dominoDashedViolet"
            onClick={props.onOpenPopover}
            className={props.vars?.length ? s.addVarWrp : s.noButtonBorder}
            isActive={props.isOpen}
            data-pw="add-variable"
          >
            + {t("flow.editNodePopup.Custom Fields")}
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={popover} transform={`translate(0px,${translateY}) !important`} maxWidth="300px">
          <PopoverHeader p="20px 24px">
            <Text variant="largeBold">
              {isFlow ? t("flow.addVariablePopup.Create New Variable") : t("flow.addVariablePopup.Create New Field")}
            </Text>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton
            width="32px"
            height="32px"
            top="14px"
            borderRadius={"50%"}
            color={"darkGrey"}
            _hover={{ bg: "defaultGrey", color: "mainPurple" }}
            data-pw="close-button"
          >
            <CrossIcon />
          </PopoverCloseButton>
          <PopoverBody p="24px">
            <Flex alignItems={"center"} flexDirection={"column"}>
              <Text variant="h1"></Text>
              {!props.scope && (
                <FormControl>
                  <Flex mb="24px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <ButtonGroup bg="line" borderRadius="10px" p="4px" width="100%" data-pw="button-group">
                      <Button
                        width="100%"
                        variant={isFlow ? "dominoViolet" : "dominoGhostViolet"}
                        onClick={() => customVariableScopeOnChange(CustomVariableScope.Flow)}
                        data-pw="flow-button"
                      >
                        {t("flow.addVariablePopup.Flow")}
                      </Button>
                      <Button
                        width="100%"
                        variant={isFlow ? "dominoGhostViolet" : "dominoViolet"}
                        onClick={() => customVariableScopeOnChange(CustomVariableScope.Contact)}
                        data-pw="contact-button"
                      >
                        {t("flow.addVariablePopup.Contact")}
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </FormControl>
              )}

              <FormControl>
                <Text mb="12px" variant="medium" display={"flex"} alignItems={"center"}>
                  {isFlow ? `${t("flow.addVariablePopup.Name")}` : `${t("flow.addVariablePopup.Name field")}`} &nbsp;
                  <TooltipWithTouch
                    variant="dominoLight"
                    placement="top"
                    label={t("flow.addVariablePopup.Name can contain only letters, numbers, hyphens and underscores")}
                  >
                    <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                  </TooltipWithTouch>
                </Text>
                <Input
                  h={"36px"}
                  value={newCustomVariableInfo.key}
                  maxLength={25}
                  placeholder={
                    isFlow ? `${t("flow.addVariablePopup.Enter name")}` : `${t("flow.addVariablePopup.Enter name field")}`
                  }
                  onChange={e =>
                    setNewCustomVariableInfo({
                      ...newCustomVariableInfo,
                      key: e.target.value.replace(/\s/g, ""),
                    })
                  }
                  onBlur={() => {
                    validateName(newCustomVariableInfo.key);
                  }}
                  type="text"
                  data-pw="name-input"
                  ref={initialFocusRef}
                />
                <Box color="#f92222">
                  <Text minH="16px" variant="small">
                    {nameError && t(`flow.addVariablePopup.errors.${nameError}`)}
                  </Text>
                </Box>
              </FormControl>
              <FormControl mt="8px" mb="32px">
                <Text mb="12px" variant="medium" display={"flex"} alignItems={"center"}>
                  {t("flow.addVariablePopup.Reply Type")} &nbsp;
                  <TooltipWithTouch
                    variant="dominoLight"
                    placement="top"
                    label={t(
                      "flow.addVariablePopup.Choose the type of the field according to the nature of data that you want to store. String means any amount of text, boolean means yes/no/undefined states, while number and date are self-explanatory.",
                    )}
                  >
                    <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                  </TooltipWithTouch>
                </Text>
                <Menu placement="top" preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
                  <MenuButton
                    disabled={!!props.onlyType}
                    className={s.menuButton}
                    onClick={e => e.stopPropagation()}
                    data-pw="menu-button"
                  >
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                      <Flex w={"90%"} alignItems={"center"} ml={"1%"}>
                        {vt(newCustomVariableInfo.type)}
                      </Flex>
                      {!props.onlyType && <Image boxSize={"24px"} src={DownArrow}></Image>}
                    </Flex>
                  </MenuButton>
                  <MenuList data-pw="button-group">
                    {newCustomVariableInfo.scope !== CustomVariableScope.Contact
                      ? Object.keys(CustomVariableType)
                          .filter(k => k !== CustomVariableType.Order)
                          .map((el, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={el}
                                onClick={() =>
                                  setNewCustomVariableInfo({
                                    ...newCustomVariableInfo,
                                    ...(isValueNecessary && { value: "" }),
                                    type: CustomVariableType[el as keyof typeof CustomVariableType],
                                  })
                                }
                                data-pw={el}
                              >
                                {vt(el)}
                              </MenuItem>
                            );
                          })
                      : includedTypes().map((el, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={el}
                              onClick={() =>
                                setNewCustomVariableInfo({
                                  ...newCustomVariableInfo,
                                  ...(isValueNecessary && { value: "" }),
                                  type: CustomVariableType[el as keyof typeof CustomVariableType],
                                })
                              }
                              data-pw={el}
                            >
                              {vt(el)}
                            </MenuItem>
                          );
                        })}
                  </MenuList>
                </Menu>
              </FormControl>
              {isValueNecessary && (
                <Box width="100%">
                  <Text my="10px" variant="medium" display={"flex"} alignItems={"center"}>
                    {t("flow.addVariablePopup.Value")} &nbsp;
                    <TooltipWithTouch
                      variant="dominoLight"
                      placement="top"
                      label={t("flow.addVariablePopup.Value should match the type")}
                    >
                      <QuestionOutlineIcon boxSize="14px" color="darkGrey" _hover={{ color: "darkPurple" }} />
                    </TooltipWithTouch>
                  </Text>
                  {getValueInput()}
                  <Box color="#f92222">
                    <Text minH="16px" variant="small">
                      {valueError && t(`flow.addVariablePopup.errors.${valueError}`)}
                    </Text>
                  </Box>
                </Box>
              )}
            </Flex>
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button onClick={() => props.onClose()} variant="dominoOutlineViolet" data-pw="cancel-button">
                {t("commonWords.Cancel")}
              </Button>
              <Button
                variant="dominoViolet"
                onClick={() => onCreate()}
                colorScheme={`${isFlow ? "blue" : "whatsapp"}`}
                data-pw="create-button"
              >
                {t("commonWords.Create")}
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <script>{blockHeight()}</script>
    </>
  );
};
