import { Flex, Heading, Button, Box, Link } from "@chakra-ui/react";
import { useAppDispatch } from "../../../../common/state/store";
import { stopFlow } from "../../ContactInfoSlice";
import s from "../../ContactInfo.module.scss";
import { ContactInfoModel } from "../../ContactInfoModel";
import { useTranslation } from "react-i18next";
import GTM from "../../../../common/ga/GAEventTracker";
import { ChatSettings } from "../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  contactInfo: ContactInfoModel;
}

export default function ContactInfoFlow({ contactInfo }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const trackEvent = GTM(EventCategories.Chats);

  return (
    <Flex className={s.contactInFlow} flexDir={"column"} justifyContent={"center"}>
      <Flex justifyContent="space-between" alignItems="center" gap="16px" flexDirection={{ base: "column", "2xl": "row" }}>
        <Heading fontSize={"16px"} fontWeight={"400"}>
          {contactInfo.activeFlow ? t("Contact is in Flow") : t("Contact is not in Flow now")}
        </Heading>
        {contactInfo.activeFlow && (
          <Button
            variant="dominoOutlineRed"
            flexShrink={0}
            onClick={() => {
              dispatch(
                stopFlow({
                  flowExecutionId: contactInfo.activeFlow?.id ?? "",
                }),
              );
              trackEvent(ChatSettings.ChatStopFlow);
            }}
            data-pw="stop-flow-btn"
          >
            {t("Stop Flow")}
          </Button>
        )}
      </Flex>
      {contactInfo.activeFlow && (
        <Box mt={"8px"}>
          <Link
            href={`/automation/flows/${contactInfo.activeFlow.sourceFlowId}`}
            isExternal
            fontSize={"15px"}
            color={"blueLink"}
            data-pw="active-flow-link"
            onClick={() => trackEvent(ChatSettings.ChatFlowLink)}
          >
            {contactInfo.activeFlow.title}
          </Link>
        </Box>
      )}
    </Flex>
  );
}
