import { Box, Icon, Tab, TabList, Tabs } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { ReactComponent as QuestionIcon } from "../../assets/icons/questionIcon.svg";
import { TooltipWithTouch } from "../../common/tooltipWithTouch/TooltipWithTouch";
import s from "./Automation.module.scss";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../sidebar/SidebarSlice";
import { useAppSelector } from "../../common/state/store";
import { selectFlowList } from "./AutomationSlice";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../common/ga/GAEventTracker";
import { FlowEvents } from "../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

export const AutomationLayout = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const location = useLocation();
  const navigate = useNavigate();
  const currentBot = useAppSelector(selectCurrentBot);
  const getFlowList = useAppSelector(selectFlowList);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const isLoadingFlowList = useGetLoadingState("getFlowList");
  const [searchParams] = useSearchParams("");
  const search = searchParams.get("search") ?? "";
  const trackEvent = GTM(EventCategories.Flow);

  const getIndex = useCallback(() => {
    if (location.pathname.includes("flows")) {
      return 0;
    }
    if (location.pathname.includes("triggers")) {
      return 1;
    }
    return 0;
  }, [location.pathname]);

  return (
    <>
      <Box className={s.desktopAutomatinLayout} height="100%">
        {((currentBot && getFlowList && getFlowList.totalItems) || isCurrentBotLoading || isLoadingFlowList || search) && (
          <Tabs index={getIndex()} isManual>
            <TabList>
              <Tab
                data-pw="flows-tab"
                onClick={() => {
                  navigate("automation/flows");
                  trackEvent(FlowEvents.FlowList);
                }}
              >
                {t("Flows")}
              </Tab>
              <Tab
                data-pw="triggers-tab"
                onClick={() => {
                  navigate("automation/triggers");
                  trackEvent(FlowEvents.FlowTrigger);
                }}
                position={"relative"}
              >
                {t("Triggers")}
                {getIndex() === 1 && (
                  <TooltipWithTouch
                    variant="dominoLight"
                    label={t("Triggers will be activated in sequence specified in the list")}
                    placement="auto"
                    maxW={"270px"}
                  >
                    <Icon
                      as={QuestionIcon}
                      ml={"4px"}
                      boxSize={"15px"}
                      borderRadius={"50%"}
                      fill="darkGrey"
                      _hover={{ fill: "darkPurple" }}
                      position={"absolute"}
                      top={"50%"}
                      right={0}
                      marginTop={"-7px"}
                      marginRight={"-14px"}
                    />
                  </TooltipWithTouch>
                )}
              </Tab>
            </TabList>
          </Tabs>
        )}

        <Box height="100%">
          <Outlet />
        </Box>
      </Box>
      <Box className={s.mobileAutomatinLayout} height="100%">
        {((currentBot && getFlowList && getFlowList.totalItems) || isCurrentBotLoading || isLoadingFlowList || search) && (
          <Tabs index={getIndex()} isManual>
            <TabList>
              <Tab
                data-pw="flows-tab"
                onClick={() => {
                  navigate("automation/flows");
                  trackEvent(FlowEvents.FlowList);
                }}
              >
                {t("Flows")}
              </Tab>
              <Tab
                data-pw="triggers-tab"
                onClick={() => {
                  navigate("automation/triggers");
                  trackEvent(FlowEvents.FlowTrigger);
                }}
                position={"relative"}
              >
                {t("Triggers")}
                {getIndex() === 1 && (
                  <TooltipWithTouch
                    variant="dominoLight"
                    label={t("Triggers will be activated in sequence specified in the list")}
                    placement="auto"
                    maxW={"270px"}
                  >
                    <Icon
                      as={QuestionIcon}
                      ml={"4px"}
                      boxSize={"15px"}
                      borderRadius={"50%"}
                      fill="darkGrey"
                      _hover={{ fill: "darkPurple" }}
                      position={"absolute"}
                      top={"50%"}
                      right={0}
                      marginTop={"-7px"}
                      marginRight={"-14px"}
                    />
                  </TooltipWithTouch>
                )}
              </Tab>
            </TabList>
          </Tabs>
        )}
        <Box height="100%">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
