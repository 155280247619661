import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import s from "./EditContact.module.scss";
import { Box, Button, Container, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  getContact,
  selectContact,
  getContactVariables,
  clearContactState,
  changeContactVariableValue,
  createCustomVariable,
  deleteVariable,
  clearVariableUsage,
  checkVariableUsage,
  selectVariableInFlowUsage,
  selectPagingContactVariables,
  selectVariableInBroadcastUsage,
  changeContactVariableDescription,
} from "../../ContactSlice";
import { AppSettings } from "../../../../common/AppSettings";
import { ContactVariableModel } from "../../ContactModel";
import { AddVariablePopup } from "../../../modals/addVariable/addVariableModal/AddVariableModal";
import { CustomVariableModel, CustomVariableScope, CustomVariableType } from "../../../../common/AppEnums";
import { BotVariableDeletePopup } from "../../../bot/components/BotVariableDeletePopup/BotVariableDeletePopup";
import { EditContactInfo } from "./EditContactInfo/EditContactInfo";
import { EditContactVariables } from "./EditContactVariables/EditContactVariables";
import { EditContactValueInput } from "./EditContactValueInput/EditContactValueInput";
import { mapDescriptionToObjectData, mapValuesToObjectData } from "./EditContactMapping";
import { getPlaceholder, numberValidated } from "./EditContactValidation";
import { EditContactValueInputMobile } from "./EditContactValueInput/EditContactValueInputMobile";
import { EditContactPaginator } from "./EditContactPaginator/EditContactPaginator";
import { useNowWidthView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { MOBILE_VIEW, TABLET_VIEW } from "../../../layout/LayoutHelper/ResolutionConst";
import { EDIT_CONTACTS_PAGE_SIZE } from "../../../../common/paginator/paginatorSizes";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";

declare const appSettings: AppSettings;

export const EditContact = () => {
  const { t } = useTranslation("translation", { keyPrefix: "contact" });
  const dispatch = useAppDispatch();
  const { contactId } = useParams();
  const contact = useAppSelector(selectContact);
  const contactPagingVariables = useAppSelector(selectPagingContactVariables);
  const variableInFlowUsage = useAppSelector(selectVariableInFlowUsage);
  const variableInBroadcastUsage = useAppSelector(selectVariableInBroadcastUsage);

  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();
  const [variablesText, setVariablesText] = useState<Record<string, unknown> | undefined>({});
  const [variablesDescription, setVariablesDescription] = useState<Record<string, unknown> | undefined>({});
  const [addVariablePopup, setAddVariablePopup] = useState(false);
  const [deleteVariableState, setDeleteVariableState] = useState<ContactVariableModel | undefined>();

  const [valueMobileModal, setValueMobileModal] = useState<string | null>(null);

  const onAddBotFieldModalClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setAddVariablePopup(true);
  };

  useEffect(() => {
    return () => {
      dispatch(clearContactState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (contactId && !contact) {
      dispatch(getContact({ contactId }));
      dispatch(getContactVariables({ contactId, page: "1", size: EDIT_CONTACTS_PAGE_SIZE.toString() }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (contactPagingVariables) {
      setVariablesText(mapValuesToObjectData(contactPagingVariables?.items));
      setVariablesDescription(mapDescriptionToObjectData(contactPagingVariables?.items));
    }
  }, [contactPagingVariables]);

  const changeVariable = (contactVariable: ContactVariableModel, defaultValue: ContactVariableModel) => {
    const page = contactPagingVariables?.currentPage.toString() ?? "1";
    const size = EDIT_CONTACTS_PAGE_SIZE.toString();
    if (contactVariable.value !== null) {
      if (contactId && contactVariable.value !== (defaultValue.value ?? "")) {
        if (contactVariable.type === CustomVariableType.DateTime) {
          dispatch(changeContactVariableValue({ contactId, contactVariable, page, size }));
        }
        dispatch(changeContactVariableValue({ contactId, contactVariable, page, size }));
      }
    }
    if (contactId && contactVariable.description !== defaultValue.description) {
      dispatch(changeContactVariableDescription({ contactId, contactVariable, page, size }));
    }
  };

  const changeMobileVariable = (contactVariable: ContactVariableModel, defaultValue: ContactVariableModel) => {
    const page = contactPagingVariables?.currentPage.toString() ?? "1";
    const size = EDIT_CONTACTS_PAGE_SIZE.toString();
    if (contact && contactVariable.value !== null) {
      if (
        contactId &&
        (contactVariable.value !== (defaultValue.value ?? "") || contactVariable.description !== defaultValue.description)
      ) {
        dispatch(changeContactVariableValue({ contactId, contactVariable, page, size }));
        dispatch(changeContactVariableDescription({ contactId, contactVariable, page, size }));
      }
    }
  };

  const createVariable = (variable: CustomVariableModel) => {
    const page = contactPagingVariables?.currentPage.toString() ?? "1";
    const size = EDIT_CONTACTS_PAGE_SIZE.toString();
    dispatch(createCustomVariable({ variable, page, size }));
    setAddVariablePopup(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) return;
    if (e.key === "Enter") {
      const target = e.target as HTMLInputElement;
      target.blur();
    }
  };

  const onVariableDelete = (variableId?: string) => {
    if (!variableId) return;

    const page = contactPagingVariables?.currentPage.toString() ?? "1";
    const isLastVariableOnPage = contactPagingVariables.items?.length === 1 && contactPagingVariables.currentPage !== 1;
    const size = EDIT_CONTACTS_PAGE_SIZE.toString();
    dispatch(deleteVariable({ variableId, page: isLastVariableOnPage ? `${Number(page) - 1}` : page, size }));
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
    // if (contactId) {
    //   dispatch(getContactVariables({ contactId, page, size: EDIT_CONTACTS_PAGE_SIZE.toString() }));
    // }
  };

  const variableDeleteConfirm = (variable: ContactVariableModel) => {
    if (!variable.id) return;

    setDeleteVariableState(variable);
    dispatch(checkVariableUsage(variable.id));
  };

  const onCloseBotVariableUsagePopup = () => {
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
  };
  const getVariableValuePlaceholder = (type: CustomVariableType) => {
    const placeholder = getPlaceholder(type);
    return t(placeholder);
  };

  const changeTextInputNumber = (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => {
    if (!numberValidated(e.target.value) && e.target.value) {
      return;
    }
    setVariablesText(prevState => ({
      ...prevState,
      [contactVar.id]: e.target.value,
    }));
  };

  const changeTextInputText = (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => {
    setVariablesText(prevState => ({
      ...prevState,
      [contactVar.id]: e.target.value,
    }));
  };

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    contactVar: ContactVariableModel,
    variablesText: Record<string, unknown>,
  ) => {
    if (!(variablesText[contactVar.id] as string).trim().length && (variablesText[contactVar.id] as string) !== "") {
      changeVariable({ ...contactVar }, contactVar);
      e.target.value = contactVar.value ?? "";
    } else if (!(variablesText[contactVar.id] as string).trim().length && contactVar.value == null) {
      e.target.value = "";
    } else {
      changeVariable({ ...contactVar, value: (variablesText[contactVar.id] as string).trim() }, contactVar);
      e.target.value = (variablesText[contactVar.id] as string).trim();
    }
    e.target.placeholder = `${t("Not set")}`;
    e.target.type = "text";
  };

  const handleOnBlurDescription = (
    contactVar: ContactVariableModel,
    variablesDescription: Record<string, unknown> | undefined,
  ) => {
    if (
      (variablesDescription &&
        !(variablesDescription[contactVar.id] as string).trim().length &&
        (variablesDescription[contactVar.id] as string) !== "") ||
      (variablesDescription && contactVar.description === (variablesDescription[contactVar.id] as string).trim())
    ) {
      setVariablesDescription(prevState => ({
        ...prevState,
        [contactVar.id]: contactVar.description,
      }));
    } else {
      changeVariable(
        { ...contactVar, description: variablesDescription && (variablesDescription[contactVar.id] as string).trim() },
        contactVar,
      );
    }
  };

  const executeMobileModal = (type: CustomVariableType, contactVar: ContactVariableModel) => {
    switch (type) {
      case CustomVariableType.Date:
      case CustomVariableType.Boolean:
      case CustomVariableType.DateTime:
        if (variablesText) {
          changeMobileVariable(
            {
              ...contactVar,
              value: valueMobileModal ?? (variablesText[contactVar.id] as string).trim(),
              description: variablesDescription && (variablesDescription[contactVar.id] as string).trim(),
            },
            contactVar,
          );
        }
        setValueMobileModal(null);
        return;
      case CustomVariableType.Text:
      case CustomVariableType.Number:
      case CustomVariableType.Array:
        if (variablesText) {
          changeMobileVariable(
            {
              ...contactVar,
              value: valueMobileModal ?? (variablesText[contactVar.id] as string).trim(),
              description: variablesDescription && (variablesDescription[contactVar.id] as string).trim(),
            },
            contactVar,
          );
        }
        setValueMobileModal(null);
        return;
    }
  };

  const getValueInput = (contactVar: ContactVariableModel, isMobile?: boolean) => {
    if (variablesText) {
      if (!isMobile) {
        return (
          <EditContactValueInput
            contactVar={contactVar}
            variablesText={variablesText}
            setVariablesText={setVariablesText}
            changeVariable={changeVariable}
            handleKeyPress={handleKeyPress}
            changeTextInputNumber={changeTextInputNumber}
            changeTextInputText={changeTextInputText}
            handleOnBlur={handleOnBlur}
            getVariableValuePlaceholder={getVariableValuePlaceholder}
          />
        );
      } else {
        return (
          <EditContactValueInputMobile
            contactVar={contactVar}
            variablesText={variablesText}
            setVariablesText={setVariablesText}
            handleKeyPress={handleKeyPress}
            handleOnBlur={handleOnBlur}
            getVariableValuePlaceholder={getVariableValuePlaceholder}
            isMobile={isMobile}
            valueMobileModal={valueMobileModal ?? ""}
            setValueMobileModal={setValueMobileModal}
          />
        );
      }
    }
    return <></>;
  };

  return (
    <Box
      bg="bgLight"
      height={
        nowWidth <= TABLET_VIEW
          ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "180px" : "120px"})`
          : "calc(100dvh - 60px)"
      }
    >
      <Box className={s.editContactContainer}>
        <Box className={s.contactEditInfo}>
          <EditContactInfo contact={contact} apiBaseUrl={appSettings.apiBaseUrl} />
          <Container className={s.contactFieldsContainer} variant="dominoContentBlock">
            <Box className={s.contactFieldsHeader}>
              <Text variant="extraLargeBold" className={s.title}>
                {t("Contact fields")}
              </Text>
              <Button className={s.button} variant="dominoDashedViolet" onClick={onAddBotFieldModalClick}>
                + {t("New Field")}
              </Button>
            </Box>
            {((contactPagingVariables.items && contactPagingVariables.items.length > 0) ||
              contactPagingVariables.items === undefined) && (
              <Box className={s.contactFieldsSubHeader}>
                <Text variant={"medium"}>{t("Name")}</Text>
                <Text variant={"medium"}>{t("Type")}</Text>
                <Text variant={"medium"}>{t("Value")}</Text>
                <Text variant={"medium"}>{t("Description")}</Text>
              </Box>
            )}
            <Box className={s.contactFieldsItems}>
              <EditContactVariables
                contactVariablesList={contactPagingVariables}
                getValueInput={getValueInput}
                handleKeyPress={handleKeyPress}
                handleOnBlurDescription={handleOnBlurDescription}
                setVariablesDescription={setVariablesDescription}
                variableDeleteConfirm={variableDeleteConfirm}
                variablesDescription={variablesDescription}
                executeMobileModal={executeMobileModal}
              />

              {addVariablePopup && (
                <AddVariablePopup
                  scope={CustomVariableScope.Contact}
                  isOpen={addVariablePopup}
                  onCreate={createVariable}
                  onClose={() => setAddVariablePopup(false)}
                />
              )}
              <BotVariableDeletePopup
                flows={variableInFlowUsage}
                broadcasts={variableInBroadcastUsage}
                variable={deleteVariableState}
                onConfirmDelete={onVariableDelete}
                onClose={() => onCloseBotVariableUsagePopup()}
              />
            </Box>
            <div />
          </Container>
        </Box>
        <EditContactPaginator />
      </Box>
    </Box>
  );
};
