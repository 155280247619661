import { TelegramAuthModel } from "./TelegramAuthModel";
import { AppSettings } from "../AppSettings";
import { BadRequestError, Error400Model, Error403Model } from "../ErrorModel";
import { getFingerprint } from "react-fingerprint";
import { httpPostIdentity } from "../BaseApi";
import i18n, { simplifyLanguageCode } from "../../common/i18next/i18n";

declare const appSettings: AppSettings;

export const telegramAuth = async (data: TelegramAuthModel) => {
  const fingerprint = await getFingerprint();
  data = { ...data, fingerprint: fingerprint, locale: i18n.resolvedLanguage ?? simplifyLanguageCode(i18n.language) };

  const res = await fetch(`${appSettings.apiIdentityProvider}/auth/telegram`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": i18n.resolvedLanguage ?? simplifyLanguageCode(i18n.language),
    },
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res?.status === 400) {
    const data: Error400Model = await res.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }

  if (res?.status === 403) {
    const error = { message: "notApproved" };
    throw new Error403Model(error.message);
  }

  if (res.status === 200) {
    return await res.json();
  }
};

export const telegramLinkedAccountAuth = (data: TelegramAuthModel) => {
  return httpPostIdentity("/auth/linkTelegram", data);
};
