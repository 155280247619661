import { Box, Button, Flex, Switch, Tab, TabList, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import s from "./BotContainer.module.scss";
import TGIcon from "../../assets/images/tgIcon.svg";
import { BotVariableModel } from "./BotModel";
import {
  checkVariableUsage,
  clearVariableUsage,
  createBotVariable,
  deleteBot,
  deleteBotVariable,
  editBotVariableDescription,
  editBotVariableValue,
  getBotInfo,
  getBotVariables,
  selectBotInfo,
  selectBotMenuCommands,
  selectBotVariables,
  selectBotVariableUsage,
  switchBotMenuCommands,
} from "./BotSlice";
import { BotInfo } from "./components/BotInfo/BotInfo";
import { BotVariables } from "./components/BotVariables/BotVariables";
import { AddVariablePopup } from "../modals/addVariable/addVariableModal/AddVariableModal";
import { BotVariableDeletePopup } from "./components/BotVariableDeletePopup/BotVariableDeletePopup";
import { BotRemovePopup } from "./components/BotRemovePopup/BotRemovePopup";
import { useTranslation } from "react-i18next";
import { RefreshTokenPopup } from "./components/RefreshTokenPopup/RefreshTokenPopup";
import { CustomVariableScope } from "../../common/AppEnums";
import MenuCommands from "./components/MenuCommands/MenuCommands";
import AddCommandPopup from "./components/MenuCommands/AddCommandPopup/AddCommandPopup";
import useGA from "../../common/ga/GAEventTracker";
import { BotCommandsEvents, BotFieldsEvents } from "../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

export const BotContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const trackEvent = useGA(EventCategories.Bot);
  const dispatch = useAppDispatch();
  const { botId } = useParams();
  const [SearchParams, setSearchParams] = useSearchParams("");
  const botInfo = useAppSelector(selectBotInfo);
  const botVariables = useAppSelector(selectBotVariables);
  const botVariableUsage = useAppSelector(selectBotVariableUsage);
  const botMenuCommands = useAppSelector(selectBotMenuCommands);

  const { isOpen: isOpenBotRemoveModal, onOpen: onOpenBotRemoveModal, onClose: onCloseBotRemoveModal } = useDisclosure();
  const { isOpen: isOpenRefreshTokenModal, onOpen: onOpenRefreshTokenModal, onClose: onCloseRefreshTokenModal } = useDisclosure();
  const { isOpen: isOpenAddCommandModal, onOpen: onOpenAddCommandModal, onClose: onCloseAddCommandModal } = useDisclosure();
  const [isAllActiveFlag, setIsAllActiveFlag] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [addVariablePopup, setAddVariablePopup] = useState(false);
  const [deleteVariableState, setDeleteVariableState] = useState<BotVariableModel | undefined>();
  const [deleteBotPopupState, setDeleteBotPopupState] = useState<{ id: string; title: string } | undefined>();
  const botContainerRef = useRef<null | HTMLDivElement>(null);

  enum ActiveTab {
    "Fields" = "fields",
    "Commands" = "commands",
  }

  const selectActiveTab = (tab: string) => {
    switch (tab) {
      case ActiveTab.Fields:
        setActiveTab(0);
        setSearchParams({ tab: ActiveTab.Fields });
        return 0;
      case ActiveTab.Commands:
        setActiveTab(1);
        setSearchParams({ tab: ActiveTab.Commands });
        return 1;
      default:
        setActiveTab(0);
        setSearchParams({ tab: ActiveTab.Fields });
        return 0;
    }
  };

  const hangleSwitchCommandActive = () => {
    if (botId) {
      const newIsActiveState = !isAllActiveFlag;
      if (newIsActiveState) {
        trackEvent(BotCommandsEvents.BotCommandsActivate);
      } else {
        trackEvent(BotCommandsEvents.BotCommandDeactivate);
      }

      setIsAllActiveFlag(newIsActiveState);
      dispatch(switchBotMenuCommands({ botId, data: { isActive: newIsActiveState } }));
    }
  };

  const onBotFieldTabSelect = () => {
    selectActiveTab(ActiveTab.Fields);
    trackEvent(BotFieldsEvents.BotFields);
  };

  const onMenuCommandTabSelect = () => {
    selectActiveTab(ActiveTab.Commands);
    trackEvent(BotCommandsEvents.BotCommands);
  };

  const onOpenAddCommandModalClick = () => {
    onOpenAddCommandModal();
    trackEvent(BotCommandsEvents.BotCommandsNew);
  };

  useEffect(() => {
    if (botId) {
      dispatch(getBotInfo({ botId }));
      dispatch(getBotVariables({ botId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  useEffect(() => {
    if (botInfo) {
      setDeleteBotPopupState({
        id: botInfo.id,
        title: botInfo.name,
      });
    }
  }, [botInfo]);

  useEffect(() => {
    const tab = SearchParams.get("tab");
    if (tab) {
      selectActiveTab(tab);
    } else {
      setSearchParams({ tab: ActiveTab.Fields });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (botMenuCommands?.items?.every(item => item.isActive)) {
      setIsAllActiveFlag(true);
    } else {
      setIsAllActiveFlag(false);
    }
  }, [botMenuCommands?.items]);

  const onVariableCreate = () => {
    setAddVariablePopup(true);
    trackEvent(BotFieldsEvents.BotFieldsNew);
  };

  const onVariableValueEdit = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    dispatch(editBotVariableValue({ variableId: variable.id, variable: variable, botId }));
  };

  const onVariableDescriptionEdit = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    dispatch(editBotVariableDescription({ variableId: variable.id, variable: variable, botId }));
  };

  const onVariableDelete = (variableId?: string) => {
    if (!variableId || !botId) return;

    dispatch(deleteBotVariable({ variableId, botId }));
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
  };

  const variableDeleteConfirm = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    setDeleteVariableState(variable);
    dispatch(checkVariableUsage(variable.id));
  };

  const onCloseBotVariableUsagePopup = () => {
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
  };

  const createVariable = (variable: BotVariableModel) => {
    if (!botId) return;

    dispatch(createBotVariable({ variable, botId }));
    setAddVariablePopup(false);
  };

  const onCloseAddVariablePopup = () => {
    setAddVariablePopup(false);
  };

  const onDeleteBot = () => {
    if (botId) {
      dispatch(deleteBot(botId));
    }
  };

  if (!botId) {
    return <></>;
  }

  return (
    <>
      <Box
        className={s.botInfoContainer}
        ref={botContainerRef}
        bg={"bgLight"}
        height={window.innerWidth > 768 ? "calc(100vh - 80px)" : "100%"}
      >
        {botInfo && (
          <>
            <Box className={s.header}>
              {/* Type is hardcoded for now*/}
              <Box className={s.type}>
                <img alt="" className={s.typeImage} src={TGIcon}></img>
                <Text className={s.typeLabel}>{t("Telegram")}</Text>
              </Box>
            </Box>

            <Box
              borderRadius="12px"
              border="1px solid var(--chakra-colors-defaultGrey)"
              background="white"
              className={s.infoSection}
            >
              <BotInfo
                botInfo={botInfo}
                onOpenBotRemoveModal={onOpenBotRemoveModal}
                onOpenRefreshTokenModal={onOpenRefreshTokenModal}
              />
            </Box>
          </>
        )}
        {botInfo && (
          <Tabs index={activeTab} mt="24px" mb="16px" isManual>
            <TabList alignItems="center" m={0} justifyContent="space-between">
              <Flex>
                <Tab p={"8px 16px"} onClick={onBotFieldTabSelect} data-pw="botFields-tab">
                  {t("Variables")}
                </Tab>
                <Tab h="52px" onClick={onMenuCommandTabSelect} data-pw="menuCommands-tab" position={"relative"}>
                  {t("Menu Commands")}
                </Tab>
              </Flex>
              <Flex pb="8px" gap="16px" align="center">
                {activeTab === 1 && (
                  <Flex gap="8px">
                    <Text>{t("All Commands")}</Text>
                    <Switch
                      justifySelf="center"
                      variant="dominoDefaultGreen"
                      defaultChecked={false}
                      isChecked={isAllActiveFlag}
                      onChange={hangleSwitchCommandActive}
                    />
                  </Flex>
                )}
                {activeTab === 1 ? (
                  <Button onClick={onOpenAddCommandModalClick} maxW="max-content" variant="dominoDashedViolet">
                    + {t("New Command")}
                  </Button>
                ) : (
                  <Button maxW="max-content" variant="dominoDashedViolet" onClick={onVariableCreate} data-pw="new-variable">
                    + {t("New Variable")}
                  </Button>
                )}
              </Flex>
            </TabList>
          </Tabs>
        )}
        {botVariables && activeTab === 0 && (
          <Box className={s.infoSection}>
            <BotVariables
              variables={botVariables}
              onVariableCreate={onVariableCreate}
              onVariableDelete={variableDeleteConfirm}
              onVariableEdit={onVariableValueEdit}
              onVariableDescriptionEdit={onVariableDescriptionEdit}
            ></BotVariables>
          </Box>
        )}
        {activeTab === 1 && (
          <MenuCommands
            isOpenAddCommandModal={isOpenAddCommandModal}
            onCloseAddCommandModal={onCloseAddCommandModal}
            botId={botId}
            isAllActiveFlag={isAllActiveFlag}
            botRef={botContainerRef}
          />
        )}
      </Box>

      {addVariablePopup && (
        <AddVariablePopup
          scope={CustomVariableScope.Bot}
          isOpen={addVariablePopup}
          botId={botId}
          onCreate={createVariable}
          onClose={() => onCloseAddVariablePopup()}
        />
      )}

      <RefreshTokenPopup onClose={onCloseRefreshTokenModal} isOpen={isOpenRefreshTokenModal} botInfo={botInfo} />

      {deleteBotPopupState && (
        <BotRemovePopup
          onClose={onCloseBotRemoveModal}
          isOpen={isOpenBotRemoveModal}
          onDelete={onDeleteBot}
          deleteBotPopupState={deleteBotPopupState}
        />
      )}

      <BotVariableDeletePopup
        flows={botVariableUsage}
        variable={deleteVariableState}
        onConfirmDelete={onVariableDelete}
        onClose={() => onCloseBotVariableUsagePopup()}
      />
      <AddCommandPopup isOpen={isOpenAddCommandModal} onClose={onCloseAddCommandModal} botId={botId} />
    </>
  );
};
