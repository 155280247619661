import { Box, Flex } from "@chakra-ui/react";
import { TriggerListContainer } from "../../../../automation/components/TriggerListContainer";

export const TriggersPage = () => {
  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <Box bg="bgLight" height={"100%"}>
        <TriggerListContainer />
      </Box>
    </Flex>
  );
};
