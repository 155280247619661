import { ConversationInfoData } from "../conversation/ConversationData";
import { ConversationInfoModel } from "../conversation/ConversationModel";
import { store } from "../../index";
import { GroupChatListData } from "./GroupChatData";
import { GroupChatListItemModel, GroupChatListModel } from "./GroupChatModel";

export const mapGroupChatListDataToModel = (data: GroupChatListData, count: number): GroupChatListModel => {
  return {
    ...data,
    totalItems: count,
    items: data.items,
  };
};

export const mapConversationInfoModelToModelList = (data: ConversationInfoModel): GroupChatListItemModel => {
  return {
    id: data.id,
    botId: data.botId,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    assignedOperator: data.assignedOperator,
    assignedTeam: data.assignedTeam,
    contact: data.contact
      ? {
          ...data.contact,
          fullName: `${data.contact.firstName} ${data.contact.lastName ?? ""}`.trim(),
        }
      : undefined,
    isNew: data.isNew,
    createDate: data.createDate,
  };
};

export const mapConversationInfoDataToModel = (data: ConversationInfoData): ConversationInfoModel => {
  const conversationInfo = store.getState().app.conversationState.conversationInfo;
  if (conversationInfo) {
    return {
      id: data.id,
      botId: data.botId,
      contact: data.contact,
      name: data.name,
      status: data.status,
      lastMessage: data.lastMessage,
      unreadMessageCount: data.unreadMessageCount,
      flowExecutionId: data.flowExecutionId,
      activeFlow: data.activeFlow,
      isBlocked: data.isBlocked,
      assignedOperator: conversationInfo.assignedOperator,
      assignedTeam: conversationInfo.assignedTeam,
      createDate: conversationInfo.createDate,
    };
  }
  return {
    id: data.id,
    botId: data.botId,
    contact: data.contact,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    activeFlow: data.activeFlow,
    createDate: data.createDate,
    isBlocked: data.isBlocked,
  };
};
