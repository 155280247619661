import { Box } from "@chakra-ui/react";
import React from "react";
import { GroupChatInfo } from "./components/GroupChatInfo/GroupChatInfo";
import s from "../../GroupChatContainer.module.scss";
import { GroupChatConversation } from "./components/GroupChatConversation/GroupChatConversation";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOpenGroupChatInfo } from "./components/GroupChatInfo/GroupChatInfoSlice";
export const GroupChatMain = () => {
  const isOpenInfo = useAppSelector(selectIsOpenGroupChatInfo);

  return (
    <>
      <Box className={s.groupChatMessages}>
        <GroupChatConversation />
      </Box>
      <Box className={`${s.groupChatInfo} ${isOpenInfo ? s.open : ""}`}>
        <GroupChatInfo />
      </Box>
    </>
  );
};
