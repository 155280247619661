import { ConversationInfoData } from "../conversation/ConversationData";
import { ConversationInfoModel } from "../conversation/ConversationModel";
import { ChatListData } from "./ChatListData";
import { ChatListItemModel, ChatListModel } from "./ChatListModel";
import { store } from "../../index";

export const mapChatListDataToModel = (data: ChatListData, count: number): ChatListModel => {
  return {
    ...data,
    totalItems: count,
    items: data.items.map(({ contact, ...rest }) => {
      return {
        ...rest,
        contact: contact
          ? {
              ...contact,
              fullName: `${contact.firstName} ${contact.lastName ?? ""}`.trim(),
            }
          : undefined,
      };
    }),
  };
};

export const mapConversationInfoModelToModelList = (data: ConversationInfoModel): ChatListItemModel => {
  return {
    id: data.id,
    botId: data.botId,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    assignedOperator: data.assignedOperator,
    assignedTeam: data.assignedTeam,
    contact: data.contact
      ? {
          ...data.contact,
          fullName: `${data.contact.firstName} ${data.contact.lastName ?? ""}`.trim(),
        }
      : undefined,
    isNew: data.isNew,
    isBlocked: data.isBlocked,
    createDate: data.createDate,
  };
};

export const mapConversationInfoDataToModel = (data: ConversationInfoData): ConversationInfoModel => {
  const conversationInfo = store.getState().app.conversationState.conversationInfo;
  if (conversationInfo) {
    return {
      id: data.id,
      botId: data.botId,
      contact: data.contact,
      name: data.name,
      status: data.status,
      lastMessage: data.lastMessage,
      unreadMessageCount: data.unreadMessageCount,
      flowExecutionId: data.flowExecutionId,
      activeFlow: data.activeFlow,
      isBlocked: data.isBlocked,
      assignedOperator: conversationInfo.assignedOperator,
      assignedTeam: conversationInfo.assignedTeam,
      createDate: conversationInfo.createDate,
    };
  }
  return {
    id: data.id,
    botId: data.botId,
    contact: data.contact,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    activeFlow: data.activeFlow,
    createDate: data.createDate,
    isBlocked: data.isBlocked,
  };
};
