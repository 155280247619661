import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Flex,
  Text,
  Box,
  Icon,
} from "@chakra-ui/react";
import { ChangeEvent, useState, DragEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { notify } from "../../../../common/notifications/NotificationSlice";
import { ReactComponent as FileIcon } from "../../../../assets/icons/fileIcon.svg";
import { ReactComponent as CircleErrorIcon } from "../../../../assets/icons/circleCloseIcon.svg";
import { ReactComponent as attentionIcon } from "../../../../assets/icons/attention.svg";
import { ReactComponent as CircleAlertIcon } from "../../../../assets/icons/circleAlert.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import s from "./ImportFlowModal.module.scss";
import { selectImportResultsFlow, setImportResultsFlow } from "../../AutomationSlice";
import { selectCurrentBotId } from "../../../sidebar/SidebarSlice";
import { importStatusResultEnum } from "../../AutomationData";
import { createImportFlow } from "../../../flowBuilder/FlowBuilderSlice";
import { useGA } from "../../../../common/ga/GAEventTracker";
import { FileNameSplitter } from "../../../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

const MAX_SIZE_10MB = 10485760;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ImportFlowModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "importFlow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const dispatch = useAppDispatch();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const importResult = useAppSelector(selectImportResultsFlow);
  const loadingState = useGetLoadingState("importFlow");
  const selectBotId = useAppSelector(selectCurrentBotId);
  const [isDragging, setDragging] = useState(false);
  const trackEvent = useGA(EventCategories.Flow);

  useEffect(() => {
    dispatch(setImportResultsFlow({ importResults: null }));
  }, [dispatch]);

  const chooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file && file.size && file.size <= MAX_SIZE_10MB) {
      setCurrentFile(e.target?.files?.[0] ?? null);
      dispatch(setImportResultsFlow({ importResults: null }));
      trackEvent(FlowEvents.FlowImportChooseFile);
    } else if (file && file.size && file.size > MAX_SIZE_10MB) {
      dispatch(notify({ message: t("File is too big"), type: "error" }));
    }
  };

  const uploadFile = (submitWarnings?: boolean) => {
    if (currentFile) {
      dispatch(createImportFlow({ file: currentFile, botId: selectBotId ?? "", submitWarnings }));
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentFile(null);
    dispatch(setImportResultsFlow({ importResults: null }));
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [, ext] = file.type.split("/");
      if (ext === "json") {
        if (file && file.size && file.size <= MAX_SIZE_10MB) {
          setCurrentFile(file ?? null);
          trackEvent(FlowEvents.FlowImportChooseFile);
          dispatch(setImportResultsFlow({ importResults: null }));
        } else if (file && file.size && file.size > MAX_SIZE_10MB) {
          dispatch(notify({ message: t("File is too big"), type: "error" }));
        }
      } else {
        dispatch(notify({ message: t("Select a JSON file"), type: "error" }));
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!currentFile) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "300px", sm: "460px" }} data-pw="import-flow-modal">
          <ModalHeader p={{ base: "24px", sm: "32px" }}>
            {!importResult ? (
              <Text variant="h1">{t("Import Flow")}</Text>
            ) : (
              <Flex direction="column">
                {importResult.status !== importStatusResultEnum.Error ? (
                  <>
                    <Text variant="h1">{t("Import Flow")}</Text>
                  </>
                ) : (
                  <>
                    <Flex align="center" gap="8px">
                      <Icon data-pw="error-icon" as={CircleErrorIcon} color="#EB5038" boxSize="40px" />
                      <Text data-pw="error-text" color="#EB5038" variant="h1">
                        {t("Import has been Error")}
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton
            data-pw="close-button"
            borderRadius={"50%"}
            color={"darkGrey"}
            _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          >
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px={{ base: "24px", sm: "32px" }} py="0" className={s.modalBody}>
            {!importResult && <Text variant="medium">{t("Select a JSON  file containing your Flow templates")}</Text>}
            <Box
              className={s.fileUploadContainer}
              transition={"all .3s"}
              borderStyle={importResult ? "solid !important" : "dashed !important"}
              borderColor={isDragging ? "mainPurple !important" : "line !important"}
              _hover={{ borderColor: "mainPurple !important" }}
              bgColor={"#F7FAFF"}
              onDragEnter={handleDrag}
            >
              <Flex alignItems="center" gap="8px" direction="column">
                {!importResult ? (
                  <>
                    {isDragging ? (
                      <>
                        <Icon color="#DCE7FB" as={FileIcon} boxSize="80px" />
                      </>
                    ) : (
                      <>
                        <Icon color="black" as={FileIcon} boxSize="24px" />
                      </>
                    )}

                    {currentFile ? (
                      <Text color="black" variant="largeBold" data-pw="uploadedFile">
                        <FileNameSplitter fileName={currentFile.name} />
                      </Text>
                    ) : (
                      <>
                        {isDragging ? (
                          <>
                            <Text color="darkGrey" variant="medium">
                              {t("Drag and drop the file here")}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text variant="largeBold">{t("Drag and drop the file here")}</Text>
                            <Text variant="medium" color="darkGrey">
                              {t("or press the button")}
                            </Text>
                            <Button htmlFor="file-upload" as="label" variant="dominoOutlineViolet" data-pw="select-file-button">
                              {t("Select file")}
                            </Button>
                            <Input id="file-upload" onChange={chooseFile} variant="unstyled" type="file" accept=".json" />
                          </>
                        )}
                      </>
                    )}
                    {isDragging && (
                      <Box
                        className={s.dragFileElement}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={onDropFile}
                      ></Box>
                    )}
                  </>
                ) : (
                  <Flex direction="column" gap="24px">
                    {importResult.status === importStatusResultEnum.Success ? (
                      <Flex direction="column" alignItems="center">
                        <Text variant="large" color="darkGrey" mb="4px">
                          {ct("Imported file")}:
                        </Text>
                        <Text color="black" variant="largeBold" data-pw="file-success">
                          <FileNameSplitter fileName={currentFile?.name ?? ""} />
                        </Text>
                      </Flex>
                    ) : (
                      <>
                        <Flex direction="column" alignItems="center">
                          <Text variant="large" color="darkGrey" mb="4px" data-pw="file-failed">
                            {t("File failed")}:
                          </Text>
                          <Text color="black" variant="largeBold">
                            <FileNameSplitter fileName={currentFile?.name ?? ""} />
                          </Text>
                        </Flex>
                        <Box
                          borderRadius={"8px"}
                          border={"1px solid #FFC300"}
                          width={"348px"}
                          p={"16px"}
                          bgColor={"#FEF6DC"}
                          maxH="max-content"
                        >
                          {importResult.status === importStatusResultEnum.Warning && (
                            <Flex color={"mainYellow"} gap={"8px"} direction={"column"} h={"max-content"}>
                              {importResult.variableWarnings.map(war => (
                                <Flex flexWrap={"wrap"} key={war.key} gap={"4px"} alignItems={"center"}>
                                  <Icon data-pw="warning-icon" as={attentionIcon} boxSize="16px" />
                                  <Text data-pw="warning-text" overflowWrap={"break-word"} color="#232B39" fontWeight={700}>
                                    {t(`${war.key} Fields`)}
                                  </Text>
                                  <Text color={"#232B39"}>{t("will be created")}:</Text>
                                  {war.data.map((field, ind) => (
                                    <Text key={field.key} fontSize={"15px"} color={"#3E5E95"}>
                                      {field.data.key}
                                      {ind !== war.data.length - 1 && ","}
                                    </Text>
                                  ))}
                                </Flex>
                              ))}
                              {importResult.warnings.map(error => (
                                <Flex flexWrap={"wrap"} key={error.key} gap={"8px"} alignItems={"center"}>
                                  <Icon data-pw="warning-icon" as={attentionIcon} boxSize="16px" />
                                  <Text data-pw="warning-text" fontSize={"13px"} color={"black"}>
                                    {t(error.key)}
                                  </Text>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                          {importResult.status === importStatusResultEnum.Error && (
                            <Flex color={"mainYellow"} gap={"8px"} direction={"column"} h={"max-content"}>
                              {importResult.errors.map(error => (
                                <Flex key={error.key} gap={"8px"} alignItems={"center"}>
                                  <Icon data-pw="warning-icon" as={CircleAlertIcon} boxSize="16px" />
                                  <Text data-pw="warning-text" fontSize={"13px"} color={"black"}>
                                    {t(error.key)}
                                  </Text>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                        </Box>
                      </>
                    )}
                  </Flex>
                )}
                <Loading scope="importFlow" />
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter p="32px">
            {importResult ? (
              <Flex width="100%" justifyContent="space-between">
                <Button data-pw="cancel-button" variant="dominoOutlineRed" onClick={handleClose}>
                  {ct("Cancel")}
                </Button>
                {importResult.status === importStatusResultEnum.Warning ? (
                  <Button data-pw="import-button" variant="dominoViolet" onClick={() => uploadFile(true)}>
                    {ct("Import")}
                  </Button>
                ) : (
                  <Button data-pw="close-button" variant="dominoViolet" onClick={handleClose}>
                    {ct("Close")}
                  </Button>
                )}
              </Flex>
            ) : (
              !((currentFile && importResult) || loadingState) && (
                <Flex width="100%" justifyContent="space-between">
                  <Button data-pw="cancel-button" variant="dominoOutlineRed" onClick={handleClose}>
                    {ct("Cancel")}
                  </Button>
                  <Button
                    isDisabled={!currentFile || loadingState}
                    variant="dominoViolet"
                    onClick={() => {
                      trackEvent(FlowEvents.FlowImportConfirm);
                      uploadFile();
                    }}
                    data-pw="import-button"
                  >
                    {ct("Import")}
                  </Button>
                </Flex>
              )
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
