import { all, call, put, select, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { conversationSlice, selectConversationInfo } from "./ConversationSlice";
import * as conversationApi from "./ConversationApi";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ConversationInfoData,
  ConversationMessagesData,
  CustomVariableData,
  FileData,
  MessageData,
  MessageTypeDiscriminator,
  OutgoingTypeDiscriminator,
  TaggedOperatorData,
} from "./ConversationData";
import {
  AssignedItemModel,
  ConversationMessageModel,
  ConversationMessagesModel,
  ConversationSendMessageModel,
  FileUploadType,
  MessageModel,
} from "./ConversationModel";
import { store } from "../..";
import { BadRequestError, NotFoundRequestError, ValidationError, ValidationModel } from "../../common/ErrorModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import * as mapper from "./ConversationMapper";
import { mapConversationInfoDataToConversationInfoModel, mapMessagesToSortMessages } from "./ConversationMapper";
import { signalRConnection } from "../../middlewares/signalRMiddleware";
import { CustomVariablesModel } from "../flowBuilder/FlowBuilderModel";
import { chatListSlice, selectChatList } from "../chatList/ChatListSlice";
import { mapConversationInfoModelToModelList } from "../chatList/ChatListMapper";
import { ChatListItemModel, ChatListModel } from "../chatList/ChatListModel";
import { MessageDirection } from "../../common/AppEnums";
import { systemNotificationSlice } from "../../common/systemNotifications/SystemNotificationSlice";
import { AppSettings } from "../../common/AppSettings";
import { FileValidate, FileValidateModel } from "../../common/validation/fileValidator";
import { contactInfoSlice } from "../contactInfo/ContactInfoSlice";
import GTM from "../../common/ga/GAEventTracker";
import { ChatMessageEvents } from "../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

declare const appSettings: AppSettings;

const trackEvent = GTM(EventCategories.Chats);

export function* conversationSaga() {
  yield takeLatest(conversationSlice.actions.getConversationInfo, getConversationInfo);
  yield takeLatest(conversationSlice.actions.getConversationMessages, getConversationMessages);
  yield takeLeading(conversationSlice.actions.sendConversationMessage, sendConversationMessage);
  yield takeLatest(conversationSlice.actions.getCustomVariables, getCustomVariables);
  yield takeLatest(conversationSlice.actions.setConversationMessage, setConversationMessage);
  yield takeEvery(conversationSlice.actions.uploadMessageFile, uploadMessageFile);
  yield takeEvery(conversationSlice.actions.messageSignalReceived, addMessage);
  yield takeEvery(conversationSlice.actions.readMessages, readMessages);
  yield takeLatest(conversationSlice.actions.assignConversation, assignConversation);
  yield takeLatest(conversationSlice.actions.closeConversation, closeConversation);
  yield takeLatest(conversationSlice.actions.blockConversation, blockConversation);
  yield takeLatest(conversationSlice.actions.unblockConversation, unblockConversation);
  yield takeEvery(conversationSlice.actions.getTaggedOperators, getTaggedOperators);

  signalRConnection.addHandler("receiveMessage", (data: MessageData) => {
    const model: MessageModel = mapper.mapMessageToModel(data);
    store.dispatch(conversationSlice.actions.messageSignalReceived(model));
  });
}

export function* getConversationInfo(action: PayloadAction<{ conversationId: string }>) {
  try {
    const conversationInfoData: ConversationInfoData = yield call(conversationApi.getConversation, action.payload.conversationId);
    const conversationInfoModel = mapConversationInfoDataToConversationInfoModel(conversationInfoData);
    yield put(conversationSlice.actions.getConversationInfoSucceed(conversationInfoModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* closeConversation(action: PayloadAction<{ conversationId: string }>) {
  try {
    yield call(conversationApi.closeConversation, action.payload.conversationId);
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* blockConversation(action: PayloadAction<{ conversationId: string }>) {
  try {
    yield put(beginScope("blockConversation"));
    yield call(conversationApi.blockConversation, action.payload.conversationId);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("blockConversation"));
  }
}

export function* unblockConversation(action: PayloadAction<{ conversationId: string }>) {
  try {
    yield put(beginScope("unblockConversation"));
    yield call(conversationApi.unblockConversation, action.payload.conversationId);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("unblockConversation"));
  }
}

export function* getConversationMessages(
  action: PayloadAction<{
    conversationId: string;
    lastId: string;
    searchValue?: string;
    currentSearchMessagesLength?: number;
    includeMessageId?: string;
  }>,
) {
  try {
    yield put(beginScope("messageList"));
    const messagesData: ConversationMessagesData = yield call(
      conversationApi.getConversationMessages,
      action.payload.conversationId,
      action.payload.lastId,
      action.payload.searchValue,
      action.payload.includeMessageId,
    );

    const currentMessages = store.getState().app.conversationState.messages;
    const updMessages: MessageModel[] = messagesData.items.map(message => {
      switch (message.typeDiscriminator) {
        case MessageTypeDiscriminator.SystemMessage: {
          return {
            ...message,
            text: message.text?.trim(),
          };
        }
        case MessageTypeDiscriminator.NoteTextConversation: {
          return {
            ...message,
            text: message.text?.trim(),
          };
        }
        case MessageTypeDiscriminator.Message:
        default: {
          return {
            ...message,
            replyToMessage: message.replyToMessage,
            text: message.text?.trim(),
            photos: message.photos.map(p => {
              return {
                ...p,
                url: p.fileId != null ? conversationApi.makeFileUrl(p.fileId) : "/noimage",
              };
            }),
          };
        }
      }
    });

    const concatedMessages = concatMessages(updMessages.reverse(), currentMessages?.items ?? []);
    const isLastPage = concatedMessages.length === messagesData.totalItems;
    const isNextSearch = !isLastPage && !(!messagesData.nextSearch && !!action.payload.searchValue);
    const totalSearchItems = currentMessages?.totalSearchItems
      ? currentMessages.totalSearchItems
      : (action.payload.currentSearchMessagesLength ?? 0) + (messagesData.totalSearchItems ?? 0);
    const model = {
      ...messagesData,
      items: concatedMessages,
      isNextSearch,
      totalSearchItems,
      lastId: messagesData.lastId ?? currentMessages?.lastId,
    };
    yield put(
      conversationSlice.actions.readMessages({
        conversationId: action.payload.conversationId,
        messageIds: model.items.map(message => message.id),
      }),
    );
    yield put(conversationSlice.actions.getConversationMessagesSucceed(model));
    if (action.payload.includeMessageId) {
      yield put(conversationSlice.actions.setScrollToMessageId({ messageId: action.payload.includeMessageId }));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("messageList"));
  }
}

export const concatMessages = (model: MessageModel[], prevModel: MessageModel[]) => {
  //remove filtration of messages by id

  // const messageIds = model.map(el => el.id);
  // const tuplePrevModel = prevModel.filter(el => !messageIds.includes(el.id));
  const newModel = [...model];
  newModel.push(...prevModel);
  return newModel;
};

export function* getCustomVariables(action: PayloadAction<{ botId: string }>) {
  try {
    const contactVariablesData: CustomVariableData[] = yield call(conversationApi.getContactCustomVariables);
    const systemVariablesData: CustomVariableData[] = yield call(conversationApi.getSystemVariables);
    const variablesModel = systemVariablesData.concat(contactVariablesData);
    yield put(conversationSlice.actions.getCustomVariablesSucceed(variablesModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* setConversationMessage(action: PayloadAction<ConversationSendMessageModel>) {
  try {
    const message = {
      ...action.payload,
    };
    // set type depends on content
    let typeDiscriminator = OutgoingTypeDiscriminator.OutgoingTextMessage;
    if (message.photo) {
      typeDiscriminator = OutgoingTypeDiscriminator.OutgoingPhotoMessage;
    }
    if (message.document) {
      typeDiscriminator = OutgoingTypeDiscriminator.OutgoingDocumentMessage;
    }
    const messageModel = {
      ...message,
      typeDiscriminator,
    };
    yield put(conversationSlice.actions.setConversationMessageCompleted(messageModel));
    validateSendMessage(messageModel);
    yield put(conversationSlice.actions.setMessageValidation({ errors: [] }));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(conversationSlice.actions.setMessageValidation(e.validationData));
    } else {
      yield handleException(e);
    }
  }
}

export function* sendConversationMessage(
  action: PayloadAction<{
    conversationId: string;
    message: ConversationSendMessageModel;
  }>,
) {
  try {
    yield put(beginScope("sendMessage"));
    if (action.payload.message.typeDiscriminator === OutgoingTypeDiscriminator.NoteTextConversation) {
      yield put(
        conversationSlice.actions.setConversationMessageCompleted({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.NoteTextConversation,
        }),
      );
      trackEvent(ChatMessageEvents.ChatMassageNoteSent);
    } else {
      yield put(
        conversationSlice.actions.setConversationMessageCompleted({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.OutgoingTextMessage,
        }),
      );
      trackEvent(ChatMessageEvents.ChatMassageSent);
    }
    const customVariables: CustomVariablesModel[] | undefined = store.getState().app.conversationState.variables;
    const conversationId = action.payload.conversationId;
    const message = action.payload.message;
    const messageData = {
      ...message,
      document: message.document?.id,
      photo: message.photo?.id,
    };

    const messages = mapper.mapMessageToArray(messageData, customVariables);
    yield all(messages.map(m => call(conversationApi.sendMessage, conversationId, m)));
    yield put(
      conversationSlice.actions.setMessageValidation({
        errors: [
          {
            field: "text",
            message: "Message is required",
          },
        ],
      }),
    );
    if (messageData.photo) {
      trackEvent(ChatMessageEvents.ChatMassageMediaSent);
      const filter = store.getState().app.chatListState.filter;
      if (filter.type === "closed") {
        yield put(contactInfoSlice.actions.getContactInfoFiles({ conversationId }));
      }
      if (action.payload.message.photo?.extension !== ".gif") {
        yield put(contactInfoSlice.actions.incMediaFilesCompleted());
      }
    }
    if (messageData.document) {
      trackEvent(ChatMessageEvents.ChatMassageAttachmentSent);
    }
    yield put(completeScope("sendMessage"));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(completeScope("sendMessage"));
  }
}

function* uploadMessageFile(action: PayloadAction<{ file: File; type: FileUploadType }>) {
  try {
    validateFile(action.payload.file, action.payload.type);
    yield put(beginScope("messageFileUpload"));
    const data: FileData = yield call(conversationApi.postFile, action.payload.file);
    const message = store.getState().app.conversationState.message;
    const updatedMessage = {
      ...message,
      ...(action.payload.type === FileUploadType.photo && { photo: data }),
      ...(action.payload.type === FileUploadType.document && {
        document: data,
      }),
    };

    yield put(conversationSlice.actions.setConversationMessage(updatedMessage));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "warning",
        }),
      );
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(completeScope("messageFileUpload"));
  }
}

function* addMessage(action: PayloadAction<MessageModel>) {
  const conversationInfo: ReturnType<typeof selectConversationInfo> = yield select(selectConversationInfo);
  const chatList: ReturnType<typeof selectChatList> = yield select(selectChatList);

  if (conversationInfo?.id !== action.payload.conversation.id || !document.hasFocus()) {
    if (action.payload.typeDiscriminator === MessageTypeDiscriminator.Message) {
      if (
        action.payload.direction === MessageDirection.Incoming &&
        (action.payload.text || action.payload.photos?.length || action.payload.documents?.length)
      ) {
        yield put(
          systemNotificationSlice.actions.notify({
            title: action.payload.conversation.name,
            message: action.payload.text ?? action.payload.documents[0]?.fileName ?? "",
            imageUrl: action.payload.photos?.at(2)?.url ?? action.payload.photos?.at(0)?.url,
            iconUrl: action.payload.conversation.contact?.avatars?.at(0)?.at(0)
              ? `${appSettings.apiBaseUrl}/file/${action.payload.conversation.contact.avatars[0][0].fileId}`
              : undefined,
            openPath: `/chats/${action.payload.conversationId}`,
            tag: action.payload.id,
          }),
        );
      }
    }
  }

  if (!chatList) {
    return;
  }

  if (conversationInfo?.id !== action.payload.conversation.id) {
    yield put(chatListSlice.actions.updateChatInfo(mapConversationInfoModelToModelList(action.payload.conversation)));
    return;
  }

  const currentMessages = store.getState().app.conversationState.messages;
  if (!currentMessages) {
    return;
  }

  let items = [...currentMessages.items];
  if (items.some(x => x.id === action.payload.id)) {
    items = items.map(x => {
      if (x.id === action.payload.id) {
        return action.payload;
      }
      return x;
    });
  } else {
    items = mapMessagesToSortMessages([...items, action.payload]);
  }

  const model: ConversationMessagesModel = {
    ...currentMessages,
    items,
  };

  if (action.payload.typeDiscriminator === MessageTypeDiscriminator.Message) {
    if (action.payload.direction === MessageDirection.Outgoing) {
      yield put(chatListSlice.actions.updateChatInfo(mapConversationInfoModelToModelList(action.payload.conversation)));
    }
    if (action.payload.direction === MessageDirection.Incoming) {
      const conversationLastMessage = action.payload as ConversationMessageModel;
      yield put(
        conversationSlice.actions.readMessages({
          conversationId: conversationInfo?.id,
          messageIds: [action.payload.id],
          lastMessage: conversationLastMessage,
        }),
      );
    }
  }
  yield put(conversationSlice.actions.getConversationMessagesSucceed(model));
}

function* readMessages(
  action: PayloadAction<{ conversationId: string; messageIds: string[]; lastMessage?: ConversationMessageModel }>,
) {
  try {
    const chatList: ChatListModel | undefined = store.getState().app.chatListState.chatList;
    if (chatList) {
      let chatListItem: ChatListItemModel | undefined = chatList.items.find(el => el.id === action.payload.conversationId);
      if (chatListItem) {
        const messagesPerPageCount = 10;
        let unreadMessageCount = parseInt(chatListItem.unreadMessageCount) - messagesPerPageCount;
        unreadMessageCount = unreadMessageCount > 0 ? unreadMessageCount : 0;
        const unreadMessageCountString = unreadMessageCount ? unreadMessageCount.toString() : "";
        const lastMessage = mapper.mapMessageModelToLastMessage(action.payload.lastMessage);
        chatListItem = {
          ...chatListItem,
          ...(lastMessage && { lastMessage }),
          unreadMessageCount: unreadMessageCountString,
        };
        yield put(chatListSlice.actions.updateChatInfo(chatListItem));
        yield call(conversationApi.readMessages, action.payload.conversationId, action.payload.messageIds);
      }
    }
  } catch (e: unknown) {
    if (e instanceof NotFoundRequestError) {
      yield put(
        notificationSlice.actions.notify({
          message: "Something wrong with API",
          type: "warning",
        }),
      );
    } else {
      yield handleException(e);
    }
  }
}

const validateSendMessage = (message: ConversationSendMessageModel) => {
  const inputMaxLength = 2000;

  const result: ValidationModel = {
    errors: [],
  };
  switch (message.typeDiscriminator) {
    case OutgoingTypeDiscriminator.OutgoingTextMessage: {
      if (message.text.length < 1) {
        result.errors.push({
          field: "text",
          message: "Message is required",
        });
      }
      if (message.text.length && message.text.trim().length === 0) {
        result.errors.push({
          field: "text",
          message: "Message contains only empty chars",
        });
      }
      if (message.text.length > inputMaxLength) {
        result.errors.push({
          field: "text",
          message: "Message is too long",
        });
      }
      break;
    }
    case OutgoingTypeDiscriminator.OutgoingDocumentMessage:
    case OutgoingTypeDiscriminator.OutgoingPhotoMessage: {
      if (message.text.length && message.text.trim().length === 0) {
        result.errors.push({
          field: "text",
          message: "Message contains only empty chars",
        });
      }
      if (message.text.length > inputMaxLength) {
        //we send photo and text as separate messages
        result.errors.push({
          field: "text",
          message: "Message is too long",
        });
      }
      break;
    }
  }

  if (result.errors.length > 0) {
    throw new ValidationError(result);
  }
};
const validateFile = (file: File, type: FileUploadType) => {
  const result: FileValidateModel = FileValidate(file, type);
  if (result.isInvalid) {
    throw new BadRequestError(result.error);
  }
};

export function* assignConversation(
  action: PayloadAction<{ conversationId: string; team?: AssignedItemModel; operator?: AssignedItemModel }>,
) {
  try {
    yield call(
      conversationApi.assignConversation,
      action.payload.conversationId,
      action.payload.team ? action.payload.team.id : null,
      action.payload.operator ? action.payload.operator.id : null,
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getTaggedOperators(name: PayloadAction<{ name: string }>) {
  try {
    const data: TaggedOperatorData[] = yield call(conversationApi.getOrganisationUsers, name.payload.name);
    const model = data;
    yield put(conversationSlice.actions.getTaggedOperatorsSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
