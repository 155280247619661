import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import { ReactComponent as FileIcon } from "../../../../assets/icons/fileIcon.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/imageChatIcon.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/chevronChatList.svg";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useAppSelector } from "../../../../common/state/store";
import { selectContactInfoMediaFiles } from "../../ContactInfoSlice";
import s from "./ContactInfoFiles.module.scss";
import GTM from "../../../../common/ga/GAEventTracker";
import { ChatSettings } from "../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  onOpenMediaClick: () => void;
  onOpenDocsClick: () => void;
}

export default function ContactInfoFiles({ onOpenMediaClick, onOpenDocsClick }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  // const isDocuments = contactInfoDocs;
  // const contactInfoDocs = useAppSelector(selectContactInfoDocFiles);
  const contactInfoMedia = useAppSelector(selectContactInfoMediaFiles);
  const isLoading = useGetLoadingState("getContactInfoFiles");
  const isMediaFiles = contactInfoMedia;
  const trackEvent = GTM(EventCategories.Chats);

  const handleClickMediaOpen = () => {
    if (isMediaFiles !== 0) {
      onOpenMediaClick();
    }
  };

  // const handleClickDocsOpen = () => {
  //   if (isDocuments !== 0) {
  //     onOpenDocsClick();
  //   }
  // };
  return (
    <Flex className={s.filesContainer}>
      <Flex
        className={`${s.filesGroupContainer} ${isMediaFiles && s.hover}`}
        cursor={"pointer"}
        onClick={() => {
          handleClickMediaOpen();
          trackEvent(ChatSettings.ChatMedia);
        }}
      >
        <Skeleton className={s.skeleton} isLoaded={!isLoading}>
          <Flex className={s.filesGroup}>
            <ImageIcon className={`${s.icon} ${isMediaFiles && s.hover}`} />
            <Text className={`${s.text} ${isMediaFiles && s.hover}`} variant="preLargeBold">
              {contactInfoMedia} {t("mediaFiles", { count: contactInfoMedia ?? 0 })}
            </Text>
          </Flex>
        </Skeleton>
        {contactInfoMedia ? <RightArrow className={`${s.arrow} ${s.icon} ${isMediaFiles && s.hover}`} /> : null}
      </Flex>
      {/* <Flex className={`${s.filesGroupContainer} ${isDocuments && s.hover}`} cursor={"pointer"} onClick={handleClickDocsOpen}>
        <Skeleton className={s.skeleton} isLoaded={!isLoading}>
          <Flex className={s.filesGroup}>
            <FileIcon className={`${s.icon} ${isDocuments && s.hover}`} />
            <Text className={`${s.text} ${isDocuments && s.hover}`} variant="preLargeBold">
              {contactInfoDocs} {t("docs", { count: contactInfoDocs ?? 0 })}
            </Text>
          </Flex>
        </Skeleton>
        {contactInfoDocs ? <RightArrow className={`${s.arrow} ${s.icon} ${isDocuments && s.hover}`} /> : null}
      </Flex> */}
    </Flex>
  );
}
