import { Paginator } from "../Paginator";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Box, ResponsiveValue } from "@chakra-ui/react";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../../../components/organisation/OrganisationModel";
import { ContactListPaginatorModel } from "../../../components/contactList/ContactListModel";
import { BotListModel } from "../../../components/sidebar/SidebarModel";
import { BroadcastListModel } from "../../../components/broadcastList/BroadcastListModel";
import { FlowListModel } from "../../../components/flowBuilder/FlowBuilderModel";
import { TriggerListModel } from "../../../components/automation/AutomationModel";
import { ContactVariableListPaginatorModel } from "../../../components/contact/ContactModel";
import { useEffect } from "react";
import { PaginatorModel } from "../PaginatorModel";

interface Props {
  data:
    | OrganisationUsersModel
    | OrganisationTeamsModel
    | ContactListPaginatorModel
    | BotListModel
    | BroadcastListModel
    | FlowListModel
    | TriggerListModel
    | ContactVariableListPaginatorModel;
  typeOfPage: string;
  paddingRight?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  paddingLeft?: string;
  loadingScope?: string;
  sizeItems: number;
}

export const PaginatorWithSearchParams = (props: Props) => {
  const { data, typeOfPage } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const filters = searchParams.get("filters") ?? "";
  useEffect(() => {
    const newParams: { page?: string; search?: string; filters?: string } = {};
    if (data.currentPage > 1) {
      newParams.page = data.currentPage.toString();
    }
    if (search !== "") {
      newParams.search = search;
    }
    if (filters !== "") {
      newParams.filters = filters;
    }
    setSearchParams(createSearchParams(newParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentPage]);

  const getPage = (page: number) => {
    const newParams: { page?: string; search?: string; filters?: string } = {};
    if (page > 1) {
      newParams.page = page.toString();
    }
    if (search !== "") {
      newParams.search = search;
    }
    if (filters !== "") {
      newParams.filters = filters;
    }
    setSearchParams(createSearchParams(newParams));
  };

  return (
    <Box position="sticky" bottom={0} width="100%">
      {data && (
        <Paginator
          getPage={getPage}
          data={data as PaginatorModel}
          typeOfPage={typeOfPage}
          paddingRight={props.paddingRight}
          paddingLeft={props.paddingLeft}
          loadingScope={props.loadingScope}
          sizeItems={props.sizeItems}
        />
      )}
    </Box>
  );
};
