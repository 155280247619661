import { forwardRef, useEffect, useMemo, useRef } from "react";
import { Flex, Button, Text, Input, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { CustomVariablesModel, FlowActionModel, SetVariableModel } from "../../../FlowBuilderModel";
import { createCustomVariable, selectCustomVariables } from "../../../FlowBuilderSlice";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { BooleanVariableValues, CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { customDateInput, DatePicker } from "../../../../../UI/atoms/datePicker/DatePicker";
import s from "./SystemActionNodePopup.module.scss";
import dayjs from "dayjs";

interface Props {
  flowAction: SetVariableModel;
  onDataChange: (flowAction: SetVariableModel) => void;
  validate: (flowAction: FlowActionModel) => void;
}

export const SetVariableEditor = ({ flowAction, onDataChange, validate }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const cv = useTranslation("translation", { keyPrefix: "flow.addVariablePopup" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const variables = useAppSelector(selectCustomVariables);
  const dispatch = useAppDispatch();
  const CustomInput = forwardRef(customDateInput);
  const valueInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    flowAction.sourceType === "value" && valueInput.current?.focus();
  }, [flowAction]);

  const changeSourceType = (sourceType: "variable" | "value") => () => {
    onDataChange({
      ...flowAction,
      sourceType,
    });
  };

  const setSource = (variableId?: string) => {
    onDataChange({
      ...flowAction,
      sourceCustomVariableId: variableId,
      targetCustomVariableId: undefined,
    });
  };

  const setSourceValue = (value: string) => {
    onDataChange({
      ...flowAction,
      value: value,
    });
  };

  const selectSource = (variable: CustomVariablesModel) => {
    if (variable.id) setSource(variable.id);
  };

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = flowAction.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const targetVariables = useMemo(() => {
    if (flowAction.sourceType === "variable" && flowAction.sourceCustomVariableId) {
      return variables?.filter(el => chosenSource?.type && chosenSource?.type === el.type && el.id !== chosenSource.id);
    } else {
      return variables;
    }
  }, [chosenSource?.id, chosenSource?.type, flowAction.sourceCustomVariableId, flowAction.sourceType, variables]);

  const setTarget = (variableId: string | undefined, resetValue: boolean) => {
    onDataChange({
      ...flowAction,
      targetCustomVariableId: variableId,
      value: resetValue ? undefined : flowAction.value,
    });
  };

  const chosenTarget = targetVariables?.find(el => {
    const targetCustomVariableId = flowAction.targetCustomVariableId;
    return el.id === targetCustomVariableId ? true : false;
  });

  const selectTarget = (variable: CustomVariablesModel) => {
    if (variable.id) setTarget(variable.id, !!chosenTarget && chosenTarget?.type !== variable.type);
  };

  const setPlaceholder = (variable: CustomVariableModel | undefined) => {
    if (variable?.type === CustomVariableType.Boolean) {
      return t("Enter true or false");
    }
    if (variable?.type === CustomVariableType.Array) {
      return t("Enter array values, separated by commas");
    }
    if (variable?.type === CustomVariableType.Number) {
      return cv("Enter digits");
    }
    return t("Enter text");
  };

  const getValueInput = () => {
    switch (chosenTarget?.type) {
      case CustomVariableType.DateTime: {
        return (
          <DatePicker
            wrapperClassName={s.datePicker}
            dateFormat="dd.MM.yyyy HH:mm"
            selected={flowAction.value ? new Date(flowAction.value) : null}
            onChange={scheduledDate => {
              setSourceValue(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ") : "");
            }}
            strictParsing
            placeholderText={`${ct("placeholderDateTime")}`}
            showTimeSelect
            customInput={<CustomInput mt="16px" />}
          />
        );
      }
      case CustomVariableType.Date: {
        return (
          <DatePicker
            wrapperClassName={s.datePicker}
            dateFormat="dd.MM.yyyy"
            selected={flowAction.value ? new Date(flowAction.value) : null}
            onChange={scheduledDate => {
              setSourceValue(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : "");
            }}
            placeholderText={`${ct("placeholderDate")}`}
            customInput={<CustomInput mt="16px" />}
            strictParsing
          />
        );
      }
      case CustomVariableType.Boolean: {
        return (
          <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
            <MenuButton
              h="36px"
              border="1px solid #DAE1FB"
              borderRadius="8px"
              color={!flowAction.value ? "var(--chakra-colors-gray-500)" : "black"}
              _hover={{ borderColor: "mainPurple" }}
              mt="16px"
              w="100%"
              onClick={e => e.stopPropagation()}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex w={"90%"} alignItems={"center"} ml={"16px"}>
                  {flowAction.value?.length ? ct(flowAction.value) : ct("Not Set")}
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList>
              {Object.keys(BooleanVariableValues).map((el, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={el}
                    onClick={() => {
                      setSourceValue(el === BooleanVariableValues["Not Set"] ? "" : el);
                    }}
                  >
                    {el === BooleanVariableValues["Not Set"] ? ct("Not Set") : el}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }
      case CustomVariableType.Array:
      case CustomVariableType.Number:
      case CustomVariableType.Text:
      default: {
        return (
          <Input
            mt="16px"
            value={flowAction.value ?? ""}
            type={chosenTarget?.type}
            placeholder={setPlaceholder(chosenTarget)}
            onChange={e => setSourceValue(e.target.value)}
            onBlur={() => validate(flowAction)}
            ref={valueInput}
          />
        );
      }
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };
  return (
    <div>
      <>
        <Text variant="large">{t("Source")}</Text>
        <Flex mt="16px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Button
            width="100%"
            borderRightRadius="0"
            variant={flowAction.sourceType === "variable" ? "dominoViolet" : "dominoOutlineViolet"}
            onClick={changeSourceType("variable")}
          >
            {t("Variable")}
          </Button>
          <Button
            ml="0"
            borderLeftRadius="0"
            width="100%"
            variant={flowAction.sourceType === "variable" ? "dominoOutlineViolet" : "dominoViolet"}
            onClick={changeSourceType("value")}
          >
            {t("Value")}
          </Button>
        </Flex>
        {flowAction.sourceType === "variable" ? (
          <CustomVariableContextMenu
            type={CustomVariableContextMenuType.Dropdown}
            selectCustomVariable={selectSource}
            addVariable={onCreateVariable({ sourceOrTarget: "sourceCustomVariableId" })}
            variables={variables ?? []}
            chosenVariable={chosenSource}
            mt="16px"
            clearVariable={() => setSource(undefined)}
            excludeVariableType={CustomVariableType.Order}
          />
        ) : (
          getValueInput()
        )}

        <Flex mt="20px" gap="4px">
          <Text variant="large">{t("Set to")}</Text>
        </Flex>
        <CustomVariableContextMenu
          type={CustomVariableContextMenuType.Dropdown}
          selectCustomVariable={selectTarget}
          addVariable={onCreateVariable({ sourceOrTarget: "targetCustomVariableId", variableType: chosenSource?.type })}
          variables={targetVariables ?? []}
          chosenVariable={chosenTarget}
          mt="16px"
          clearVariable={() => setTarget(undefined, true)}
          excludeVariableType={CustomVariableType.Order}
        />
      </>
    </div>
  );
};
