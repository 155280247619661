import { Box } from "@chakra-ui/react";
import { CustomVariableContextMenu } from "../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { EmojiMenu } from "../emojiMenu/EmojiMenu";
import { EmojiPropsModel, VariablesPropsModel } from "../../InputWrapperModel";
import { CSSProperties } from "react";
import { CustomVariableContextMenuType } from "../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { FormatAction } from "../../../../common/utils/escapeMarkdown";
import { FormattingMenu } from "../formattingMenu/FormattingMenu";
import s from "./TextAreaComponent.module.scss";

interface Props {
  children: React.ReactNode;
  charactersLeft: number;
  variablesProps?: VariablesPropsModel;
  emojiProps?: EmojiPropsModel;
  buttonBlockStyle?: CSSProperties;
  customVariablesMenuView?: CustomVariableContextMenuType;
  onFormattingClick?: (action: FormatAction) => void;
  customButton?: JSX.Element;
  hideToolsButtons?: boolean;
  isNoVariablesButton?: boolean;
  isDisabled?: boolean;
}

export const TextAreaComponent = (props: Props) => {
  return (
    <>
      {props.children}
      {!props.hideToolsButtons && (
        <Box className={s.textareaButtonBlock} style={props.buttonBlockStyle}>
          {props.customButton}
          <Box
            data-pw="characters-left"
            aria-disabled={props.isDisabled}
            className={props.charactersLeft >= 0 ? s.charactersLeft : s.characterLeft_red}
          >
            {props.charactersLeft}
          </Box>
          {props.onFormattingClick && (
            <FormattingMenu isDisabled={props.isDisabled} onFormattingClick={props.onFormattingClick} />
          )}
          {props.emojiProps && <EmojiMenu isDisabled={props.isDisabled} addEmoji={props.emojiProps.addEmoji} />}
          {props.variablesProps && !props.isNoVariablesButton && (
            <CustomVariableContextMenu
              disableBtn={props.isDisabled}
              type={props.customVariablesMenuView}
              selectCustomVariable={props.variablesProps?.addCustomVariableText}
              variables={props.variablesProps.variables}
            />
          )}
        </Box>
      )}
    </>
  );
};
