import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { isChangePage } from "../../common/utils/pagination";
import * as organisationApi from "./OrganisationApi";
import {
  NewTeamModel,
  OrganisationModel,
  OrganisationTeamItemModel,
  OrganisationTeamsModel,
  OrganisationUsersModel,
  PluginModel,
} from "./OrganisationModel";
import {
  organisationSlice,
  selectIsOrganizationTrial,
  selectOrganisationTeams,
  selectOrganisationUsers,
  selectTrialPlan,
} from "./OrganisationSlice";
import { getBots, selectBotCompleted, sidebarSlice } from "../sidebar/SidebarSlice";
import { tariffPlanSlice } from "../../common/tariffPlan/TariffPlanSlice";
import { getTokenFromStorage } from "../../common/auth/AuthRepo";
import { automationSlice } from "../automation/AutomationSlice";
import { OrganisationData } from "./OrganisationData";
import { getBillingPlan, getProfile } from "../../common/user/UserSlice";
import { authSlice } from "../../common/auth/AuthSlice";
import { AppSettings } from "../../common/AppSettings";
import { activateTrial, getTrialPlanInfo } from "./components/TrialPlan/TrialPlanApi";
import { TrialPlanData } from "./components/TrialPlan/TrialPlanData";
import { TrialPlanModel } from "./components/TrialPlan/TrialPlanModel";
import { mapTrialDataToModel } from "./components/TrialPlan/TrialPlanMapper";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { NotFoundRequestError } from "../../common/ErrorModel";
import { NotificationModel } from "../../common/notifications/NotificationModel";
import { clearContactInfoState } from "../contactInfo/ContactInfoSlice";
import { setLimitsPerBillingPlan } from "../automation/AutomationSaga";
import { refreshToken } from "../../common/auth/AuthSaga";
import { clearConversationState } from "../conversation/ConversationSlice";
import { setUserProperty } from "../../common/ga/GAEventTracker";
import { clearContactListState } from "../contactList/ContactListSlice";

declare const appSettings: AppSettings;

export function* organisationSaga() {
  yield takeLeading(organisationSlice.actions.getOrganisation, getOrganisation);
  yield takeLatest(organisationSlice.actions.createOrganisation, createOrganisation);
  yield takeLatest(organisationSlice.actions.getOrganisationUsers, getOrganisationUsers);
  yield takeLatest(organisationSlice.actions.getOrganisationTeams, getOrganisationTeams);
  yield takeLatest(organisationSlice.actions.generateInviteToken, generateInviteToken);
  yield takeLatest(organisationSlice.actions.updateOrganisation, updateOrganisation);
  yield takeLatest(organisationSlice.actions.deleteUserFromOrganisation, deleteUserFromOrganisation);
  yield takeLatest(organisationSlice.actions.createOrganisationTeam, createOrganisationTeam);
  yield takeLatest(organisationSlice.actions.editOrganisationTeam, editOrganisationTeam);
  yield takeLatest(organisationSlice.actions.deleteOrganisationTeam, deleteOrganisationTeam);
  yield takeLatest(organisationSlice.actions.deleteTeamOperator, deleteTeamOperator);
  yield takeLatest(organisationSlice.actions.addOperatorsToTeam, addOperatorsToTeam);
  yield takeLatest(organisationSlice.actions.checkIsOperatorInUse, checkIsOperatorInUse);
  yield takeLatest(organisationSlice.actions.checkIsTeamInUse, checkIsTeamInUse);
  yield takeLatest(organisationSlice.actions.setOrganization, setOrganization);
  yield takeLatest(organisationSlice.actions.getTrialPlan, getTrialPlan);
  yield takeLeading(organisationSlice.actions.activateTrialPlan, setTrialPlan);
}

function* getPlugin(name: string) {
  try {
    const result: PluginModel = yield call(organisationApi.getPlugin, name);
    return result;
  } catch (err) {
    console.error("Failed to load plugin", name);
    return undefined;
  }
}

export function* getOrganisation() {
  try {
    yield put(beginScope("getOrganisation"));
    const organisationData: OrganisationData = yield call(organisationApi.getOrganisation);

    const isTrialLoaded: boolean = yield select(selectIsOrganizationTrial);

    let plugins: PluginModel[] = [];

    if (organisationData.plugins) {
      plugins = yield all(organisationData.plugins.map(p => getPlugin(p)));
    }
    const organisationModel: OrganisationModel = {
      ...organisationData,
      plugins: plugins.filter(x => x),
    };

    yield put(organisationSlice.actions.getOrganisationCompleted(organisationModel));
    yield put(tariffPlanSlice.actions.clearPlan());
    yield put(tariffPlanSlice.actions.getPlan());
    yield put(automationSlice.actions.getFlowTemplatesSucceed(undefined));
    if (!isTrialLoaded) {
      yield put(organisationSlice.actions.getTrialPlan());
    } else {
      //
    }
    const token = getTokenFromStorage();
    const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";

    const lastOrg = JSON.parse(sessionStorage.getItem("usersOrganizationId") || "{}");

    if (token && !lastOrg[username]) {
      const organizationId = JSON.parse(atob(token.accessToken.split(".")[1])).Organization;
      lastOrg[username] = organizationId;
      const newOrg = JSON.stringify(lastOrg);
      sessionStorage.setItem("usersOrganizationId", newOrg);
      yield put(organisationSlice.actions.setOrganization(organizationId));
    }

    setUserProperty({ organizationId: lastOrg[username] });
    yield setLimitsPerBillingPlan(organisationModel);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getOrganisation"));
  }
}

export function* setOrganization(action: PayloadAction<string>) {
  try {
    yield put(clearContactInfoState());
    yield put(clearConversationState());
    yield put(clearContactListState());
    yield put(organisationSlice.actions.getOrganisation());
    yield put(organisationSlice.actions.getOrganisationUsers({ page: 1 }));
    yield put(organisationSlice.actions.setTrialPlanState(false));
    yield put(organisationSlice.actions.setIsInitialTrialLoading(false));
    const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
    yield put(selectBotCompleted(undefined));

    if (sessionOrgId) {
      const newOrganisationId: { [key: string]: string } = JSON.parse(sessionOrgId);
      const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
      newOrganisationId[username] = action.payload;
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      yield put(getProfile());
      yield put(getBillingPlan());
      yield put(getBots({ page: 1, filter: undefined }));
      const token = getTokenFromStorage();
      if (token) {
        yield put(
          authSlice.actions.refreshToken({
            token: token.accessToken,
            apiName: appSettings.apiBaseUrl,
            refresh: token.refresh,
            expiration: token.expiration,
          }),
        );
      }
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* createOrganisation(action: PayloadAction<OrganisationModel>) {
  try {
    yield put(begin());
    const organisationData: OrganisationModel = yield call(organisationApi.createOrganisation, action.payload);
    yield put(organisationSlice.actions.getOrganisationCompleted(organisationData));
    if (organisationData.id) {
      const token = getTokenFromStorage();
      if (token) {
        //FIX:check and replace with take
        yield call(refreshToken, {
          payload: {
            token: token.accessToken,
            apiName: appSettings.apiBaseUrl,
            refresh: token.refresh,
            expiration: token.expiration,
          },
          type: "auth/refreshToken",
        });

        yield put(organisationSlice.actions.setOrganization(organisationData.id));
        yield put(sidebarSlice.actions.getAllOrganisation());
      }
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* getOrganisationUsers(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    yield put(begin());
    const organisationUsersData: OrganisationUsersModel = yield call(
      organisationApi.getOrganisationUsers,
      action.payload.page,
      action.payload.filter,
    );
    yield put(organisationSlice.actions.getOrganisationUsersCompleted(organisationUsersData));
    yield put(organisationSlice.actions.getOrganisation());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* getOrganisationTeams(action: PayloadAction<{ page: number }>) {
  try {
    yield put(begin());
    const organisationTeamsData: OrganisationTeamsModel = yield call(organisationApi.getOrganisationTeams, action.payload.page);
    yield put(organisationSlice.actions.getOrganisationTeamsCompleted(organisationTeamsData));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* generateInviteToken() {
  try {
    yield put(beginScope("generateInviteToken"));
    const inviteToken: { link: string } = yield call(organisationApi.generateInviteToken);
    yield put(organisationSlice.actions.setInviteToken({ inviteToken: inviteToken.link }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("generateInviteToken"));
  }
}

export function* updateOrganisation(action: PayloadAction<OrganisationModel>) {
  try {
    yield put(beginScope("updateOrganisation"));
    yield call(organisationApi.updateOrganisation, action.payload);
    yield put(organisationSlice.actions.getOrganisationCompleted(action.payload));
    yield put(sidebarSlice.actions.getAllOrganisation());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("updateOrganisation"));
  }
}

export function* deleteUserFromOrganisation(action: PayloadAction<{ userId: string }>) {
  try {
    yield put(beginScope("deleteUserFromOrganisation"));
    const organisationUsers: OrganisationUsersModel = yield select(selectOrganisationUsers);
    let page = organisationUsers?.currentPage;
    if (organisationUsers && page && isChangePage(organisationUsers)) {
      page -= 1;
    }
    yield call(organisationApi.deleteUserFromOrganisation, action.payload.userId);
    yield put(organisationSlice.actions.getOrganisationUsers({ page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteUserFromOrganisation"));
  }
}

export function* createOrganisationTeam(action: PayloadAction<NewTeamModel>) {
  try {
    const organisationTeams: OrganisationUsersModel = yield select(selectOrganisationTeams);
    let page = organisationTeams?.currentPage;
    if (organisationTeams && page && isChangePage(organisationTeams)) {
      page -= 1;
    }
    const newOrganisationTeam: OrganisationTeamItemModel = yield call(organisationApi.createOrganisationTeam, action.payload);
    yield call(organisationApi.addOperatorsToTeam, action.payload.members, newOrganisationTeam.id);
    yield put(organisationSlice.actions.getOrganisation());
    yield put(organisationSlice.actions.getOrganisationTeams({ page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* editOrganisationTeam(action: PayloadAction<OrganisationTeamItemModel>) {
  try {
    const organisationTeams: OrganisationTeamsModel = yield select(selectOrganisationTeams);
    const changedOrganisationTeamItems = organisationTeams.items.map(el => {
      if (el.id === action.payload.id) {
        return { ...el, name: action.payload.name };
      }
      return el;
    });
    yield put(
      organisationSlice.actions.getOrganisationTeamsCompleted({
        ...organisationTeams,
        items: changedOrganisationTeamItems,
      }),
    );
    yield call(organisationApi.editOrganisationTeam, action.payload);
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* deleteOrganisationTeam(action: PayloadAction<{ teamId: string }>) {
  try {
    yield put(beginScope("deleteOrganiastionTeam"));
    const organisationTeams: OrganisationUsersModel = yield select(selectOrganisationTeams);
    let page = organisationTeams?.currentPage;
    if (organisationTeams && page && isChangePage(organisationTeams)) {
      page -= 1;
    }

    yield call(organisationApi.deleteOrganisationTeam, action.payload.teamId);
    yield put(organisationSlice.actions.getOrganisation());
    yield put(organisationSlice.actions.getOrganisationTeams({ page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteOrganiastionTeam"));
  }
}

export function* addOperatorsToTeam(action: PayloadAction<{ teamId: string; operatorIds: string[] }>) {
  try {
    const organisationTeams: OrganisationUsersModel = yield select(selectOrganisationTeams);
    let page = organisationTeams?.currentPage;
    if (organisationTeams && page && isChangePage(organisationTeams)) {
      page -= 1;
    }
    yield call(organisationApi.addOperatorsToTeam, action.payload.operatorIds, action.payload.teamId);
    yield put(organisationSlice.actions.getOrganisationTeams({ page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* deleteTeamOperator(action: PayloadAction<{ teamId: string; operatorIds: string[] }>) {
  try {
    yield put(beginScope("deleteTeamOperator"));
    const organisationTeams: OrganisationUsersModel = yield select(selectOrganisationTeams);
    let page = organisationTeams?.currentPage;
    if (organisationTeams && page && isChangePage(organisationTeams)) {
      page -= 1;
    }
    yield call(organisationApi.deleteTeamOperator, action.payload.operatorIds, action.payload.teamId);
    yield put(organisationSlice.actions.getOrganisationTeams({ page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteTeamOperator"));
  }
}

export function* checkIsOperatorInUse(action: PayloadAction<{ operatordId: string }>) {
  try {
    yield put(beginScope("checkVariableUsage"));
    const operatorUsage: { [key: string]: string } = yield call(
      organisationApi.getIsOrganisationUsersInUse,
      action.payload.operatordId,
    );
    const flowModel = mapKeyDataToFlowModel(operatorUsage);
    yield put(organisationSlice.actions.checkIsOperatorInUseSucceed(flowModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("checkVariableUsage"));
  }
}

export function* checkIsTeamInUse(action: PayloadAction<{ teamId: string }>) {
  try {
    yield put(beginScope("checkVariableUsage"));
    const teamUsage: {
      [key: string]: string;
    } = yield call(organisationApi.getIsOrganisationTeamsInUse, action.payload.teamId);
    const flowModel = mapKeyDataToFlowModel(teamUsage);
    yield put(organisationSlice.actions.checkIsTeamInUseSucceed(flowModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("checkVariableUsage"));
  }
}

export const mapKeyDataToFlowModel = (data: { [key: string]: string }) => {
  const useKeys = Object.keys(data);
  return useKeys.map(k => {
    return {
      id: k,
      name: data[k],
    };
  });
};

export function* getTrialPlan() {
  try {
    sessionStorage.setItem("isTrial", "true");
    const trialPlanData: TrialPlanData = yield call(getTrialPlanInfo);
    const trialPlanModel: TrialPlanModel = mapTrialDataToModel(trialPlanData);
    if (trialPlanModel.isAvailable) {
      yield put(organisationSlice.actions.setTrialPlanState(true));
    }
    yield put(organisationSlice.actions.getTrialPlanCompleted(trialPlanModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* setTrialPlan(action: PayloadAction<string>) {
  try {
    const trialPlan: TrialPlanModel = yield select(selectTrialPlan);
    yield call(activateTrial, action.payload);
    const notification: NotificationModel = {
      message: `You have activated a free trial period for {{days}} days`,
      messageVariables: { days: `${trialPlan.trialDays}`, count: trialPlan.trialDays },
      type: "success",
      duration: 5000,
    };
    yield put(organisationSlice.actions.setTrialPlanState(false));
    yield put(organisationSlice.actions.setIsInitialTrialLoading(false));
    yield put(notificationSlice.actions.notify(notification));
    yield put(getBillingPlan());
  } catch (e: unknown) {
    if (e instanceof NotFoundRequestError) {
    }
    handleException(e);
  }
}
