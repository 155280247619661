import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as ChatListApi from "./ChatListApi";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import { chatListSlice, selectChatList, selectFilter, selectMyTeams, selectSearchText, selectSort } from "./ChatListSlice";
import { ChatListData, OverLimitConversationsData, TeamsData } from "./ChatListData";
import { mapChatListDataToModel, mapConversationInfoDataToModel, mapConversationInfoModelToModelList } from "./ChatListMapper";
import {
  CHAT_LIST_FILTER_VARIANTS,
  ChatListFilterTypes,
  ChatListItemModel,
  ChatListSortEnum,
  OverLimitConversationsModel,
} from "./ChatListModel";
import { store } from "../..";
import { signalRConnection } from "../../middlewares/signalRMiddleware";
import { ConversationInfoData } from "../conversation/ConversationData";
import { selectCurrentBot, selectCurrentBotId } from "../sidebar/SidebarSlice";
import dayjs from "dayjs";
import { selectUserId } from "../../common/user/UserSlice";
import { conversationSlice } from "../conversation/ConversationSlice";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { BotModel } from "../sidebar/SidebarModel";
import { contactInfoSlice } from "../contactInfo/ContactInfoSlice";
import { ChatEvent } from "../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import GTM from "../../common/ga/GAEventTracker";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

const trackEvent = GTM(EventCategories.Chats);

export function* chatListSaga() {
  yield takeLatest(chatListSlice.actions.getChatList, getChatList);
  yield takeLatest(chatListSlice.actions.getChatListCount, getChatListCount);
  yield takeLatest(chatListSlice.actions.getMyTeams, getMyTeams);
  yield takeLatest(chatListSlice.actions.setFilter, setFilter);
  yield takeEvery(chatListSlice.actions.addNewConversation, addConversation);
  yield takeEvery(chatListSlice.actions.updateChatInfo, chatListReorder);
  yield takeEvery(chatListSlice.actions.addChat, chatListReorder);
  yield takeLatest(chatListSlice.actions.getOverLimitConversations, getOverLimitConversations);
  signalRConnection.addHandler("receiveConversation", (data: ConversationInfoData) => {
    let overLimitConversations = store.getState().app.chatListState.overLimitedConversations;
    const currentBotId = store.getState().app.sidebarState.bot?.id;
    if (data.isOverLimited && data.botId === currentBotId) {
      overLimitConversations = [
        ...(overLimitConversations?.filter(x => x.conversationId !== data.id) ?? []),
        {
          conversationId: data.id,
        },
      ];
      store.dispatch(chatListSlice.actions.getOverLimitConversationsCompleted(overLimitConversations));
      return;
    }
    const itemModel = mapConversationInfoModelToModelList(data);
    const conversationInfo = mapConversationInfoDataToModel(data);

    store.dispatch(chatListSlice.actions.updateChatInfo(itemModel));
    store.dispatch(chatListSlice.actions.addNewConversation(itemModel));
    const conversationInfoNow = store.getState().app.conversationState.conversationInfo;
    if (conversationInfoNow?.id === conversationInfo.id) {
      store.dispatch(conversationSlice.actions.getConversationInfoSucceed(conversationInfo));
      if (data.lastMessage.isPhoto) {
        if (
          data.action !== "Assignment" &&
          data.action !== "Closed" &&
          data.status !== "Closed" &&
          data.lastMessage.status === "NotDefined"
        ) {
          store.dispatch(contactInfoSlice.actions.incMediaFilesCompleted());
        }
      }
      if (data.lastMessage.isDocument) {
        store.dispatch(contactInfoSlice.actions.incDocFilesCompleted());
      }
    }
  });
}

function* getChatList(
  action: PayloadAction<{
    botId: string;
    search: string;
    sort: ChatListSortEnum;
    filter: { type: ChatListFilterTypes; teamId?: string };
    lastId: string;
  }>,
) {
  try {
    yield put(beginScope("getChatList"));
    const { search, sort, filter, lastId } = action.payload;
    const filterFromStorage = getFilterFromStorage();
    const prevChatListItems = lastId === "1" ? [] : store.getState().app.chatListState.chatList?.items;
    const data: ChatListData = yield call(
      ChatListApi.getConversationList,
      action.payload.botId,
      getQueryStringFromParams({ search, sort, filter: filterFromStorage ?? filter, lastId }),
    );

    const chatModel = mapChatListDataToModel(data, 0);
    yield put(chatListSlice.actions.updateConversationsUploading(true));
    if (!data.items.length && lastId !== "1") {
      yield put(chatListSlice.actions.updateConversationsUploading(false));
      return;
    }

    const newConcatedItems = prevChatListItems?.concat(chatModel.items);
    const dictionaryCounts = {} as Record<string, number>;
    newConcatedItems?.forEach(item => {
      if (dictionaryCounts?.[item.id as string] !== undefined) {
        dictionaryCounts[item.id as string] += 1;
      } else {
        dictionaryCounts[item.id] = 1;
      }
    });
    const concatedItems = lastId ? newConcatedItems?.filter(item => dictionaryCounts[item.id] === 1) : chatModel.items;
    const concatedChatModel = {
      ...chatModel,
      items: concatedItems ?? chatModel.items,
    };
    yield put(chatListSlice.actions.getChatListSucceed({ chatList: concatedChatModel }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getChatList"));
  }
}

function* getChatListCount(
  action: PayloadAction<{
    botId: string;
    search: string;
    sort: ChatListSortEnum;
    filter: { type: ChatListFilterTypes; teamId?: string };
    lastId: string;
  }>,
) {
  try {
    yield put(beginScope("getChatListCount"));
    const { search, sort, filter, lastId } = action.payload;
    const filterFromStorage = getFilterFromStorage();
    const { count }: { count: number } = yield call(
      ChatListApi.getConversationCount,
      action.payload.botId,
      getQueryStringFromParamsCount({ search, sort, filter: filterFromStorage ?? filter, lastId }),
    );
    yield put(chatListSlice.actions.getChatListCountSucceed({ totalItems: count }));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getMyTeams(action: PayloadAction<{ page: number }>) {
  try {
    const data: TeamsData = yield call(ChatListApi.getMyTeams, action.payload.page);

    yield put(chatListSlice.actions.getMyTeemsSucceed(data));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getChatListCount"));
  }
}

function* addConversation(action: PayloadAction<ChatListItemModel>) {
  try {
    const chatList: ReturnType<typeof selectChatList> = yield select(selectChatList);
    const myTeams: ReturnType<typeof selectMyTeams> = yield select(selectMyTeams);
    const userId: string = yield select(selectUserId);
    const currentBotId: ReturnType<typeof selectCurrentBotId> = yield select(selectCurrentBotId);
    const filter: ReturnType<typeof selectFilter> = yield select(selectFilter);
    const searchText: ReturnType<typeof selectSearchText> = yield select(selectSearchText);
    const contact = action.payload.contact;
    const searchTextLowerCase = searchText.toLowerCase();
    const isConversationSuitSearch =
      contact?.firstName?.toLocaleLowerCase().includes(searchTextLowerCase) ||
      contact?.lastName?.toLocaleLowerCase().includes(searchTextLowerCase) ||
      contact?.username?.toLocaleLowerCase().includes(searchTextLowerCase) ||
      action.payload.name.toLocaleLowerCase().includes(searchTextLowerCase);

    let matchFilter = false;
    const isIncludedItem = chatList?.items.some((item: { id: string }) => item?.id === action.payload?.id);
    const isAssagnedToMyTeam = myTeams?.items?.some(x => x.id === action.payload.assignedTeam?.id);
    const isNewConversation = action.payload.isNew;
    if (filter.type === "all") {
      matchFilter = true;
    } else if (filter.type === "open") {
      matchFilter = action.payload.status === "Open";
    } else if (filter.type === "assignedToMyTeams") {
      matchFilter = isAssagnedToMyTeam === true;
    } else if (filter.type === "assignedToMe") {
      matchFilter =
        action.payload.assignedOperator?.id === userId ||
        (Boolean(isIncludedItem) && action.payload.assignedOperator?.id === userId);
    } else if (filter.type === "assignedToOneOfMyTeams") {
      matchFilter = action.payload.assignedTeam?.id === filter.teamId;
    } else if (filter.type === "closed") {
      matchFilter = action.payload.status === "Closed";
    }
    if (!matchFilter && isIncludedItem) {
      yield put(chatListSlice.actions.removeChat(action.payload));
      return;
    } else if (
      isNewConversation &&
      matchFilter &&
      isConversationSuitSearch &&
      !isIncludedItem &&
      currentBotId === action.payload.botId
    ) {
      yield put(chatListSlice.actions.addChat(action.payload));
    } else if (
      matchFilter &&
      currentBotId === action.payload.botId &&
      isConversationSuitSearch &&
      !isIncludedItem &&
      (action.payload.assignedOperator?.id === userId ||
        isAssagnedToMyTeam ||
        action.payload.status === "Closed" ||
        action.payload.status === "Open")
    ) {
      yield put(chatListSlice.actions.addChat(action.payload));
      trackEvent(ChatEvent.ChatNewConversation);
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* chatListReorder() {
  try {
    const sort: ReturnType<typeof selectSort> = yield select(selectSort);
    const chatList: ReturnType<typeof selectChatList> = yield select(selectChatList);
    if (chatList) {
      const updatedChatListItems = [...chatList.items];
      if (sort === ChatListSortEnum.oldest) {
        // sortByOldest
        updatedChatListItems.sort((item1, item2) => {
          const compareDate1 = item1.lastMessage?.date == null ? item1?.createDate : item1.lastMessage?.date;
          const compareDate2 = item2.lastMessage?.date == null ? item2?.createDate : item2.lastMessage?.date;
          const sortItem1BeforeItem2 = dayjs(compareDate1).isBefore(dayjs(compareDate2));
          const sortItem1AfterItem2 = dayjs(compareDate1).isAfter(dayjs(compareDate2));
          return sortItem1BeforeItem2 ? -1 : sortItem1AfterItem2 ? 1 : 0;
        });
      } else {
        // sortByNewest
        updatedChatListItems.sort((item1, item2) => {
          const compareDate1 = item1.lastMessage?.date == null ? item1?.createDate : item1.lastMessage?.date;
          const compareDate2 = item2.lastMessage?.date == null ? item2?.createDate : item2.lastMessage?.date;
          const sortItem2BeforeItem1 = dayjs(compareDate2).isBefore(dayjs(compareDate1));
          const sortItem2AfterItem1 = dayjs(compareDate2).isAfter(dayjs(compareDate1));
          return sortItem2BeforeItem1 ? -1 : sortItem2AfterItem1 ? 1 : 0;
        });
      }
      yield put(
        chatListSlice.actions.setChatList({
          ...chatList,
          items: updatedChatListItems,
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* setFilter(action: PayloadAction<{ type: ChatListFilterTypes; teamId?: string; teamName?: string }>) {
  try {
    saveFilterToStorage(action.payload);
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function getQueryStringFromParams(params: {
  search: string;
  sort: ChatListSortEnum;
  filter: { type: ChatListFilterTypes; teamId?: string };
  lastId: string;
}) {
  const filterParams = CHAT_LIST_FILTER_VARIANTS[params.filter.type];
  const filterQueryString =
    filterParams?.field !== ""
      ? `filters[0].field=${filterParams.field}&filters[0].condition=${filterParams.condition}${
          filterParams.value.length ? `&filters[0].value=${params.filter.teamId ?? filterParams.value}` : ""
        }`
      : "";
  const searchQueryString = `filter=${params.search}&filterBy=Name`;
  return `?${filterQueryString}&orderBy=LastMessageDate&orderDirection=${params.sort}&${searchQueryString}${
    params.lastId ? "&lastId=" + params.lastId : "1"
  }&size=14`;
}

function getQueryStringFromParamsCount(params: {
  search: string;
  sort: ChatListSortEnum;
  filter: { type: ChatListFilterTypes; teamId?: string };
  lastId: string;
}) {
  const filterParams = CHAT_LIST_FILTER_VARIANTS[params.filter.type];
  const filterQueryString =
    filterParams?.field !== ""
      ? `filters[0].field=${filterParams.field}&filters[0].condition=${filterParams.condition}${
          filterParams.value.length ? `&filters[0].value=${params.filter.teamId ?? filterParams.value}` : ""
        }`
      : "";
  const searchQueryString = `filter=${params.search}&filterBy=Name`;
  return `?${filterQueryString}&orderBy=LastMessageDate&orderDirection=${params.sort}&${searchQueryString}${
    params.lastId ? "&lastId=" + params.lastId : "1"
  }&size=14`;
}

export const getFilterFromStorage = () => {
  const filterSessionStorageJson = sessionStorage.getItem("ChatListFilter");
  const filterLocalStorageJson = localStorage.getItem("ChatListFilter");
  if (filterSessionStorageJson) {
    return JSON.parse(filterSessionStorageJson);
  } else if (filterLocalStorageJson) {
    const filter = JSON.parse(filterLocalStorageJson);
    sessionStorage.setItem("ChatListFilter", JSON.stringify(filter));
    return filter;
  }
  return undefined;
};

const saveFilterToStorage = (filter: { type: ChatListFilterTypes; teamId?: string; teamName?: string }) => {
  sessionStorage.setItem("ChatListFilter", JSON.stringify(filter));
  localStorage.setItem("ChatListFilter", JSON.stringify(filter));
};

function* getOverLimitConversations() {
  try {
    const bot: BotModel = yield select(selectCurrentBot);
    const data: OverLimitConversationsData = yield call(ChatListApi.getOverLimitConversations, bot.id);

    const model: OverLimitConversationsModel[] = data.conversationIds.map(x => {
      return {
        conversationId: x,
      } as OverLimitConversationsModel;
    });

    yield put(chatListSlice.actions.getOverLimitConversationsCompleted(model));
  } catch (e: unknown) {
    handleException(e);
  }
}
