import { useCallback, useEffect, DragEvent, useState, useRef, ClipboardEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  Box,
  Flex,
  Text,
  Icon,
  useMediaQuery,
  Skeleton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";
import { selectBot, selectCurrentBot } from "../sidebar/SidebarSlice";
import { MessagesList } from "./components/MesssagesList";
import {
  clearMessages,
  getConversationInfo,
  getConversationMessages,
  getConversationMessagesSucceed,
  getCustomVariables,
  selectConversationInfo,
  selectConversationMessage,
  selectConversationMessages,
  selectConversationMessageValidation,
  selectConversationVariables,
  sendConversationMessage,
  setConversationMessage,
  uploadMessageFile,
  selectTaggedOperators,
  getTaggedOperators,
  unblockConversation,
  selectScrollToMessageId,
} from "./ConversationSlice";
import { useParams, useNavigate } from "react-router-dom";
import s from "./Conversation.module.scss";
import { MessageBox } from "./components/MessageBox/MessageBox";
import { ConversationSendMessageModel, FileUploadType } from "./ConversationModel";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { OutgoingTypeDiscriminator } from "./ConversationData";
import { IMAGE_FILE_EXTS } from "../../common/AppEnums";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackArrowMenu } from "../../assets/images/downArrow.svg";
import { ReactComponent as EmptyChatIcon } from "../../assets/icons/emptyChat.svg";
import DotMenuContainer from "../../UI/atoms/DotMenu/DotMenuContainer";
import { ConversationViewerContainer } from "../conversationViewer/ConversationViewerContainer";

export interface Props {
  onContactInfoOpen?: () => void;
}

export const ConversationContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const id = params["conversationId"];

  const currentBot = useAppSelector(selectCurrentBot);
  const messageList = useAppSelector(selectConversationMessages);
  const conversationInfo = useAppSelector(selectConversationInfo);
  const variables = useAppSelector(selectConversationVariables);
  const message = useAppSelector(selectConversationMessage);
  const messageValidation = useAppSelector(selectConversationMessageValidation);
  const scrollToMessageId = useAppSelector(selectScrollToMessageId);
  const userNames = useAppSelector(selectTaggedOperators);

  const [isDragging, setDragging] = useState(false);
  const [isTablet] = useMediaQuery("(min-width: 768px) and (max-width: 1269px)");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNoteActive, setIsNoteActive] = useState(false);
  const [replyMode, setReplyMode] = useState<{
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }>({ enabled: false });

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const myRef = useRef<HTMLDivElement>(null);
  const getMessages = useCallback(
    (lastId: string, searchValue?: string, currentSearchMessagesLength?: number, includeMessageId?: string) => {
      if (id) {
        dispatch(
          getConversationMessages({
            conversationId: id,
            lastId,
            searchValue,
            currentSearchMessagesLength,
            includeMessageId,
          }),
        );
      }
    },
    [dispatch, id],
  );

  const clearSearchParams = () => {
    if (messageList) {
      dispatch(getConversationMessagesSucceed({ ...messageList, isNextSearch: true, totalSearchItems: undefined }));
    }
  };

  useEffect(() => {
    if (currentBot && id) {
      dispatch(clearMessages());
      dispatch(
        setConversationMessage({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.OutgoingTextMessage,
        }),
      );
      dispatch(getConversationInfo({ conversationId: id }));
      getMessages("");
      dispatch(getCustomVariables({ botId: currentBot.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, dispatch, getMessages, id]);

  useEffect(() => {
    if (conversationInfo && currentBot && currentBot.id !== conversationInfo?.botId) {
      dispatch(selectBot(conversationInfo.botId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationInfo, currentBot]);

  const sendMessage = (message: ConversationSendMessageModel) => {
    if (!id) {
      return;
    }
    dispatch(sendConversationMessage({ conversationId: id, message }));
  };

  const setMessage = (message: ConversationSendMessageModel, cursorPosition?: number) => {
    if ((!message.text && isOpen) || (!message.text.includes("@") && isOpen)) {
      onClose();
    }
    const isUserTryTagged =
      (message.text.includes("@") && cursorPosition && isNoteActive) || (message.text.startsWith("@") && cursorPosition === 0);
    if (isUserTryTagged) {
      const currentText = message.text
        .substring(0, cursorPosition + 1)
        .split(" ")
        .reverse()[0];
      let isTagged = currentText.startsWith("@");
      if (!isTagged) {
        isTagged = currentText.includes("\n@");
      }
      const name = currentText.substring(currentText.indexOf("@"), currentText.length);

      if (isTagged && name.length >= 1) {
        switch (name.length) {
          case 1:
            dispatch(getTaggedOperators({ name: "" }));
            break;
          default:
            dispatch(getTaggedOperators({ name: name.slice(1) }));
            break;
        }
      } else {
        onClose();
      }
    }
    dispatch(setConversationMessage(message));
  };

  useEffect(() => {
    if (isNoteActive) {
      return getUserName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNames]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("message_textarea")?.focus();
    }, 100);
  }, [isOpen]);

  useEffect(() => {
    setReplyMode({ enabled: false });
  }, [id]);

  function getUserName() {
    if (message.text[cursorPosition - 1] === " " || !cursorPosition) {
      onClose();
    } else {
      onOpen();
    }
  }

  function getTagPanelWidth() {
    const messageBoxSize = myRef.current?.clientWidth;
    if (messageBoxSize) {
      return messageBoxSize - 50;
    }
  }

  function onNameClick(name: string) {
    const editedMessage = generateNewMessage(name);
    if (editedMessage) {
      const completeMessage: ConversationSendMessageModel = { ...message, text: editedMessage[0] };
      setMessage(completeMessage);
      onClose();
      setCursorPosition(editedMessage[1]);
    }
  }

  function generateNewMessage(name: string): [string, number] {
    const firstPartOfText = message.text.substring(0, cursorPosition);
    const symbolsFirstPart = firstPartOfText.split("").reverse().join("");
    const lastIndex = symbolsFirstPart.indexOf("@");
    const cleanFirstMessage =
      symbolsFirstPart
        .substring(lastIndex + 1, symbolsFirstPart.length)
        .split("")
        .reverse()
        .join("") +
      "@" +
      name;
    const secondPartOfText = message.text.substring(cursorPosition, message.text.length);
    if (secondPartOfText.substring(0, 1) !== " ") {
      return [cleanFirstMessage + " " + secondPartOfText + " ", firstPartOfText.length];
    }
    return [cleanFirstMessage + secondPartOfText + " ", firstPartOfText.length];
  }

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadMessageFile({ file, type }));
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [type, ext] = file.type.split("/");
      if (type === "image" && IMAGE_FILE_EXTS.includes(ext)) {
        uploadFile(file, FileUploadType.photo);
      } else {
        uploadFile(file, FileUploadType.document);
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    if (isNoteActive) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (!message.document && !message.photo) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  const handlePasteFromClipBoard = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const { clipboardData } = e;
    if (clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData?.files[0];

      if (file && !message.document && !message.photo) {
        e.preventDefault();
        if (file.type.match("^image/")) {
          uploadFile(file, FileUploadType.photo);
        } else {
          uploadFile(file, FileUploadType.document);
        }
      }
    }
  };

  const onUnblockConversation = () => {
    if (!conversationInfo) return;
    dispatch(unblockConversation({ conversationId: conversationInfo.id }));
  };

  if (!conversationInfo || !id) {
    return (
      <Skeleton
        speed={0.5}
        startColor="line"
        endColor="bgLight"
        borderRadius="4px"
        height="100%"
        width="100%"
        border="1px solid"
        borderColor="line"
      />
    );
  }

  return (
    <>
      <Loading scope="messageList" />

      <Box onDragEnter={handleDrag} className={`${s.conversationContainer} ${isDragging ? s.dragging : ""} `}>
        {(isMobile || isTablet) && (
          <Flex className={s.topContactInfo}>
            {isMobile && (
              <Flex
                alignItems={"cener"}
                ml={"-16px"}
                onClick={() =>
                  navigate(`/chats`, {
                    replace: true,
                  })
                }
              >
                <Icon as={BackArrowMenu} boxSize="42px" color="midDeepBlue" transform={"rotate(90deg)"} borderRadius={"4px"} />
              </Flex>
            )}
            <Box
              fontWeight={"bold"}
              flexGrow={"1"}
              maxW={"100%"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              <Text className={s.text}>{conversationInfo.name}</Text>
            </Box>
            {conversationInfo?.contact?.id && (
              <DotMenuContainer
                menuItems={[
                  {
                    MenuItem: {
                      icon: <></>,
                      text: t("Contact info"),
                      clickEvent: () => {
                        props.onContactInfoOpen?.();
                      },
                    },
                  },
                ]}
                MenuHover={{ bg: "line" }}
              />
            )}
          </Flex>
        )}
        <Flex flexDir={"column"} className={s.messageListContainer}>
          {messageList?.items?.length === 0 && (
            <Flex className={s.nothingYet}>
              <EmptyChatIcon />
              <Text variant="medium" color="darkGrey">
                {t("There's nothing here yet")}
              </Text>
            </Flex>
          )}
          {messageList?.items && (
            <MessagesList
              onReply={reply => setReplyMode(reply)}
              conversationInfo={conversationInfo}
              botName={currentBot?.username || "Unnamed Bot"}
              messageList={messageList}
              getMessages={getMessages}
              clearSearchParams={clearSearchParams}
              scrollToMessageId={scrollToMessageId}
            />
          )}
        </Flex>

        <Popover onClose={onClose} isOpen={isOpen} placement="top-start" offset={[25, -10]}>
          <PopoverTrigger>
            <Box className={`${s.sendMessageContainer} loaderContainer`} ref={myRef}>
              <ConversationViewerContainer id={id} getMessages={getMessages} />
              <MessageBox
                replyMode={replyMode}
                onCloseReply={() => setReplyMode({ enabled: false })}
                isNoteActive={isNoteActive}
                isBlocked={conversationInfo.isBlocked}
                onUnblockClick={onUnblockConversation}
                setIsNoteActive={setIsNoteActive}
                message={message}
                variables={variables}
                validation={messageValidation}
                onUploadFile={uploadFile}
                onSetMessage={setMessage}
                onSendMessage={sendMessage}
                cursorPosition={cursorPosition}
                setCursorPosition={setCursorPosition}
                onPasteFileFromClipboard={handlePasteFromClipBoard}
              ></MessageBox>
            </Box>
          </PopoverTrigger>
          {isNoteActive && (
            <PopoverContent className={s.assignedUsersPopover} width={getTagPanelWidth()}>
              {userNames && userNames.length > 0 ? (
                userNames.map((item, index) => (
                  <div className={s.popoverItem} key={index} onClick={() => onNameClick(item.username)}>
                    {item.username}
                  </div>
                ))
              ) : (
                <div className={s.popoverItem}>{t("No operators")}</div>
              )}
            </PopoverContent>
          )}
        </Popover>
        {isDragging && !message.document && !message.photo && !conversationInfo.isBlocked && (
          <div
            className={s.dragFileElement}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={onDropFile}
          ></div>
        )}
        {isDragging && !message.document && !message.photo && !conversationInfo.isBlocked && (
          <div className={s.dragFileContainer}>
            <div className={s.dragFileContent}>
              <Text maxW="195px" variant="largeBold" color="white">
                {t("Drag and drop the file here to attach to the message")}
              </Text>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};
