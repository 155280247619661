import { call, put, takeLatest } from "redux-saga/effects";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { postBot, setBotWebHook } from "./AddBotApi";
import { BotInfoData } from "./AddBotData";
import { addBotSlice } from "./AddBotSlice";
import { AppSettings } from "../../common/AppSettings";
import { BotIndexTabNames, CreatedBotModel } from "./AddBotModel";
import { BotAddEvents } from "../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { routerSlice } from "../../common/router/RouterSlice";
import GTM from "../../common/ga/GAEventTracker";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

declare const appSettings: AppSettings;
const trackEvent = GTM(EventCategories.Bot);

export function* addBotSaga() {
  yield takeLatest(addBotSlice.actions.connectBot, connectBot);
}

function* connectBot(action: { payload: { token: string; tab: number } }) {
  try {
    yield put(beginScope("connectBot"));
    const botData: BotInfoData = yield call(postBot, action.payload.token);
    const webHookUrl = `${appSettings.apiBaseUrl}/api/telegramBot/webhook`;
    const botModel: CreatedBotModel = { ...botData };
    yield call(setBotWebHook, botData.id, webHookUrl);
    yield put(sidebarSlice.actions.selectBot(botModel));
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );

    const tabForGA = action.payload.tab;
    if (tabForGA === BotIndexTabNames.CREATE) {
      trackEvent(BotAddEvents.BotCreateComleted);
    } else if (tabForGA === BotIndexTabNames.CONNECT) {
      trackEvent(BotAddEvents.BotConneсtComleted);
    }

    yield put(addBotSlice.actions.connectBotCompleted());
    yield put(routerSlice.actions.redirect("/automation/flows"));
  } catch (e) {
    if (e instanceof Error && !action.payload) {
      yield put(
        notificationSlice.actions.notify({
          message: "Please enter the bot token",
          type: "error",
        }),
      );
      return;
    }
    if (e instanceof Error) {
      yield put(
        notificationSlice.actions.notify({
          message: e.message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  } finally {
    yield put(completeScope("connectBot"));
  }
}
