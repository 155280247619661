import { CloseIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, AlertTitle, Box, CreateToastFnReturn } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AppSettings } from "../../../common/AppSettings";

declare const appSettings: AppSettings;

interface Props {
  idToast: string;
  toast: CreateToastFnReturn;
  message: string;
}

export default function PaymentError({ idToast, toast, message }: Props) {
  const [id, setId] = useState("");

  useEffect(() => {
    if (!id) {
      setId(idToast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    window.open(appSettings.billingPortalUrl, "_blank");
    toast.close(idToast);
  };

  return (
    <Alert
      className={`${id}`}
      cursor={"pointer"}
      onClick={onClick}
      borderRadius="0.375rem"
      position="relative"
      pr="32px"
      bgColor="#C53030"
      status="error"
    >
      <AlertIcon color="white" />
      <Box>
        <AlertTitle color="white">{message}</AlertTitle>
      </Box>

      <Box
        px="10px"
        cursor="pointer"
        onClick={e => {
          e.stopPropagation();
          toast.close(id);
        }}
        position="absolute"
        right="2px"
        top="2px"
      >
        <CloseIcon color="white" alignSelf="flex-start" boxSize="10px" />
      </Box>
    </Alert>
  );
}
