/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../common/state/store";
import { GroupChatModel } from "../../../../GroupChatModel";

export interface GroupChatInfoState {
  isOpen: boolean;
  groupChatInfo?: GroupChatModel;
}

const initialState: GroupChatInfoState = {
  isOpen: false,
};

export const groupChatInfoSlice = createSlice({
  name: "groupChatInfoSlice",
  initialState,
  reducers: {
    getGroupChatInfo: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getGroupChatInfoCompleted: (state, action: PayloadAction<GroupChatModel>) => {
      return {
        ...state,
        groupChatInfo: action.payload,
      };
    },
    setIsOpenGroupChatInfo: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpen: action.payload,
      };
    },
  },
});

export const { getGroupChatInfo, setIsOpenGroupChatInfo } = groupChatInfoSlice.actions;

export const selectIsOpenGroupChatInfo = (state: RootState) => state.app.groupChatInfoSlice.isOpen;
export const selectGroupChatInfo = (state: RootState) => state.app.groupChatInfoSlice.groupChatInfo;

export default groupChatInfoSlice.reducer;
