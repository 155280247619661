import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copyIconMenu.svg";
import { FlowItemModel } from "../../../AutomationModel";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/editIcon.svg";
import { ReactComponent as RedTrashIcon } from "../../../../../assets/icons/trash.svg";
import { ReactComponent as ExportIcon } from "../../../../../assets/icons/export.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import { useGAWithCustomParameters } from "../../../../../common/ga/GAEventTracker";
import { getExportFlow } from "../../../AutomationSlice";
import { useAppDispatch } from "../../../../../common/state/store";
import React from "react";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FlowEvents } from "../../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

interface Props {
  IsExportAvailable: boolean;
  canAddFlow: boolean;
  flowItem: FlowItemModel;
  onCopy: (flow: FlowItemModel) => void;
  onDelete: (flow: FlowItemModel) => void;
}

export const FlowListItemMenu = React.memo(function FlowListItemMenu(props: Props) {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onEditFlowItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    navigate(`/automation/flows/${props.flowItem.id}`);
  };
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);
  function exportFlow() {
    dispatch(getExportFlow({ flowId: props.flowItem.id, title: props.flowItem.title }));
  }
  return (
    <DotMenuContainer
      menuItems={[
        {
          isActive: true,
          MenuItem: {
            icon: <EditIcon width="18px" height="18px" />,
            clickEvent: e => {
              trackEvent(FlowEvents.FlowСhange);
              onEditFlowItem(e);
            },
            text: `${ct("Edit")}`,
          },
        },
        {
          isActive: props.canAddFlow,
          MenuItem: {
            icon: <CopyIcon fill="#3E5E95" width="18px" height="18px" />,
            clickEvent: e => {
              trackEvent(FlowEvents.FlowCopy);
              props.onCopy(props.flowItem);
              e.stopPropagation();
            },
            text: `${ct("Copy")}`,
          },
        },
        {
          isActive: props.IsExportAvailable,
          MenuItem: {
            icon: <ExportIcon width="18px" height="18px" />,
            clickEvent: e => {
              trackEvent(FlowEvents.FlowExport);
              exportFlow();
              e.stopPropagation();
            },
            text: `${ct("Export")}`,
          },
        },
        {
          isActive: true,
          MenuItem: {
            color: "mainRed",
            icon: <RedTrashIcon width="18px" height="18px" />,
            clickEvent: e => {
              trackEvent(FlowEvents.FlowDeleted);
              props.onDelete(props.flowItem);
              e.stopPropagation();
            },
            text: `${ct("Delete")}`,
          },
        },
      ]}
    />
  );
});
