import { Avatar, Box, Flex, Icon, Tooltip, useBoolean, useDisclosure } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { AppSettings } from "../../../../common/AppSettings";
import { ReactComponent as BotAvatar } from "../../../../assets/icons/sidebar/botIcon.svg";
import { ReactComponent as ReplyIcon } from "../../../../assets/icons/replyIcon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mailIcon.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/icons/PaymentNodeIcon.svg";
import { AvatarModel, ConversationMessageModel } from "../../ConversationModel";
import { PhotoMessageItem } from "./PhotoMessageItem";
import { DocumentMessageItem } from "./DocumentMessageItem";
import { TextMessageItem } from "./TextMessageItem";
import { ButtonMessageItem } from "./ButtonMessageItem";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { useTranslation } from "react-i18next";
import { MessageOriginType } from "../../../../common/AppEnums";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import { useNavigate } from "react-router-dom";
import { BotModel } from "../../../sidebar/SidebarModel";
import { ContactMessageItem } from "./ContactMessageItem";
import PaymentMessageItem from "./PaymentMessageItem";
import { UnsupportedMessageItem } from "./UnsupportedMessageItem";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
  highlightedText?: string;
  isInViewport?: boolean;
  isHighlighted?: boolean;
  userName?: string;
  avatars: AvatarModel[][];
  onReply: (reply: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }) => void;
  onReplyClick: (targetMessageId: string) => void;
  isReply?: boolean;
}

export const MessageItem = ({ ...props }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const [messageHover, setMessageHover] = useBoolean(false);
  const botInfo = useAppSelector(selectCurrentBot);

  let displayImageIndex = 1;
  const message = props.message;

  const messagePhotoLength = props.message.photos.length;
  if (messagePhotoLength <= displayImageIndex) {
    displayImageIndex = messagePhotoLength - 1;
  }
  if (props.message.contact && props.message.replyToMessage) {
    const elem = document.getElementById(`contact-${props.message.id}`);
    const elemReply = document.getElementById(`reply-${props.message.id}`);
    if (elemReply && elem) {
      elemReply.style.width = `${elem.offsetWidth}px`;
    }
  }

  const isSupported =
    message.replyToMessage ||
    message.text ||
    message.contact ||
    message.documents.length > 0 ||
    message.photos.length > 0 ||
    message.payment ||
    message.replyMarkup;

  return (
    <Box
      className={`${s.messageItemWrapper} ${s[props.message.direction]} ${props.message.replyToMessage ? s["WithReply"] : ""}`}
      onMouseLeave={setMessageHover.off}
    >
      {props.isInViewport && <Box className={s.backgroundHiglighted}></Box>}
      <Box className={`${s.messageHiglighted} ${props.isHighlighted ? s.messageHiglightedShow : s.messageHiglightedHide}`}></Box>
      {props.userName === "bot" && botInfo ? (
        <MessageAvatar origin={props.message.origin} botInfo={botInfo} />
      ) : props.message.payment ? (
        <Tooltip label={props.userName} variant="dominoLight">
          <Box className={s.payment}>
            <Icon as={PaymentIcon} boxSize="18px" viewBox="0 0 18 18" />
          </Box>
        </Tooltip>
      ) : props.avatars.length ? (
        <Tooltip label={props.userName} variant="dominoLight">
          <Avatar
            name={props.userName}
            className={s.avatar}
            src={`${appSettings.apiBaseUrl}/file/${props.avatars[0][0].fileId}`}
            size="smDomino"
          />
        </Tooltip>
      ) : (
        <Tooltip label={props.userName} variant="dominoLight">
          <Avatar name={props.userName} className={s.avatar} size="smDomino" />
        </Tooltip>
      )}
      <Flex direction="column" style={{ maxWidth: "80%" }}>
        <Box
          onMouseEnter={setMessageHover.on}
          className={`${s.message} ${props.message.replyMarkup !== null ? s.messagWithButtons : ""}`}
          style={{
            maxWidth: props.message.photos[displayImageIndex]?.width ?? "",
          }}
        >
          {isSupported ? (
            <>
              {props.message.replyToMessage !== null && (
                <Box
                  className={`${s.messageReplyWrapper} ${
                    props.message.replyToMessage?.contact !== null ? s.messageReplySharedContactMes : ""
                  }`}
                  id={`${props.message.contact && `reply-${props.message.id}`}`}
                  style={{
                    width: props.message.photos[displayImageIndex]?.width ?? "",
                  }}
                >
                  <Box
                    className={`${s.messageReply} ${
                      props.message.replyToMessage?.contact !== null ? s.messageReplySharedContactWrp : ""
                    } `}
                    onClick={() => props.onReplyClick(props.message.replyToMessage?.id ?? "")}
                    onTouchStart={() => props.onReplyClick(props.message.replyToMessage?.id ?? "")}
                  >
                    {props.message.replyToMessage?.contact && (
                      <Box className={s.messageReplySharedContact}>
                        <Box className={s.contactName}>
                          {props.message.replyToMessage?.contact.lastName && (
                            <>
                              <span>{props.message.replyToMessage?.contact.lastName} </span>
                              <p>&nbsp;</p>
                            </>
                          )}
                          <span> {props.message.replyToMessage?.contact.firstName}</span>
                        </Box>
                        <span className={s.contactLabel}>{t("Contact")}</span>
                      </Box>
                    )}
                    {props.message.replyToMessage?.photoFileId && (
                      <span className={s.messageReplyContentPhoto}>
                        <img
                          alt="img"
                          className={s.photo}
                          src={`${appSettings.apiBaseUrl}/file/${props.message.replyToMessage?.photoFileId}`}
                        />
                      </span>
                    )}
                    <div className={s.messageReplyContent}>
                      <span className={s.messageReplyContentAuthor}>
                        {props.message.replyToMessage?.author === "bot" ? botInfo?.name : props.message.replyToMessage?.author}
                      </span>

                      {props.message.replyToMessage?.documentName !== null ? (
                        <span className={`${s.messageReplyContentText} ${s.description}`}>
                          {props.message.replyToMessage?.documentName}
                        </span>
                      ) : (
                        <>
                          {props.message.replyToMessage?.photoFileId && !props.message.replyToMessage?.text ? (
                            <span className={`${s.messageReplyContentText} ${s.description}`}>{t("Image")}</span>
                          ) : (
                            <span className={s.messageReplyContentText}>{props.message.replyToMessage?.text}</span>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                </Box>
              )}
              <PhotoMessageItem message={message} />
              <DocumentMessageItem message={message} />
              <TextMessageItem message={message} />
              <ContactMessageItem message={message} />
              <PaymentMessageItem message={message} />
            </>
          ) : (
            <UnsupportedMessageItem message={message} />
          )}
        </Box>
        <ButtonMessageItem message={message} />
      </Flex>
      {isSupported && messageHover && props.isReply && (
        <Box className={s.messageOptionsWrapper}>
          <Icon
            as={ReplyIcon}
            onClick={() =>
              props.onReply({
                enabled: true,
                message: {
                  id: props.message.id,
                  text: props.message.text,
                  author: props.userName ?? t("Unknown"),
                  documentName: props.message.documents?.at(0)?.fileName,
                  photoFileId: props.message.photos?.at(0)?.fileId,
                  contact: props.message.contact?.firstName,
                },
              })
            }
          ></Icon>
        </Box>
      )}
    </Box>
  );
};

const MessageAvatar = (props: {
  origin?: {
    type: MessageOriginType;
    name: string | null;
    id: string | null;
  };
  botInfo: BotModel;
}) => {
  if (!props.origin) {
    return <BotAvatarComponent botInfo={props.botInfo} />;
  }

  switch (props.origin.type) {
    case MessageOriginType.Broadcast:
      return <BroadcastAvatarComponent name={props.origin.name} id={props.origin.id} />;
    case MessageOriginType.Operator:
      return !!props.origin.name ? (
        <TooltipWithTouch label={props.origin.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
          <Avatar bg={"#3E5E95"} color={"#FFFFFF"} size={"sm"} name={props.origin.name} />
        </TooltipWithTouch>
      ) : (
        <BotAvatarComponent botInfo={props.botInfo} />
      );
    case MessageOriginType.Bot:
    case MessageOriginType.ApiClient:
    case MessageOriginType.Undefined:
    default:
      return <BotAvatarComponent botInfo={props.botInfo} />;
  }
};

const BotAvatarComponent = (props: { botInfo: BotModel }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBot = () => {
    navigate(`/bots/${props.botInfo?.id}`);
  };

  return (
    <Tooltip
      isOpen={tooltip.isOpen}
      label={props.botInfo?.name ?? props.botInfo?.username}
      bg={"#FEF6DC"}
      variant={"dominoLight"}
      placement="top"
    >
      <Box
        className={s.avatarBot}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
        onClick={e => openBot()}
      >
        <Icon as={BotAvatar} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};

const BroadcastAvatarComponent = (props: { name: string | null; id: string | null }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBroadcast = () => {
    if (props.id) {
      navigate(`/broadcasts/${props.id}/view`);
    }
  };

  return (
    <Tooltip isOpen={tooltip.isOpen} label={props.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
      <Box
        onClick={e => openBroadcast()}
        className={`${s.avatarBroadcast} ${!!props.id ? s.canOpen : ""}`}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
      >
        <Icon as={MailIcon} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};
