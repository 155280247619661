import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Modal,
  Text,
  ModalOverlay,
  ModalFooter,
  Button,
  Flex,
} from "@chakra-ui/react";
import s from "./ContactInfoBlockConversationDialog.module.scss";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { useTranslation } from "react-i18next";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";

export interface Props {
  conversationName: string;
  isOpen: boolean;
  onApply: () => void;
  onClose: () => void;
}

export const ContactInfoBlockConversationDialog = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const t = useTranslation("translation", { keyPrefix: "contactInfo" }).t;
  const isLoadingBlock = useGetLoadingState("blockConversation");

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered={true} autoFocus={true}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
      >
        <ModalHeader className={s.modalHeader}>
          <Text className={s.headerText} variant="h1">
            {t("Block this chat with", { name: props.conversationName })}
          </Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody px={"24px"}>{t("This action can be undone")}</ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <Button
              mt="16px"
              width={"100%"}
              variant="dominoOutlineViolet"
              flexShrink={0}
              iconSpacing={"4px"}
              w={"min-content"}
              onClick={props.onClose}
            >
              {ct("Cancel")}
            </Button>
            <Button
              mt="16px"
              width={"100%"}
              variant="dominoPrimaryRed"
              flexShrink={0}
              spinnerPlacement={"end"}
              loadingText={t("Block")}
              isLoading={isLoadingBlock}
              w={"min-content"}
              onClick={props.onApply}
            >
              <span>{t("Block")}</span>
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
