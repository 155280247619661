import { BooleanVariableValues, CustomVariableType } from "../../../../../common/AppEnums";
import { customDateInput, DatePicker } from "../../../../../UI/atoms/datePicker/DatePicker";
import s from "../EditContact.module.scss";
import dayjs from "dayjs";
import {
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useOutsideClick,
  Box,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContactVariableModel } from "../../../ContactModel";
import { Dispatch, forwardRef, SetStateAction, useRef, useState } from "react";
import { ReactComponent as CatalogValueIcon } from "../../../../../assets/icons/variableTypes/catalogValueIcon.svg";

interface DefaultProps {
  contactVar: ContactVariableModel;
  variablesText: Record<string, unknown>;
  setVariablesText: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  getVariableValuePlaceholder: (type: CustomVariableType) => string;
  changeVariable: (contactVariable: ContactVariableModel, defaultValue: ContactVariableModel) => void;
}

interface HandlerProps {
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlur: (
    e: React.FocusEvent<HTMLInputElement>,
    contactVar: ContactVariableModel,
    variablesText: Record<string, unknown>,
  ) => void;
}

interface ChangerProps {
  changeTextInputNumber: (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => void;
  changeTextInputText: (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => void;
}

type Props = DefaultProps & HandlerProps & ChangerProps;

export const EditContactValueInput = (props: Props) => {
  const t = useTranslation("translation", { keyPrefix: "contact" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const CustomInput = forwardRef(customDateInput);
  const [isCatalogOpen, setIsCatalogOpen] = useState<boolean>(false);
  const catalogModalRef = useRef<null | HTMLDivElement>(null);
  const locale = localStorage.getItem("i18nextLng");
  useOutsideClick({
    ref: catalogModalRef,
    handler: () => setIsCatalogOpen(false),
  });
  //const { onClose } = useDisclosure();
  switch (props.contactVar?.type) {
    case CustomVariableType.DateTime: {
      return (
        <DatePicker
          wrapperClassName={s.contactDatePicker}
          dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
          selected={
            (props.variablesText[props.contactVar.id] as string)
              ? new Date(props.variablesText[props.contactVar.id] as string)
              : null
          }
          onChange={scheduledDate => {
            props.setVariablesText(prevState => ({
              ...prevState,
              [props.contactVar.id]: dayjs(scheduledDate).isValid()
                ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ")
                : undefined,
            }));
          }}
          placeholderText={`${t("Not set")}`}
          showTimeSelect
          customInput={<CustomInput ml="-16px" borderColor={"transparent"} placeholdertext={ct("placeholderDateTime")} />}
          strictParsing
          shouldCloseOnSelect={true}
          onCalendarClose={() => {
            props.changeVariable(
              {
                ...props.contactVar,
                value: props.variablesText[props.contactVar.id] as string,
              },
              props.contactVar,
            );
          }}
        />
      );
    }
    case CustomVariableType.Date: {
      return (
        <DatePicker
          wrapperClassName={s.contactDatePicker}
          dateFormat="dd.MM.yyyy"
          selected={
            (props.variablesText[props.contactVar.id] as string)
              ? new Date(props.variablesText[props.contactVar.id] as string)
              : null
          }
          onChange={scheduledDate => {
            props.setVariablesText(prevState => ({
              ...prevState,
              [props.contactVar.id]: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : undefined,
            }));
          }}
          placeholderText={`${t("Not set")}`}
          customInput={<CustomInput ml="-16px" borderColor={"transparent"} placeholdertext={ct("placeholderDate")} />}
          strictParsing
          shouldCloseOnSelect={true}
          onCalendarClose={() =>
            props.changeVariable(
              {
                ...props.contactVar,
                value: props.variablesText[props.contactVar.id] as string,
              },
              props.contactVar,
            )
          }
        />
      );
    }
    case CustomVariableType.Boolean: {
      const borderColor = "transparent";
      return (
        <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
          <MenuButton
            h="36px"
            border="1px"
            borderRadius="8px"
            borderColor={borderColor}
            ml="-16px"
            color={"black"}
            bg="white"
            w="100%"
            _hover={{ bgColor: "white", borderColor: "mainPurple" }}
            onClick={e => e.stopPropagation()}
          >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Flex w="100%" px="16px" alignItems={"center"}>
                {props.variablesText[props.contactVar.id] ? (
                  <Text>{ct(props.variablesText[props.contactVar.id] as string)}</Text>
                ) : (
                  <Text noOfLines={1} color="#718096" style={{ textWrap: "nowrap" }}>
                    {ct("Not Set")}
                  </Text>
                )}
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList>
            {Object.keys(BooleanVariableValues).map((el, i) => {
              return (
                <MenuItem
                  key={i}
                  value={el}
                  onClick={() => {
                    props.changeVariable(
                      {
                        ...props.contactVar,
                        value: el !== BooleanVariableValues["Not Set"] ? el : "",
                      },
                      props.contactVar,
                    );
                  }}
                >
                  {ct(el)}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }
    case CustomVariableType.Number: {
      return (
        <Input
          ml="-16px"
          borderColor={"transparent"}
          bg="white"
          noOfLines={1}
          placeholder={`${t("Not set")}`}
          value={props.variablesText[props.contactVar.id] as string}
          onKeyDown={e => props.handleKeyPress(e)}
          type={"text"}
          onChange={e => props.changeTextInputNumber(e, props.contactVar)}
          onFocus={e => (e.target.placeholder = props.getVariableValuePlaceholder(props.contactVar.type))}
          onBlur={e => props.handleOnBlur(e, props.contactVar, props.variablesText)}
        />
      );
    }
    case CustomVariableType.Array:
    case CustomVariableType.Text: {
      return (
        <Input
          noOfLines={1}
          ml="-16px"
          borderColor={"transparent"}
          bg="white"
          placeholder={`${t("Not set")}`}
          value={props.variablesText[props.contactVar.id] as string}
          onKeyDown={e => props.handleKeyPress(e)}
          type={props.contactVar.type}
          onChange={e => props.changeTextInputText(e, props.contactVar)}
          onFocus={e => {
            e.target.placeholder = props.getVariableValuePlaceholder(props.contactVar.type);
          }}
          onBlur={e => props.handleOnBlur(e, props.contactVar, props.variablesText)}
        />
      );
    }
    case CustomVariableType.Order: {
      return (
        <Box position="relative">
          <InputGroup position={"relative"}>
            {!!props.variablesText[props.contactVar.id] && (
              <InputLeftElement width={"auto"}>
                <CatalogValueIcon margin-left={"0"} />
              </InputLeftElement>
            )}
            <Input
              noOfLines={1}
              ml="-16px"
              readOnly
              isDisabled={!!!props.variablesText[props.contactVar.id]}
              borderColor={"transparent"}
              bg="white"
              cursor={props.variablesText[props.contactVar.id] ? "pointer" : "initial"}
              placeholder={props.variablesText[props.contactVar.id] ? `${t("Order")}` : `${t("Not set")}`}
              value={props.variablesText[props.contactVar.id] ? `${t("Order")}` : `${t("Not set")}`}
              type={props.contactVar.type}
              _hover={{ borderColor: props.variablesText[props.contactVar.id] ? "#4F3DED" : "transparent" }}
              _focus={{ borderColor: props.variablesText[props.contactVar.id] ? "#4F3DED" : "transparent" }}
              onClick={() => {
                if (!!props.variablesText[props.contactVar.id]) {
                  setIsCatalogOpen(!isCatalogOpen);
                }
              }}
            />
          </InputGroup>
          <Flex className={s.Catalogmodal} visibility={!isCatalogOpen ? "hidden" : "visible"} ref={catalogModalRef}>
            <ul className={s.CatalogModal_list}>
              {(props.variablesText[props.contactVar.id] as string)?.split("\n").map((item, index) => (
                <li className={s.CatalogModal_list} key={index}>
                  {item.trim()}
                </li>
              ))}
            </ul>
          </Flex>
        </Box>
      );
    }
  }
};
