import { Connection, Handle, Position } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCustomVariables, selectFlow, selectNodeEditorState, setNodeEvent } from "../../FlowBuilderSlice";
import { ReactComponent as startGreenIcon } from "../../../../assets/icons/startGreenIcon.svg";
import { ReactComponent as keyIcon } from "../../../../assets/icons/keyIcon.svg";
import { ReactComponent as messageIcon } from "../../../../assets/icons/messageIcon.svg";
import { ReactComponent as flashIcon } from "../../../../assets/icons/flashIcon.svg";
import { ReactComponent as refUrlIcon } from "../../../../assets/icons/refUrlIcon.svg";
import { ReactComponent as buttonClickIcon } from "../../../../assets/icons/buttonClickIcon.svg";
import { ReactComponent as commandTriggerIcon } from "../../../../assets/icons/commandTriggerIcon.svg";
import { ReactComponent as scheduleTriggerIcon } from "../../../../assets/icons/scheduleTriggerIcon.svg";
import s from "./StartingNode.module.scss";
import {
  ButtonTriggerMatchTypeName,
  CustomVariablesModel,
  KeywordMatchType,
  keywordMatchTypeName,
  ScheduleTriggerTypeDiscriminator,
  TriggerTypeDiscriminator,
  TriggerTypeName,
} from "../../FlowBuilderModel";
import { Badge, Box, Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { addTranslationPrefix, getVariableType } from "../../../../common/utils/convertPascalCaseToText";
import { formatDateTimeToView } from "../../../../common/utils/formatDate";

function StartingNode() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const cft = useTranslation("translation", { keyPrefix: "complexFilter.conditionsByType" }).t;
  const flow = useAppSelector(selectFlow);
  const nodeId = useAppSelector(selectNodeEditorState)?.id;
  const customVariables = useAppSelector(selectCustomVariables);
  const locale = localStorage.getItem("i18nextLng") ?? "ru";

  if (!flow) {
    return <></>;
  }
  const triggers = flow.triggers;

  const TriggerTypeIcons = {
    ConversationStartFlowTrigger: flashIcon,
    IncomingMessageFlowTrigger: messageIcon,
    KeywordMatchFlowTrigger: keyIcon,
    TelegramRefUrlFlowTrigger: refUrlIcon,
    ButtonClickTrigger: buttonClickIcon,
    TelegramCommandFlowTrigger: commandTriggerIcon,
    SchedulerFlowTrigger: scheduleTriggerIcon,
  };

  const isValidConnection = (connection: Connection) => {
    return !flow.edges.some(edge => edge.source === connection.source);
  };

  const isEdgeInStartingNodeHandle = () => {
    if (flow?.edges.find(el => el.source === "1")) {
      return true;
    }
    return false;
  };

  const typeOfVariable = (variables: CustomVariablesModel[] | undefined, valueCustomVariableId: string) => {
    return variables ? variables?.find(variable => variable.id === valueCustomVariableId)?.type : "";
  };

  return (
    <Box className={`${s.node} ${"targetFlowTrigger"} ${nodeId === "1" ? s.activeNode : ""}`}>
      <Flex w="100%" mb={"16px"} alignItems="center">
        <Icon as={startGreenIcon} boxSize={"24px"} />
        <Heading fontSize="16px" color="green" ml={"13px"} fontWeight={400}>
          {t("Starting Step")}
        </Heading>
      </Flex>
      {triggers?.map((el, i) => {
        return (
          <div className={s.nodeItem} key={i} data-pw={el.typeDiscriminator}>
            <Icon as={TriggerTypeIcons[el.typeDiscriminator as keyof typeof TriggerTypeIcons]} boxSize="27px" color="green" />
            <div className={s.textBlock}>
              <div className={s.event}>{t(TriggerTypeName[el.typeDiscriminator as keyof typeof TriggerTypeName])}</div>
              {el.typeDiscriminator === TriggerTypeDiscriminator.KeywordMatch && (
                <>
                  {el.keywordMatchType !== KeywordMatchType.Any ? (
                    <div className={s.eventType}>
                      {t("Message")} {t(keywordMatchTypeName[el.keywordMatchType as keyof typeof keywordMatchTypeName])}:
                    </div>
                  ) : (
                    <div className={s.eventType}>
                      {t("Message")} {t(keywordMatchTypeName[el.keywordMatchType as keyof typeof keywordMatchTypeName])}
                    </div>
                  )}
                  {el.keywords && (
                    <div className={s.eventType}>
                      {el.keywords.map((el, index, array) => {
                        return (
                          <span key={index} className={s.eventTypeMarked} color="midDeepBlue">{`${el}${
                            array.length - 1 !== index ? ", " : ""
                          }`}</span>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {el.typeDiscriminator === TriggerTypeDiscriminator.ButtonClick && (
                <>
                  <div className={s.eventType}>
                    {t("Button Payload")}{" "}
                    {t(ButtonTriggerMatchTypeName[el.buttonTriggerType as keyof typeof ButtonTriggerMatchTypeName])}:
                  </div>
                  <div className={s.eventType}>
                    {el.buttonIds?.map((el, index, array) => {
                      return (
                        <span key={index} className={s.eventTypeMarked} color="midDeepBlue">{`${el}${
                          array.length - 1 !== index ? ", " : ""
                        }`}</span>
                      );
                    })}
                  </div>
                </>
              )}
              {el.typeDiscriminator === TriggerTypeDiscriminator.CommandMatch && (
                <>
                  {el.command && (
                    <div className={s.eventType}>
                      {t("Command is")}{" "}
                      <span className={s.eventTypeMarked} color="midDeepBlue">
                        /{el.command}
                      </span>
                    </div>
                  )}
                </>
              )}
              {el.typeDiscriminator === TriggerTypeDiscriminator.Schedule && (
                <div className={s.eventType}>
                  <Box mb="20px" mt="2px">
                    {el?.conditions?.map((elem, index) => {
                      return (
                        <Flex key={index} flexWrap="wrap">
                          <Text className={s.highlightedWord}>{elem.conditionCustomVariableName}&nbsp;</Text>
                          <Text className={s.eventTypeMarked}>
                            {!!elem.condition &&
                              cft(
                                addTranslationPrefix(
                                  elem.condition,
                                  getVariableType(customVariables, elem.conditionCustomVariableId || "") || "",
                                ),
                              )}
                            &nbsp;
                          </Text>
                          <Text className={s.conditionValue}>
                            {formatDateTimeToView(
                              typeOfVariable(customVariables, elem.conditionCustomVariableId || ""),
                              elem.value,
                              locale,
                            )}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Box>
                  {el.scheduler?.value && (
                    <div className={s.badge}>
                      {t("editTriggerPopup.Repeat Interval")}{" "}
                      <span className={s.eventTypeMarked} color="midDeepBlue">
                        {el.scheduler?.value}{" "}
                        {t(`delayNodeTimeUnits.${el.scheduler.unit}`, { count: parseInt(el.scheduler.value) })}
                      </span>
                    </div>
                  )}
                  <div className={s.badge}>
                    {el.scheduler?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? (
                      <>
                        {t("editTriggerPopup.Scheduled time")}&nbsp;
                        <span className={s.eventTypeMarked} color="midDeepBlue">
                          {formatDateTimeToView("DateTime", el.scheduler?.scheduledTime ?? "", locale)}
                        </span>
                      </>
                    ) : (
                      <>
                        {t("editTriggerPopup.Start time")}&nbsp;
                        <span className={s.eventTypeMarked} color="midDeepBlue">
                          {formatDateTimeToView("DateTime", el.scheduler?.startTime ?? "", locale ?? "ru")}
                        </span>
                      </>
                    )}
                  </div>
                  {el.scheduler?.endTime && (
                    <div className={s.badge}>
                      {t("editTriggerPopup.End time")}{" "}
                      <span className={s.eventTypeMarked} color="midDeepBlue">
                        {formatDateTimeToView("DateTime", el.scheduler?.endTime ?? "", locale ?? "ru")}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!el.isEnabled && (
              <Box className={s.badgeWrapper} data-pw="disabledTrigger">
                <Badge variant="dominoBase">{t("Disabled")}</Badge>
              </Box>
            )}
          </div>
        );
      })}
      <Button
        size="md"
        variant="dominoDashedViolet"
        isDisabled={triggers.length === 6}
        onClick={e => {
          e.stopPropagation();
          dispatch(
            setNodeEvent({
              eventType: "addNewTriggerPopup",
              eventData: {},
            }),
          );
        }}
        data-pw="add-trigger-button"
      >
        {t("Add Trigger")}
      </Button>
      <div className={s.nodeFooter}>
        <Handle
          isValidConnection={isValidConnection}
          className={`${s.handleStyle} ${isEdgeInStartingNodeHandle() ? s.active : ""}`}
          type="source"
          position={Position.Right}
          id="common-source"
          isConnectable={!isEdgeInStartingNodeHandle()}
        />
        <p>{t("The First Step")}</p>
      </div>
    </Box>
  );
}

export default StartingNode;
