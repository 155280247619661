import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid } from "@chakra-ui/react";
import {
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToParentElement,
  restrictToFirstScrollableAncestor,
} from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import MenuCommandsItem from "./MenuCommandsItem/MenuCommandsItem";
import { BotMenuCommandsModel, MenuCommandModel } from "../../BotModel";
import { getBotMenuCommands, selectBotMenuCommands, reorderCommandsItems } from "../../BotSlice";
import { BOT_MENU_COMMANDS_SIZE } from "../../../../common/paginator/paginatorSizes";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import s from "./MenuCommands.module.scss";

interface Props {
  onCloseAddCommandModal: () => void;
  isOpenAddCommandModal: boolean;
  botId: string;
  isAllActiveFlag: boolean;
  botRef: React.RefObject<HTMLDivElement>;
}

export default function MenuCommands({ botId, botRef, onCloseAddCommandModal, isOpenAddCommandModal, ...props }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const dispatch = useAppDispatch();
  const botMenuCommands = useAppSelector(selectBotMenuCommands);
  const [items, setItems] = useState<MenuCommandModel[]>([]);
  const currentPageRef = useRef(0);
  const loadingRef = useRef(false);
  const [commands, setCommands] = useState<BotMenuCommandsModel | null>(null);

  useEffect(() => {
    // Initial loadind
    dispatch(getBotMenuCommands({ botId, page: 1, size: BOT_MENU_COMMANDS_SIZE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  useEffect(() => {
    if (botMenuCommands) {
      setCommands(botMenuCommands);
      setItems(botMenuCommands.items);
      currentPageRef.current = botMenuCommands.currentPage;
      loadingRef.current = false;
    }
  }, [botMenuCommands]);

  const loadCommandsOnScroll = useCallback(() => {
    if (!loadingRef.current && commands && currentPageRef.current < commands.totalPages) {
      const nextPage = currentPageRef.current + 1;
      loadingRef.current = true;
      dispatch(getBotMenuCommands({ botId, page: nextPage, size: BOT_MENU_COMMANDS_SIZE }));
    }
  }, [commands, dispatch, botId]);

  const handleScroll = () => {
    if (botRef.current) {
      const scrollHeight = botRef.current.scrollHeight;
      const scrollTop = botRef.current.scrollTop;
      const scrollPosition = scrollHeight - scrollTop;
      const isBottom = scrollPosition < botRef.current?.getBoundingClientRect().bottom;
      if (isBottom) {
        loadCommandsOnScroll();
      }
    }
  };

  useEffect(() => {
    const currentBotRef = botRef.current;
    if (currentBotRef) {
      currentBotRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentBotRef) {
        currentBotRef.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(MouseSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && over.id) {
      setItems(items => {
        let newItems = items.map(x => {
          if (x.id === active.id) {
            return { ...x, isDrag: false };
          }
          return x;
        });

        if (active.id !== over.id) {
          const oldIndex = items.findIndex(x => x.id === active.id);
          const newIndex = items.findIndex(x => x.id === over.id);

          newItems = arrayMove(newItems, oldIndex, newIndex);
          dispatch(
            reorderCommandsItems({
              botId: botId,
              data: {
                sourceCommandId: active.id.toString(),
                destinationCommandId: over.id.toString(),
              },
            }),
          );
        }
        return newItems;
      });
    } else {
      setItems(items => {
        return items.map(x => {
          return { ...x, isDrag: false };
        });
      });
    }
  };

  const handleDragCancel = (event: DragCancelEvent) => {
    setItems(items => {
      return items.map(x => {
        return { ...x, isDrag: false };
      });
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    if (active) {
      setItems(items => {
        return items.map(x => {
          if (x.id === active.id) {
            return { ...x, isDrag: true };
          }
          return x;
        });
      });
    }
  };
  return (
    <Flex pb="77px" flexDirection="column" gap="16px" className={s.menuCommands}>
      <Grid className={s.grid}>
        <Box pl="24px">{t("Command Name")}</Box>
        <Box paddingLeft={"8px"}>{t("Description")}</Box>
        <Box>{t("Active")}</Box>
      </Grid>
      <DndContext
        autoScroll={{
          enabled: true,
          acceleration: 10,
        }}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement, restrictToFirstScrollableAncestor]}
      >
        <SortableContext strategy={verticalListSortingStrategy} items={items.map(x => x.id)}>
          <Flex flexDirection="column" gap="8px">
            {items.map(el => (
              <MenuCommandsItem key={el.id} commandItem={el} botId={botId} />
            ))}
          </Flex>
        </SortableContext>
      </DndContext>
    </Flex>
  );
}
