import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import s from "./BotListItem.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import telegramImage from "../../../../assets/images/telegramImage.svg";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIconTile.svg";
import { BotModel } from "../../BotListModel";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { selectCanAddNewFlow } from "../../../automation/AutomationSlice";
import { BotFlowEvents, BotInteractionEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  info: BotModel;
  currentPage: number;
  createNewFlow: (botInfo: BotModel) => void;
  redirectToBotFlows: (bot: BotModel) => void;
}

export const BotListItem = ({ info, createNewFlow, currentPage, redirectToBotFlows }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const navigate = useNavigate();

  const trackEvent = useGAWithCustomParameters(EventCategories.Bot);
  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;

  return (
    <Box
      className={s.botListItem}
      justifyContent={"space-between"}
      onClick={() => {
        trackEvent(BotInteractionEvents.BotView);
        navigate(`/bots/${info.id}`, { state: { page: currentPage } });
      }}
      data-pw="botListItem"
    >
      <Box className={s.botInfo} flexDirection={"column"} justifyContent={"space-between"}>
        <Text className={s.mobileTitle}>{t("Bot")}:</Text>
        <Heading fontSize={"16px"} lineHeight={"20px"} fontWeight={"400"} className={s.mobTextEllips}>
          {info.name}
        </Heading>
      </Box>
      <Box className={s.botInfo}>
        <Text className={s.mobileTitle}>{t("Username")}:</Text>
        <Flex alignItems={"center"} overflow={{ base: "hidden", md: "visible" }}>
          <Image w={"20px"} h={"20px"} mr={"8px"} src={telegramImage} />
          <Box fontSize={"15px"} className={s.mobTextEllips}>
            {" "}
            @{info.username}
          </Box>
        </Flex>
      </Box>

      <Box className={s.botInfo}>
        <Text className={s.mobileTitle}>{t("Flows")}:</Text>
        <Box
          className={s.flowLink}
          onClick={e => {
            e.stopPropagation();
            redirectToBotFlows(info);
          }}
        >
          {info.flowCount}
        </Box>
      </Box>
      <Box className={s.flowInfo} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-between"}>
        {!canAddFlow ? (
          <Popover trigger={"hover"}>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant="dominoDashedViolet"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    data-pw="new-flow-button"
                  >
                    <Icon className={`${sp.starPlanHover} starPlanHover`} stroke="#6d5bf7" boxSize="20px" as={StarPlan} />{" "}
                    {t("New Flow")}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                    <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                    <Flex alignItems="center" justifyContent="center" direction="column">
                      <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                        {tp("Advanced feature")}
                      </PopoverHeader>
                      <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                        {tp("Please upgrade your plan to create more Flows")}
                      </PopoverBody>
                      <TariffPlanRedirectButton onClose={onClose} />
                    </Flex>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        ) : (
          <Button
            variant="dominoDashedViolet"
            onClick={e => {
              e.stopPropagation();
              trackEvent(BotFlowEvents.BotNewFlow);
              createNewFlow(info);
            }}
            data-pw="new-flow-button"
          >
            <Flex gap={"8px"} align={"center"}>
              <PlusIcon />
              {t("New Flow")}
            </Flex>
          </Button>
        )}
      </Box>
    </Box>
  );
};
