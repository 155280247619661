/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./ChangeButtonPopover.module.scss";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { Formik, FormikErrors } from "formik";
import { v1 as uuidv1 } from "uuid";
import {
  ButtonType,
  ButtonTypeDiscriminator,
  CustomVariableModel,
  InlineKeyboardTypeDiscriminatorList,
} from "../../../common/AppEnums";
import { ButtonModel, DynamicButtonModel, StaticButtonModel } from "../../../common/AppButtonsModel";
import { StaticButtonFormFields } from "./StaticButtonFormFields";
import { DynamicButtonFormFields } from "./DynamicButtonFormFields";
import { SortableItem } from "../../dnd/SortableItem/SortableItem";
import { InlineDynamicButtonItem } from "../changeButtonPopover/components/inlineDynamicButtonItem/InlineDynamicButtonItem";
import { InlineButtonItem } from "../changeButtonPopover/components/inlineButtonItem/InlineButtonItem";

interface Props {
  button?: ButtonModel;
  buttonState: ButtonModel | null;
  variables: CustomVariableModel[];
  isDefaultPayload?: boolean;
  setButtonState: Dispatch<SetStateAction<ButtonModel | null>>;
  onDoneForm: (button: ButtonModel) => void;
  deleteButton?: (callbackData: string) => void;
  addVariable?: (nodeEditorInfo: unknown) => void;
  isBroadcast?: boolean;
  placement?: PlacementWithLogical;
  isNoTriggerButtonCreate?: boolean;
}

type ButtonForm = ButtonModel;

export const ChangeButtonPopover = ({
  button,
  setButtonState,
  buttonState,
  variables,
  isDefaultPayload,
  onDoneForm,
  deleteButton,
  isBroadcast,
  placement = "left",
  isNoTriggerButtonCreate,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [newTab, setNewTab] = useState("");

  const onClose = () => {
    setButtonState(null);
  };

  const validateUrl = (url: string) => {
    // eslint-disable-next-line
    const urlRegex = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return urlRegex.test(url);
  };

  const validateFields = (values: ButtonForm) => {
    if (
      isBroadcast ||
      values?.typeDiscriminator ===
        (ButtonTypeDiscriminator.FlowMessageUrlButton || ButtonTypeDiscriminator.FlowMessageCallbackButton)
    ) {
      const errors: FormikErrors<StaticButtonModel> = {};
      const currentValues = values as StaticButtonModel;
      if (
        (currentValues.type === ButtonType.UrlButton && !currentValues.url?.trim().length) ||
        (currentValues.url && !validateUrl(currentValues.url))
      ) {
        errors.url = t("Website address is required") ?? "";
      }
      if (!currentValues.label?.trim().length) {
        errors.label = t("Button Title is required") ?? "";
      }
      if (!isDefaultPayload && currentValues.type === ButtonType.CallbackButton && !currentValues.payload?.trim().length) {
        errors.payload = t("Payload is required") ?? "";
      }
      return errors;
    }
    if (values.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton) {
      const errors: FormikErrors<DynamicButtonModel> = {};

      return errors;
    }
  };

  const getInitialValues = (value: ButtonModel): ButtonForm => {
    if (value.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton) {
      return {
        ...value,
        typeDiscriminator: ButtonTypeDiscriminator.FlowMessageDynamicButton,
      };
    }
    const type = button?.type ?? value.type;
    const typeDiscriminator = button?.typeDiscriminator ?? value.typeDiscriminator;
    if (button) {
      return {
        ...value,
        url:
          type === ButtonType.UrlButton || typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton
            ? (button as StaticButtonModel)?.url
            : "",
        payload:
          type === ButtonType.CallbackButton || typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton
            ? button?.payload
            : "",
      };
    } else {
      return {
        ...value,
        url:
          type === ButtonType.UrlButton || typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton ? value?.url : "",
        payload:
          type === ButtonType.CallbackButton || typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton
            ? value?.payload
            : "",
      };
    }
  };

  const onStart = () => {
    if (button) {
      const url = (button as StaticButtonModel).url ?? "";
      const typeDiscriminator = url
        ? ButtonTypeDiscriminator.FlowMessageUrlButton
        : ButtonTypeDiscriminator.FlowMessageCallbackButton;
      const isStaticButtonTypeDiscriminator =
        button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton ||
        button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton;
      if (!button.typeDiscriminator || isStaticButtonTypeDiscriminator) {
        const popoverButton: StaticButtonModel = {
          id: button.id,
          label: button.label ?? "",
          type: url ? ButtonType.UrlButton : ButtonType.CallbackButton,
          typeDiscriminator: typeDiscriminator,
          targetNodeId: button.targetNodeId,
        };
        setButtonState({ ...popoverButton, ...(url ? { payload: button.payload } : { url: url }) });
      } else {
        setButtonState(button);
      }
    } else {
      setButtonState(button => {
        const id = uuidv1();
        return {
          id,
          label: "",
          payload: isDefaultPayload ? "Button-" + id : "",
          type: ButtonType.CallbackButton,
          typeDiscriminator: ButtonTypeDiscriminator.FlowMessageCallbackButton,
          targetNodeId: button ? button.targetNodeId : "",
        } as ButtonModel;
      });
    }
  };

  const trimObjectValues = (obj: any) => {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      acc[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
      return acc;
    }, {});
  };

  const onDone = (values: ButtonForm) => {
    const { ...cleanValues } = trimObjectValues(values);
    if (buttonState) {
      onDoneForm(
        InlineKeyboardTypeDiscriminatorList.includes(values.typeDiscriminator)
          ? ({
              ...cleanValues,
              payload: values.type === ButtonType.CallbackButton ? cleanValues.payload : "",
              url: values.type === ButtonType.UrlButton ? (values as StaticButtonModel).url?.trim() : "",
              typeDiscriminator: (values as StaticButtonModel).url
                ? ButtonTypeDiscriminator.FlowMessageUrlButton
                : ButtonTypeDiscriminator.FlowMessageCallbackButton,
            } as StaticButtonModel)
          : { ...cleanValues },
      );
      setButtonState(null);
    }
  };

  return (
    <Popover
      placement={isNoTriggerButtonCreate ? "right" : placement}
      isOpen={button ? Boolean(buttonState?.id === button.id) : Boolean(buttonState)}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Flex width="100%" pos="relative">
          {button ? (
            <SortableItem id={button.id} handle={true}>
              <InlineDynamicButtonItem onDelete={() => deleteButton?.(button.id)} onStart={onStart} button={button} />
            </SortableItem>
          ) : (
            <div onClick={onStart} className={s.addButton}>
              <button data-pw="add-button" type="button">
                + {t("Add Button")}
              </button>
            </div>
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent maxWidth="300px">
        <PopoverHeader p="20px 24px">
          <Text variant="largeBold">{button ? t("Edit Button") : t("Create a Button")}</Text>
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton
          width="32px"
          height="32px"
          top="14px"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          data-pw="close-button"
        >
          <CrossIcon />
        </PopoverCloseButton>
        <PopoverBody p="24px" data-pw="add-button-modal">
          {buttonState && (
            <Formik
              data-pw="form"
              validate={validateFields}
              onSubmit={onDone}
              initialValues={getInitialValues(buttonState)}
              enableReinitialize
            >
              {({ values, isValid, dirty, handleSubmit, submitCount }) => (
                <>
                  {!isBroadcast && <InlineButtonItem isBroadcast={isBroadcast} setNewTab={setNewTab} newTab={newTab} />}
                  {values.typeDiscriminator !== ButtonTypeDiscriminator.FlowMessageDynamicButton ? (
                    <StaticButtonFormFields
                      isNoTriggerButtonCreate={isNoTriggerButtonCreate}
                      isDefaultPayload={isDefaultPayload}
                    />
                  ) : (
                    <DynamicButtonFormFields variables={variables} />
                  )}
                  <Flex justifyContent={button ? "space-between" : "flex-end"}>
                    {button && (
                      <Button
                        size="sm"
                        variant="dominoOutlineViolet"
                        onClick={() => deleteButton?.(button.id)}
                        data-pw="delete-button"
                      >
                        {ct("Delete")}
                      </Button>
                    )}
                    <Button
                      disabled={button ? !dirty || !isValid : !dirty || (!isValid && submitCount > 0)}
                      size="sm"
                      width={isNoTriggerButtonCreate ? "100%" : "auto"}
                      variant="dominoViolet"
                      onClick={() => handleSubmit()}
                      data-pw="done-button"
                    >
                      {t("Done")}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
