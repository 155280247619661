import { Flex, Avatar, Link, Image, Text } from "@chakra-ui/react";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";
import s from "../../ContactInfo.module.scss";
import TelegramIcon from "../../../../assets/images/telegramImage.svg";
import { AppSettings } from "../../../../common/AppSettings";
import { ContactInfoModel } from "../../ContactInfoModel";
import GTM from "../../../../common/ga/GAEventTracker";
import { ChatSettings } from "../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
declare const appSettings: AppSettings;

interface Props {
  contactInfo: ContactInfoModel;
  isBlocked: boolean;
}

export default function ContactInfoAvatar({ contactInfo, isBlocked }: Props) {
  const trackEvent = GTM(EventCategories.Chats);
  return (
    <Flex className={s.contactInfo} flexDir={"column"} alignItems={"center"}>
      {contactInfo.avatars?.length ? (
        <Image
          fallback={
            <Avatar
              boxSize={"100px"}
              opacity={isBlocked ? "50%" : "inherit"}
              name={contactInfo.lastName ? `${contactInfo.firstName} ${contactInfo.lastName}` : contactInfo.firstName}
              bg="midDeepBlue"
              color={"white"}
            />
          }
          boxSize={"100px"}
          borderRadius={"50%"}
          bg="midDeepBlue"
          color={"white"}
          opacity={isBlocked ? "50%" : "inherit"}
          src={`${appSettings.apiBaseUrl}/file/${contactInfo.avatars[0][0].fileId}`}
        />
      ) : (
        <Avatar
          boxSize={"100px"}
          opacity={isBlocked ? "50%" : "inherit"}
          name={contactInfo.lastName ? `${contactInfo.firstName} ${contactInfo.lastName}` : contactInfo.firstName}
          bg="midDeepBlue"
          color={"white"}
        />
      )}
      <Flex flexDir={"column"} mt={"16px"}>
        <Flex className={s.contactId}>
          <Flex alignSelf={"center"} marginLeft={"50px"}>
            <Text>id:</Text>
            <ClipboardText
              data_pw="copy-telegram-id"
              textIsCopy={contactInfo.externalId}
              elemIsCopy={<Text ml={"8px"}>{contactInfo.externalId}</Text>}
              color="#3e5e95"
              event={() => trackEvent(ChatSettings.ChatTgID)}
            />
          </Flex>
        </Flex>
        {contactInfo.username && (
          <Flex className={s.contactName} alignSelf={"Center"}>
            <Image src={TelegramIcon} boxSize={"20px"} />
            <Link
              href={`https://t.me/${contactInfo.username}`}
              data-pw="contact-telegram-link"
              isExternal
              ml={"8px"}
              color={"blueLink"}
              onClick={() => trackEvent(ChatSettings.ChatTgLinkForward)}
            >
              {`@${contactInfo?.username}`}
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
