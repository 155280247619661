import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import s from "./AddFirstGroupChatContainer.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { AddBotStep } from "./components/AddBotStep";
import { SettingBotStep } from "./components/SettingBotStep";
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/backArrowIcon.svg";

export const AddFirstGroupChatContainer = () => {
  const groupTranslation = useTranslation("translation", { keyPrefix: "groupChat" }).t;
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [countStep, setCountStep] = useState(1);

  const nowStep: {
    [key: number]: {
      component: JSX.Element;
      title: string;
    };
  } = {
    1: {
      component: <SettingBotStep action={() => setCountStep(2)} />,
      title: groupTranslation("settingBot.Setting up the rights of the bot"),
    },
    2: {
      component: <AddBotStep action={() => setCountStep(prev => prev - 1)} />,
      title: groupTranslation("addingBot.Adding a bot"),
    },
  };

  return (
    <Flex className={s.containerAddGroupChat}>
      <Flex className={s.addGroupChatTextContainer}>
        <Text variant="h2Bold">{groupTranslation("You don't have group chats yet")}</Text>
        <Text variant="mediumGrey">{groupTranslation("Add your bot to a group or channel by following these instructions")}</Text>
      </Flex>
      <Flex className={s.conteiner}>
        <Flex className={s.content}>
          <Box className={s.header}>
            {countStep !== 1 && (
              <Flex onClick={() => setCountStep(prev => prev - 1)} className={s.back}>
                <Icon as={BackArrowIcon} boxSize="16px" fill="currentColor" />
                <Text className={s.text} variant="medium">
                  {commonWords("Back")}
                </Text>
              </Flex>
            )}
            <Text variant="h3" fontWeight={500}>
              {nowStep[countStep].title}
            </Text>
            <Text variant="medium" className={s.step}>{`${countStep}/2`}</Text>
          </Box>
          {nowStep[countStep].component}
        </Flex>
      </Flex>
    </Flex>
  );
};
