import { CustomVariableModel, EMOJI_DEFAULT_LENGTH } from "../../common/AppEnums";
import { InputComponent } from "./components/inputComponent/InputComponent";
import { TextAreaComponent } from "./components/textAreaComponent/TextAreaComponent";
import { InputType, InputWrapperPropsModel } from "./InputWrapperModel";

export const InputWrapper = (props: InputWrapperPropsModel) => {
  const countLeftSymbols = () => {
    return props.maxLength - props.charactersNumber;
  };
  const onSelectCustomVar = (variable: CustomVariableModel) => {
    const cursorPosition = props.cursorPosition ?? 0;
    props.variablesProps?.addCustomVariableText(variable);
    props.childRef?.current?.focus();
    setTimeout(() => {
      props.childRef?.current?.setSelectionRange(
        cursorPosition + ("@{" + variable.scope + ":" + variable.key + "}").length,
        cursorPosition + ("@{" + variable.scope + ":" + variable.key + "}").length,
      );
    }, 0);
  };

  const onAddEmoji = (emojiData: string) => {
    const cursorPosition = props.cursorPosition ?? 0;
    props.emojiProps?.addEmoji(emojiData);
    props.childRef?.current?.focus();
    setTimeout(() => {
      props.childRef?.current?.setSelectionRange(cursorPosition + EMOJI_DEFAULT_LENGTH, cursorPosition + EMOJI_DEFAULT_LENGTH);
    }, 0);
  };

  const variableProps = props.variablesProps ? { ...props.variablesProps, addCustomVariableText: onSelectCustomVar } : undefined;
  const emojiProps = props.emojiProps ? { ...props.emojiProps, addEmoji: onAddEmoji } : undefined;

  switch (props.type) {
    case InputType.Input:
      return (
        <InputComponent
          charactersLeft={countLeftSymbols()}
          variablesProps={variableProps}
          emojiProps={emojiProps}
          buttonBlockStyle={props.buttonBlockStyle}
        >
          {props.children}
        </InputComponent>
      );

    case InputType.TextArea:
      return (
        <TextAreaComponent
          charactersLeft={countLeftSymbols()}
          variablesProps={variableProps}
          emojiProps={emojiProps}
          buttonBlockStyle={props.buttonBlockStyle}
          customVariablesMenuView={props.variablesProps?.ContextModelType}
          customButton={props.customButton}
          onFormattingClick={props.addFormatting}
          isDisabled={props.isDisabled}
          hideToolsButtons={props.hideToolsButtons}
          isNoVariablesButton={props.isNoVariablesButton}
        >
          {props.children}
        </TextAreaComponent>
      );
    default:
      return <div></div>;
  }
};
