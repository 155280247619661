import { Box } from "@chakra-ui/react";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useState } from "react";
import { ButtonModel } from "../../../../common/AppButtonsModel";
import { useAppSelector } from "../../../../common/state/store";
import { ChangeButtonPopover } from "../../../modals/changeButtonPopover/ChangeButtonPopover";
import { selectCustomVariables } from "../../BroadcastSlice";

interface Props {
  buttons: ButtonModel[];
  addButton: (button: ButtonModel) => void;
  editButton: (button: ButtonModel) => void;
  deleteButton: (buttonId: string) => void;
  setButtons: (buttons: ButtonModel[]) => void;
  isNoTriggerButtonCreate?: boolean;
}

export const ButtonsList = ({ buttons, addButton, editButton, deleteButton, setButtons, isNoTriggerButtonCreate }: Props) => {
  const [addButtonState, setAddButtonState] = useState<ButtonModel | null>(null);
  const [editButtonState, setEditButtonState] = useState<ButtonModel | null>(null);
  const variables = useAppSelector(selectCustomVariables);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const items = buttons;
      const activeOld = items.find(item => item.id === active?.id);
      const overNew = items.find(item => item.id === over?.id);
      if (activeOld && overNew) {
        const oldIndex = items.indexOf(activeOld);
        const newIndex = items.indexOf(overNew);
        setButtons(arrayMove(items, oldIndex, newIndex));
      }
    }
  }

  return (
    <Box width="100%" overflow="auto">
      <DndContext
        key={`dndContextR-buttons-list`}
        id={`dndContextR-buttons-list`}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          id={`sortableContextR-buttons-list`}
          strategy={verticalListSortingStrategy}
          items={buttons.map(button => button.id)}
          data-pw="buttons-list"
        >
          {buttons.map(button => {
            return (
              <ChangeButtonPopover
                isBroadcast
                key={button.id}
                button={button}
                variables={variables ?? []}
                buttonState={editButtonState}
                setButtonState={setEditButtonState}
                onDoneForm={editButton}
                deleteButton={deleteButton}
                placement="auto"
                isNoTriggerButtonCreate={isNoTriggerButtonCreate}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      <ChangeButtonPopover
        isBroadcast
        variables={variables ?? []}
        buttonState={addButtonState}
        setButtonState={setAddButtonState}
        onDoneForm={addButton}
        placement="auto"
        isNoTriggerButtonCreate={isNoTriggerButtonCreate}
      />
    </Box>
  );
};
