/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomVariableModel } from "../../common/AppEnums";
import { RootState } from "../../common/state/store";
import {
  BroadcastDirection,
  BroadcastMessageTypeDescriminator,
  BroadcastModel,
  BroadcastRecipientModel,
  BroadcastStatus,
  BroadcastValidationField,
  BroadcatMessageModel,
  ContactListModel,
  ExcludedRecipient,
  FileModel,
  FileUploadType,
  IncludedRecipient,
  RecipientFiltersModel,
  RecipientFormModel,
  RecipientModel,
  RecipientsImportModel,
} from "./BroadcastModel";
import { ButtonModel } from "../../common/AppButtonsModel";
//import { mapContactListToRecipientsList } from "./BroadcastMapper";

export interface BroadcastState {
  broadcast: BroadcastModel;
  contactList?: ContactListModel;
  recipientList?: RecipientsImportModel | null;
  currentFile?: FileModel | null;
  variables?: CustomVariableModel[];
  broadcastRecipientList?: BroadcastRecipientModel;
  recipientsFilter?: RecipientFiltersModel[] | null;
  includedRecipients?: IncludedRecipient[] | null;
  excludedRecipients?: ExcludedRecipient[] | null;
  initialRecipientsList?: RecipientModel[] | null;
  scrolledNonSelectedRecipients?: RecipientModel[] | null;
  scrolledRecipients?: RecipientFormModel[] | null;
  initialSentForAllFlag?: boolean;
  checkedFlag?: boolean;
  uncheckedFlag?: boolean;
  selectedRecipients?: RecipientModel[] | null;
  recipientsCount?: number;
  currentPage?: number;
  recipientCounter: number;
  titleInvalid?: boolean;
  titleStatus: boolean;
  shedulerValidation: string | null;
  broadcastValidationState: BroadcastValidationErrors;
  isInitialDisabledButtons: boolean;
  showLeaveBroadcastPopup: boolean;
}

interface BroadcastValidationErrors {
  isError: boolean;
  validationState: {
    message: string | null;
    name: string | null;
    recipients: string | null;
    schedule: string | null;
  };
}

const initialState: BroadcastState = {
  isInitialDisabledButtons: true,
  recipientsCount: 0,
  recipientCounter: 0,
  shedulerValidation: null,
  titleStatus: false,
  showLeaveBroadcastPopup: false,
  broadcastValidationState: {
    isError: false,
    validationState: {
      message: null,
      name: null,
      recipients: null,
      schedule: null,
    },
  },
  broadcast: {
    id: "new",
    botId: "new",
    executeState: { dirty: false, execute: false },
    isExecute: false,
    isScheduled: false,
    message: {
      text: "",
      buttons: [] as ButtonModel[],
      typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastTextMessage,
    },
    name: "",
    recipients: [],
    scheduledDate: null,
    status: BroadcastStatus.Draft,
    totalNotSent: 0,
    totalSent: 0,
  },
};

// const mergeUniqueById = (arr1: RecipientModel[], arr2: RecipientFormModel[]) => {
//   const map = new Map();
//   arr1.forEach(item => map.set(item.contactId, item));
//   arr2.forEach(item => map.set(item.contactId, item));
//   return Array.from(map.values());
// };

export const broadcastSlice = createSlice({
  name: "broadcastSlice",
  initialState,
  reducers: {
    getBroadcast: (state, action: PayloadAction<{ botId: string; broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    setBroadcastIsSheduled: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        broadcast: state.broadcast && { ...state.broadcast, isScheduled: action.payload },
      };
    },
    setBroadcastSheduledDate: (state, action: PayloadAction<Date | null>) => {
      return {
        ...state,
        broadcast: state.broadcast && { ...state.broadcast, scheduledDate: action.payload },
      };
    },
    setBroadcastSchedulerValidation: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        shedulerValidation: action.payload,
      };
    },
    setBroadcastIsExecute: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        broadcast: state.broadcast && { ...state.broadcast, isExecute: action.payload },
      };
    },
    setBroadcastExecute: (state, action: PayloadAction<{ execute: boolean; dirty: boolean }>) => {
      return {
        ...state,
        broadcast: state.broadcast && { ...state.broadcast, executeState: action.payload },
      };
    },
    setBroadcastRecipients: (state, action: PayloadAction<RecipientModel[]>) => {
      return {
        ...state,
        broadcast: state.broadcast && { ...state.broadcast, recipients: action.payload },
      };
    },
    setBroadcastMessage: (state, action: PayloadAction<BroadcatMessageModel | undefined>) => {
      const newInitialDisabled = state.broadcast.name && action.payload;
      return {
        ...state,
        isInitialDisabledButtons: !newInitialDisabled,
        showLeaveBroadcastPopup: true,
        broadcast: state.broadcast && {
          ...state.broadcast,
          message: action.payload === undefined ? state.broadcast.message : action.payload,
        },
      };
    },
    setBroadcastTitle: (state, action: PayloadAction<string>) => {
      const newInitialDisabled = state.broadcast.message.text && action.payload;

      return {
        ...state,
        isInitialDisabledButtons: !newInitialDisabled,
        showLeaveBroadcastPopup: true,
        broadcast: state.broadcast && {
          ...state.broadcast,
          name: action.payload === undefined ? state.broadcast.name : action.payload,
        },
      };
    }, //
    setTitleValidation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        titleInvalid: action.payload,
      };
    }, // если черновик, то инпут не инвалид, тк есть title
    setTitleStatusIsNew: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        titleStatus: action.payload,
      };
    }, // если черновик, то false
    getBroadcastCompleted: (state, action: PayloadAction<BroadcastModel>) => {
      return {
        ...state,
        broadcast: {
          ...action.payload,
          recipientsFilter: action.payload.recipientFilter ?? null,
          initialSentForAllFlag: action.payload.sentForAll,
          recipientCounter: action.payload.recipientsCount,
          direction: action.payload.direction,
        },
      };
    },
    getBroadcastRecipientList: (
      state,
      action: PayloadAction<{
        botId?: string;
        broadcastId?: string;
        queryFilterParams?: string;
        isScrolling?: boolean;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getBroadcastRecipientListCompleted: (state, action: PayloadAction<BroadcastRecipientModel>) => {
      return {
        ...state,
        broadcastRecipientList: action.payload,
      };
    },
    getBroadcastChatGroupsRecipientList: (
      state,
      action: PayloadAction<{
        botId?: string;
        broadcastId?: string;
        queryFilterParams?: string;
        isScrolling?: boolean;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getBroadcastChatGroupsRecipientListCompleted: (state, action: PayloadAction<BroadcastRecipientModel>) => {
      return {
        ...state,
        broadcastRecipientList: action.payload,
      };
    },
    editBroadcast: (
      state,
      action: PayloadAction<{
        botId: string;
        execute: boolean;
        recipientFilter?: RecipientFiltersModel[] | null;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getSelectedListCompleted: (state, action: PayloadAction<ContactListModel>) => {
      //const recipietnsListInit = state.initialRecipientsList ?? [];
      //const recipientsListMutable = state.recipientsList ?? [];
      // const selectedItems =
      //   action.payload.items.length > 0
      //     ? action.payload.items?.filter(item => item.isSelected === true).map(item => mapContactListToRecipientsList(item))
      //     : [];
      // return {
      //   ...state,
      //   initialRecipientsList: recipietnsListInit.length > 0 ? mergeUniqueById(recipietnsListInit, selectedItems) : selectedItems,
      //   scrolledRecipients: selectedItems,
      //   scrolledNonSelectedRecipient: action.payload.items.map(item => mapContactListToRecipientsList(item)),
      // };
      return { ...state };
    },
    // createBroadcast: (
    //   state,
    //   action: PayloadAction<{
    //     botId: string;
    //     broadcast: BroadcastModel;
    //     executeState: boolean;
    //     sentForAll: boolean;
    //     recipientFilter: RecipientFiltersModel[] | null;
    //   }>,
    // ) => {
    //   return {
    //     ...state,
    //   };
    // },
    createBroadcastCompleted: (state, action: PayloadAction<BroadcastModel>) => {
      return {
        ...state,
        broadcast: action.payload,
        recipientsFilter: action.payload.recipientFilter ?? null,
      };
    },
    deleteBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    cancelBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    executeBroadcast: (state, action: PayloadAction<{ botId: string; broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    uploadBroadcastFile: (state, action: PayloadAction<{ file: File; type: FileUploadType }>) => {
      return {
        ...state,
      };
    },
    setCurrentFile: (state, action: PayloadAction<FileModel | null>) => {
      return {
        ...state,
        currentFile: action.payload,
      };
    },
    getCustomVariables: (state, action: PayloadAction<{ botId: string }>) => {
      return {
        ...state,
      };
    },
    getCustomVariablesSucceed: (state, action: PayloadAction<CustomVariableModel[]>) => {
      return {
        ...state,
        variables: action.payload,
      };
    },
    clearState: () => {
      return initialState;
    },
    addBroadcastFilter: (state: BroadcastState, action: PayloadAction<RecipientFiltersModel>) => {
      const broadcastContactFilters = state.recipientsFilter ?? [];
      const actionFilter = broadcastContactFilters.find(filter => filter.typeDiscriminator === action.payload.typeDiscriminator);
      let newFilterArray;
      if (actionFilter) {
        newFilterArray = broadcastContactFilters.map(filter =>
          filter.typeDiscriminator === action.payload.typeDiscriminator
            ? {
                ...filter,
                members: filter.members.some(member => member.filterId === action.payload.members[0].filterId)
                  ? [...filter.members.filter(m => m.filterId !== action.payload.members[0].filterId), action.payload.members[0]]
                  : [...filter.members, action.payload.members[0]],
              }
            : filter,
        );
      } else {
        newFilterArray = [...broadcastContactFilters, action.payload];
      }
      return {
        ...state,
        recipientsFilter: newFilterArray,
      };
    },
    deleteBroadcastFilter: (state, action: PayloadAction<string>) => {
      const broadcastContactFilters = state?.recipientsFilter;
      return {
        ...state,
        recipientsFilter: broadcastContactFilters
          ? broadcastContactFilters.map(filter => ({
              ...filter,
              members: filter.members.filter(member => member.filterId !== action.payload),
            }))
          : undefined,
      };
    },
    clearBroadcastFilters: (state: BroadcastState) => {
      return {
        ...state,
        recipientsFilter: null,
      };
    },
    setRecipients: (state, action: PayloadAction<RecipientModel[]>) => {
      return {
        ...state,
        broadcast: state.broadcast ? { ...state.broadcast, recipients: action.payload } : state.broadcast,
      };
    },
    // setSelectAllState: (state, action: PayloadAction<boolean>) => {
    //   return {
    //     ...state,
    //     broadcast: state.broadcast
    //       ? {
    //           ...state.broadcast,
    //           sentForAll: action.payload,
    //         }
    //       : undefined,
    //   };
    // },
    // setSelectAllCheckedFlagState: (state, action: PayloadAction<boolean>) => {
    //   return {
    //     ...state,
    //     broadcast: state.broadcast
    //       ? {
    //           ...state.broadcast,
    //           checkedFlag: action.payload,
    //         }
    //       : undefined,
    //   };
    // },
    setRecipientCounter: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        recipientCounter: action.payload,
      };
    },
    setIncludedRecipients: (state, action: PayloadAction<IncludedRecipient[] | null>) => {
      return {
        ...state,
        includedRecipients: action.payload,
      };
    },
    setExcludedRecipients: (state, action: PayloadAction<ExcludedRecipient[] | null>) => {
      return {
        ...state,
        excludedRecipients: action.payload,
      };
    },
    createNewBroadcast: (state, action: PayloadAction<{ execute: boolean; botId: string; direction: string }>) => {
      return {
        ...state,
      };
    },
    setInititalDisabledButtons: state => {
      const newInitialDisabled = state.broadcast.message.text && state.broadcast.name;
      return {
        ...state,
        isInitialDisabledButtons: !newInitialDisabled,
        showLeaveBroadcastPopup: true,
      };
    },
    setValidationError: (state, action: PayloadAction<{ key: BroadcastValidationField; message: string | null }>) => {
      const validationBroadcastState = state.broadcastValidationState;
      const newValidationState = {
        ...validationBroadcastState.validationState,
        message:
          action.payload.key === BroadcastValidationField.message
            ? action.payload.message
            : validationBroadcastState.validationState.message,
        name:
          action.payload.key === BroadcastValidationField.name
            ? action.payload.message
            : validationBroadcastState.validationState.name,
        schedule:
          action.payload.key === BroadcastValidationField.schedule
            ? action.payload.message
            : validationBroadcastState.validationState.schedule,
        recipients:
          action.payload.key === BroadcastValidationField.recipients
            ? action.payload.message
            : validationBroadcastState.validationState.recipients,
      };
      const isErrorExists = Object.values(newValidationState).some(val => val !== null);

      return {
        ...state,
        broadcastValidationState: {
          isError: isErrorExists,
          validationState: newValidationState,
        },
      };
    },
  },
});

export const {
  getBroadcast,
  getBroadcastCompleted,
  getBroadcastRecipientList,
  getBroadcastRecipientListCompleted,
  getBroadcastChatGroupsRecipientList,
  editBroadcast,
  //createBroadcast,
  createBroadcastCompleted,
  deleteBroadcast,
  cancelBroadcast,
  executeBroadcast,
  uploadBroadcastFile,
  setCurrentFile,
  getCustomVariables,
  getCustomVariablesSucceed,
  clearState,
  addBroadcastFilter,
  deleteBroadcastFilter,
  clearBroadcastFilters,
  //setSelectAllState,
  getSelectedListCompleted,
  setRecipientCounter,
  //setSelectAllCheckedFlagState,
  setBroadcastIsSheduled,
  setBroadcastSheduledDate,
  setBroadcastSchedulerValidation,
  setBroadcastIsExecute,
  setBroadcastExecute,
  setBroadcastRecipients,
  setBroadcastMessage,
  setBroadcastTitle,
  setTitleStatusIsNew,
  setTitleValidation,
  //setRecipients,
  createNewBroadcast,
  //executeBroadcast
  setValidationError,
  setInititalDisabledButtons,
} = broadcastSlice.actions;

export const selectBroadcastState = (state: RootState) => state.app.broadcastState;
export const selectBroadcast = (state: RootState) => state.app.broadcastState.broadcast;
export const selectButtonSelectAllState = (state: RootState) => state.app.broadcastState.broadcast?.sentForAll;
export const selectBroadcastRecipientList = (state: RootState) => state.app.broadcastState.broadcastRecipientList;
export const selectCustomVariables = (state: RootState) => state.app.broadcastState.variables;
export const selectCurrentFile = (state: RootState) => state.app.broadcastState.currentFile;
export const selectRecipientsFilter = (state: RootState) => state.app.broadcastState.recipientsFilter;
export const selectRecipientsList = (state: RootState) => state.app.broadcastState.recipientList;
export const selectInitialRecipients = (state: RootState) => state.app.broadcastState.initialRecipientsList;
export const selectScrolledRecipients = (state: RootState) => state.app.broadcastState.scrolledRecipients;
export const selectNonScrolledRecipients = (state: RootState) => state.app.broadcastState.scrolledNonSelectedRecipients;
export const selectInitialSentForAllFlag = (state: RootState) => state.app.broadcastState.initialSentForAllFlag;
export const selectBroadcastRecipientCount = (state: RootState) => state.app.broadcastState.recipientCounter;
export const selectBroadcastRecipients = (state: RootState) => state.app.broadcastState.broadcast?.recipients;
export const selectBroadcastName = (state: RootState) => state.app.broadcastState.broadcast?.name;
export const selectBroadcastMessage = (state: RootState) => state.app.broadcastState.broadcast?.message;

export const selectBroadcastIsNew = (state: RootState) => state.app.broadcastState.titleStatus; // вместо isNew
export const selectBroadcastTitleValidation = (state: RootState) => state.app.broadcastState.titleInvalid;
export const selectBroadcastIsScheduled = (state: RootState) => state.app.broadcastState.broadcast?.isScheduled;
export const selectBroadcastScheduled = (state: RootState) => state.app.broadcastState.broadcast?.scheduledDate;
export const selectBroadcastSchedulerValidation = (state: RootState) => state.app.broadcastState.shedulerValidation;
export const selectBroadcastValidation = (state: RootState) => state.app.broadcastState.broadcastValidationState;
export const selectBroadcastIsInitialDisabledButtons = (state: RootState) => state.app.broadcastState.isInitialDisabledButtons;
export const selectShowBoradcastLeave = (state: RootState) => state.app.broadcastState.showLeaveBroadcastPopup;

export default broadcastSlice.reducer;
