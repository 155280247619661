import dayjs from "dayjs";
import { ComplexFilterFieldTypes } from "../../components/complexFilter/ComplexFilterModel";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CustomVariableType } from "../../components/modals/addVariable/AddVariableModel";
dayjs.extend(timezone);
dayjs.extend(utc);

//return strind ISO format
export const convertDateStringValueToISO = (dateValue: string) => {
  const date = new Date(dateValue);
  return dayjs(date).utc().format("YYYY-MM-DD HH:mm:ss");
};

//return Date
export const convertDateTimeToUTC = (date: Date | null) => {
  return new Date(dayjs(date).utc().format("YYYY-MM-DD HH:mm:ss"));
};

export const convertDateIsotoLocal = (date: string) => {
  if (date === null) {
    return undefined;
  }
  if (date?.length === 16 || date?.length === 19) {
    return dayjs(date).utc(true).local().tz(dayjs.tz.guess()).format("YYYY-MM-DD HH:mm");
  }
  return dayjs(date).utc(true).local().tz(dayjs.tz.guess()).format("YYYY-MM-DD");
};

export const formatDateTimeToString = (date: Date | null) => {
  if (date === null) {
    return null;
  }
  return dayjs(date.toString()).format("YYYY-MM-DD HH:mm:ss");
};

export const formatDateTimeToUTC = (date: string | null) => {
  if (date === null) {
    return null;
  }
  const variableValueView = new Date(date).toISOString();
  return variableValueView;
};

export const formatDateToUTCString = (date: Date | null) => {
  if (date === null) {
    return null;
  }

  return dayjs(date).utc().format("YYYY-MM-DD HH:mm:ss.sss");
};

export const formatDateTimeToView = (
  dateType: string | ComplexFilterFieldTypes | undefined | CustomVariableType,
  value: string | undefined,
  locale?: string,
) => {
  if (value === null || value === undefined) {
    return;
  }
  if (dateType === ComplexFilterFieldTypes.Date || dateType === "SystemDate" || dateType === CustomVariableType.Date) {
    return dayjs(value).format("DD.MM.YYYY");
  }

  if (
    dateType === ComplexFilterFieldTypes.DateTime ||
    dateType === "SystemDateTime" ||
    dateType === CustomVariableType.DateTime
  ) {
    return locale && locale === "en" ? dayjs(value).format("DD.MM.YYYY h:mm a") : dayjs(value).format("DD.MM.YYYY HH:mm");
  }

  return value;
};

export const formatDateToMonthDay = (date: string) => {
  const str = `${dayjs(date).format("MMM DD")}`;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatMessageDate = (date: Date | null) => {
  if (date) {
    const timeString = new Date(date).toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      hourCycle: "h23",
    });
    return timeString;
  }
  return null;
};

export const convertDays = (days: number) => {
  const lng = localStorage.getItem("i18nextLng");
  if (days % 30 === 0) {
    const months = Math.floor(days / 30);
    return lng === "ru"
      ? `${days}  месяц${days === 30 ? "" : days / 30 <= 4 ? "a" : ""}`
      : `${months} month${months === 1 ? "" : "s"}`;
  }
  if (days % 7 === 0) {
    const weeks = Math.floor(days / 7);
    return lng === "ru" ? `${days} недел${days === 7 ? "ю" : days <= 28 ? "и" : "ь"}` : `${weeks} week${weeks === 1 ? "" : "s"}`;
  } else {
    return lng === "ru" ? `${days} дней` : `${days} day${days === 1 ? "" : "s"}`;
  }
};

export const convertDaysLocalization = (days: number) => {
  const lng = localStorage.getItem("i18nextLng");
  return lng === "ru" ? `${days} д${days === 1 ? "ень" : days <= 4 ? "ня" : "ней"}` : `${days} day${days === 1 ? "" : "s"}`;
};

export function formatTime(date: Date, locale: string): string {
  return new Intl.DateTimeFormat(locale, { hour: "numeric", minute: "numeric", hour12: true }).format(date);
}

export function formatTimeString(
  dateType: string | ComplexFilterFieldTypes | undefined | CustomVariableType,
  value?: any,
  locale?: string,
) {
  if (dateType === ComplexFilterFieldTypes.Date || dateType === "SystemDate") {
    return dayjs(value).format("DD.MM.YYYY");
  }
  if (
    dateType === ComplexFilterFieldTypes.DateTime ||
    dateType === "SystemDateTime" ||
    dateType === CustomVariableType.DateTime
  ) {
    const dateTime = new Date(value);
    return locale && locale === "en"
      ? new Intl.DateTimeFormat(locale, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(dateTime)
      : dayjs(value).format("DD.MM.YYYY HH:mm");
  }
  return value;
}
