import { MenuItemProps, Box, SystemStyleObject } from "@chakra-ui/react";
import { ReactNode } from "react";
import s from "./CustomMenuItem.module.scss";

interface CustomMenuItemProps extends MenuItemProps {
  children: ReactNode;
  sx?: SystemStyleObject;
}

export const CustomMenuItem: React.FC<CustomMenuItemProps> = ({ children, sx, ...props }: CustomMenuItemProps) => {
  return (
    <Box className={s.item} {...props} sx={{ ...sx }}>
      {children}
    </Box>
  );
};
