export const FlowEvents = {
  FlowCreate: "FlowCreate",
  FlowCreateComleted: "FlowCreateComleted",
  FlowListLoaded: "FlowListLoaded",
  FlowImport: "FlowImport",
  FlowImportChooseFile: "FlowImportChooseFile",
  FlowImportConfirm: "FlowImportConfirm",
  FlowImportCompleted: "FlowImportCompleted",
  FlowImportCancelled: "FlowImportCancelled",
  FlowSearch: "FlowSearch",
  FlowСhange: "FlowСhange",
  FlowExport: "FlowExport",
  FlowCopy: "FlowCopy",
  FlowDeleted: "FlowDeleted",
  FlowCopyConfirmed: "FlowCopyConfirmed",
  FlowCopyCompleted: "FlowCopyCompleted",
  FlowCopyCanceled: "FlowCopyCanceled",
  FlowDeletedConfirmed: "FlowDeletedConfirmed",
  FlowDeletedCanceled: "FlowDeletedCanceled",
  FlowСhangeMain: "FlowСhangeMain",
  FlowTrigger: "FlowTrigger",
  FlowList: "FlowList",
  FlowTriggerActivate: "FlowTriggerActivate",
  FlowTriggerDeactivate: "FlowTriggerDeactivate",
  FlowTriggerUpload: "FlowTriggerUpload",
  FlowNameChangeFromList: "FlowNameChangeFromList",
  FlowNameChangeFromFlow: "FlowNameChangeFromFlow",
  FlowAddNodeFromMenu: "FlowAddNodeFromMenu",
  FlowAddNodeFromNode: "FlowAddNodeFromNode",
  FlowGoToStartClick: "FlowGoToStartClick",
};
