import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { BotListData, OrganisationSidebarData } from "./SidebarData";
import * as SidebarApi from "./SidebarApi";
import { selectBots, sidebarSlice } from "./SidebarSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { store } from "../..";
import { BotListModel, BotModel } from "./SidebarModel";
import { getBotData } from "../bot/BotApi";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";

export function* sidebarSaga() {
  yield takeLatest(sidebarSlice.actions.getBots, getBots);
  yield takeLatest(sidebarSlice.actions.getAllOrganisation, getAllOrganisations);
  yield takeLatest(sidebarSlice.actions.selectBot, selectBot);
}

function* getBots(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    yield put(beginScope("getBotsSidebar"));
    yield put(sidebarSlice.actions.setCurrentBotLoading(true));
    const botListdata: BotListData = yield call(SidebarApi.getBots, action.payload.page, action.payload.filter);
    //pagination
    const botList = store.getState().app.sidebarState.botList;
    if (botList?.items && action.payload.page > 1) {
      const updatedItems = botList.items.concat(botListdata.items);
      const model = { ...botListdata, items: updatedItems };
      yield put(sidebarSlice.actions.getBotsCompleted(model));
      return;
    }

    /*  records the current bot only at the first boot */
    const initialLoading = action.payload.filter === undefined;
    const botFromStorage = getBotFromStorage();
    if (initialLoading) {
      try {
        const botData: BotModel = yield call(getBotData, botFromStorage?.id);
        saveBotToStorage(botData);
        yield put(sidebarSlice.actions.selectBotCompleted(botData));
      } catch (err) {
        if (botListdata?.items.length > 0) {
          saveBotToStorage(botListdata?.items[0]);
          yield put(sidebarSlice.actions.selectBotCompleted(botListdata?.items[0]));
        } else {
          yield put(sidebarSlice.actions.selectBotCompleted(undefined));
        }
      }
    }

    yield put(sidebarSlice.actions.getBotsCompleted(botListdata));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getBotsSidebar"));
    yield put(sidebarSlice.actions.setCurrentBotLoading(false));
  }
}

function* getAllOrganisations() {
  try {
    const organisationListdata: OrganisationSidebarData[] = yield call(SidebarApi.getAllOrganisation);
    yield put(sidebarSlice.actions.getAllOrganisationCompleted(organisationListdata));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
  }
}

function* selectBot(action: PayloadAction<BotModel | string>) {
  try {
    if (typeof action.payload === "string") {
      const bots: BotListModel | undefined = yield select(selectBots);
      const bot = bots?.items.find(b => b.id === action.payload);
      if (bot) {
        saveBotToStorage(bot);
        yield put(sidebarSlice.actions.selectBotCompleted(bot));
      }
    } else {
      saveBotToStorage(action.payload);
      yield put(sidebarSlice.actions.selectBotCompleted(action.payload));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(sidebarSlice.actions.setCurrentBotLoading(false));
  }
}

export const getBotFromStorage = () => {
  const botSessionStorageJson = sessionStorage.getItem("CurrentBot");
  const botLocalStorageJson = localStorage.getItem("CurrentBot");
  if (botSessionStorageJson) {
    const bot = JSON.parse(botSessionStorageJson);
    return bot;
  } else if (botLocalStorageJson) {
    const bot = JSON.parse(botLocalStorageJson);
    sessionStorage.setItem("CurrentBot", JSON.stringify(bot));
    return bot;
  }
  return;
};

const saveBotToStorage = (bot: BotModel) => {
  sessionStorage.setItem("CurrentBot", JSON.stringify(bot));
  localStorage.setItem("CurrentBot", JSON.stringify(bot));
};

export const deleteBotFromStorage = () => {
  sessionStorage.removeItem("CurrentBot");
  localStorage.removeItem("CurrentBot");
};

export const getSideBarIsOpenFromStorage = () => {
  const isOpen = localStorage.getItem("sideBarIsOpen");
  if (isOpen === "false") {
    return false;
  }
  return true;
};

export const setSideBarIsOpenToStorage = (isOpen: boolean) => {
  localStorage.setItem("sideBarIsOpen", JSON.stringify(isOpen));
};
