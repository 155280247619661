import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Box } from "@chakra-ui/react";

interface TranslationWithStrongWordProps {
  i18nKey: string;
  children: ReactNode;
}

export const TranslationWithStrongWord: React.FC<TranslationWithStrongWordProps> = ({ i18nKey, children }) => (
  <Box>
    <Trans i18nKey={i18nKey}>{children}</Trans>
  </Box>
);
