import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import {
  flowBuilderSlice,
  selectCustomVariables,
  selectFlow,
  selectFlowList,
  selectIsAvaliableShoppingCartFile,
  selectNodeEditorState,
  selectOperators,
  selectTeams,
} from "./FlowBuilderSlice";
import {
  CalendarFlowActionModel,
  CurlFlowActionModel,
  CustomVariablesModel,
  FileContainerFlowAction,
  FlowActionType,
  FlowListModel,
  FlowLookModel,
  FlowModel,
  KeywordMatchType,
  NodeModel,
  NodeType,
  NodeValidation,
  OperatorsModel,
  PostFilePayload,
  QuestionFlowActionModel,
  ScheduleTriggerTypeDiscriminator,
  ShoppingCartFlowActionModel,
  SystemActionFlowActionModel,
  TakeItemFromArrayModel,
  TeamsModel,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "./FlowBuilderModel";
import * as FlowBuilderApi from "./FlowBuilderApi";
import * as AutomationApi from "../automation/AutomationApi";
import { store } from "../..";
import {
  CustomVariableDefinitionData,
  FileData,
  FlowData,
  FlowListData,
  NodeData,
  OperatorsData,
  StartFlowActionData,
  TeamsData,
} from "./FlowBuilderData";
import { mapFlowToData, mapFlowToModel, mapToFileInfoModel, mapToNodeModel, mapTriggersToData } from "./FlowBuilderMapper";
import { PayloadAction } from "@reduxjs/toolkit";
import { BadRequestError, NodeValidationError } from "../../common/ErrorModel";
import { handleException } from "../../common/SagaHelper";
import { routerSlice } from "../../common/router/RouterSlice";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { Node } from "reactflow";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { BotModel } from "../botList/BotListModel";
import { CustomVariableType } from "../../common/AppEnums";
import { ConditionsByField } from "../complexFilter/ComplexFilterModel";
import i18next from "i18next";
import { Locales } from "../../common/TelegramAuth/TelegramAuthModel";
import { automationSlice, selectImportResultsFlow } from "../automation/AutomationSlice";
import { ImportResultsFlowData, importStatusResultEnum } from "../automation/AutomationData";
import { ImportResultsFlowModel } from "../automation/AutomationModel";
import { convertDateIsotoLocal } from "../../common/utils/formatDate";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../common/ga/GAEventTracker";
import { FlowEvents } from "../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

const trackEvent = GTM(EventCategories.Flow);

export function* flowBuilderSaga() {
  yield takeLatest(flowBuilderSlice.actions.saveFlow, saveFlow);
  yield takeEvery(flowBuilderSlice.actions.postFile, postFile);
  yield takeLatest(flowBuilderSlice.actions.getFlow, getFlow);
  yield takeLatest(flowBuilderSlice.actions.getFlowList, getFlowList);
  yield takeLatest(flowBuilderSlice.actions.getTeams, getTeams);
  yield takeLatest(flowBuilderSlice.actions.getOperators, getOperators);
  yield takeEvery(flowBuilderSlice.actions.saveNode, saveNode);
  yield takeEvery(flowBuilderSlice.actions.editNode, editNode);
  yield takeLatest(flowBuilderSlice.actions.createCustomVariable, createCustomVariable);
  yield takeLatest(flowBuilderSlice.actions.setTrigger, setTrigger);
  yield takeLatest(flowBuilderSlice.actions.validateTrigger, validateTrigger);
  yield takeEvery(flowBuilderSlice.actions.setEdges, saveDraftFlow);
  yield takeEvery(flowBuilderSlice.actions.discardFlow, discardFlow);
  yield takeLatest(flowBuilderSlice.actions.createFlow, createFlow);
  yield takeLatest(flowBuilderSlice.actions.createImportFlow, createImportFlow);
  yield takeLatest(flowBuilderSlice.actions.createImportFlowTemplate, createImportFlowTemplate);
  yield takeLatest(flowBuilderSlice.actions.setFlowTitle, saveDraftFlow);
  yield takeLatest(flowBuilderSlice.actions.changeNodePosition, saveDraftFlow);
  yield takeLatest(flowBuilderSlice.actions.loadYamlFile, loadYamlFile);
  yield takeLatest(flowBuilderSlice.actions.getDraftYamlFile, getDraftYamlFile);
  yield takeLatest(flowBuilderSlice.actions.publishYamlFile, publishYamlFile);
}

function* createFlow(action: PayloadAction<{ botId: string; flowId: string; newFlowId?: string }>) {
  try {
    yield put(begin());
    yield getFlowInfo(action.payload.botId, action.payload.flowId, action.payload.newFlowId);
    trackEvent(FlowEvents.FlowCreateComleted);
    yield updateDraft(action.payload.flowId);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* loadYamlFile(action: PayloadAction<{ file: File | null; addFile: (file: string) => void }>) {
  try {
    yield put(beginScope("loadYamlFile"));
    yield call(FlowBuilderApi.loadYamlFile, action.payload.file);
    yield put(flowBuilderSlice.actions.getDraftYamlFile());
    yield action.payload.addFile(action.payload.file?.name || "");
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield put(
      notificationSlice.actions.notify({
        message: "Something went wrong. Check the file",
        type: "error",
      }),
    );
  } finally {
    yield put(completeScope("loadYamlFile"));
  }
}
function* getDraftYamlFile() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = yield call(FlowBuilderApi.getDraftYamlFile);
    yield put(flowBuilderSlice.actions.getDraftYamlFileCompleted(Boolean(data.length)));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* publishYamlFile() {
  try {
    yield call(FlowBuilderApi.publishYamlFile);
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* createImportFlow(action: PayloadAction<{ file?: File | null; botId: string; submitWarnings?: boolean }>) {
  try {
    yield put(begin());
    yield put(beginScope("importFlow"));
    let response: ImportResultsFlowData;
    let model: ImportResultsFlowModel;
    if (action.payload.file) {
      response = yield call(AutomationApi.importFlow, action.payload.botId, action.payload.file, action.payload.submitWarnings);
      model = { ...response, flow: response.flow ? mapFlowToModel(response.flow) : undefined };
      yield put(automationSlice.actions.setImportResultsFlow({ importResults: model }));
    } else {
      model = yield select(selectImportResultsFlow);
    }

    if (!model) return;

    if (model.status === importStatusResultEnum.Success) {
      const flow: FlowModel = {
        ...model.flow,
        botId: action.payload.botId,
        id: "import",
        newFlowId: model.flow?.id,
        nodes: model.flow?.nodes ?? [],
        edges: model.flow?.edges ?? [],
        triggers: model.flow?.triggers ?? [],
      };
      yield getFlowInfo(flow.botId, flow.id, flow.newFlowId);
      yield updateDraft(flow.id);
      yield loadNodes(flow);
      yield put(flowBuilderSlice.actions.transferFlowCompleted(flow));
      if (action.payload.file) {
        yield put(automationSlice.actions.addNewFlow("import"));
        trackEvent(FlowEvents.FlowImportCompleted);
      }
      yield call(saveDraftFlowInternal, flow, getDraftFlowId(flow.id));
    }
  } catch (e: unknown) {
    yield handleException(e);
    trackEvent(FlowEvents.FlowImportCancelled);
  } finally {
    yield put(completeScope("importFlow"));
    yield put(complete());
  }
}

function* createImportFlowTemplate(action: PayloadAction<{ botId: string; flowTemplateId: string }>) {
  try {
    yield put(begin());
    yield put(beginScope("importFlow"));

    const response: ImportResultsFlowData = yield call(
      AutomationApi.importFlowTemplate,
      action.payload.botId,
      action.payload.flowTemplateId,
    );
    const model: ImportResultsFlowModel = {
      ...response,
      flow: response.flow ? mapFlowToModel(response.flow) : undefined,
    };
    yield put(automationSlice.actions.setImportResultsFlow({ importResults: model }));

    if (model.status === importStatusResultEnum.Success) {
      const flow: FlowModel = {
        ...model.flow,
        botId: action.payload.botId,
        id: "import",
        newFlowId: model.flow?.id,
        nodes: model.flow?.nodes ?? [],
        edges: model.flow?.edges ?? [],
        triggers: model.flow?.triggers ?? [],
      };
      yield getFlowInfo(flow.botId, flow.id, flow.newFlowId);
      yield updateDraft(flow.id);
      yield loadNodes(flow);
      yield put(flowBuilderSlice.actions.transferFlowCompleted(flow));
      yield put(automationSlice.actions.addNewFlow("import"));
      yield call(saveDraftFlowInternal, flow, getDraftFlowId(flow.id));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("importFlow"));
    yield put(complete());
  }
}

function* saveNode(action: PayloadAction<{ flow: FlowModel; node: NodeModel }>) {
  try {
    yield put(beginScope("nodeValidate"));
    if (action.payload.node.type === NodeType.Trigger) {
      action.payload.flow.triggers.forEach(trigger => validateTriggerNode(trigger));
    }
    const nodes = action.payload.flow.nodes;
    const newNodes = nodes?.map(el => {
      if (el.id === action.payload.node.id) {
        return {
          ...el,
          data: action.payload.node.flowAction,
        };
      }
      return el;
    });
    yield put(flowBuilderSlice.actions.editNodeCompleted());
    yield put(
      flowBuilderSlice.actions.saveNodeCompleted({
        flow: action.payload.flow,
        nodes: newNodes,
      }),
    );

    yield call(saveDraftFlow);
  } catch (e: unknown) {
    if (e instanceof NodeValidationError) {
      yield put(flowBuilderSlice.actions.setNodeValidation(e.validationData));
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(completeScope("nodeValidate"));
  }
}

function* saveFlow(action: PayloadAction<FlowModel>) {
  try {
    yield put(beginScope("flowBuilder"));
    const flow = action.payload;
    const vars = store.getState().app.flowBuilderState.customVariables;
    const bodyObject = mapFlowToData(flow, vars ?? []);
    const shoppingCartNode = bodyObject.nodes.find(
      el => el.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction,
    );
    if (shoppingCartNode) {
      const isAvaliableShoppingCartFile: boolean | undefined = yield select(selectIsAvaliableShoppingCartFile);
      if (!isAvaliableShoppingCartFile) {
        throw new BadRequestError("Download the catalog", flow.id, shoppingCartNode.id);
      }
    }
    const newFlow = flow.newFlowId;
    const api = !newFlow ? FlowBuilderApi.editFlow : FlowBuilderApi.createFlow;
    const locale = Locales[i18next.language as keyof typeof Locales];
    const data: FlowData = yield call(api, !newFlow ? bodyObject : { ...bodyObject, locale });
    if (shoppingCartNode) {
      yield call(FlowBuilderApi.publishYamlFile);
    }
    yield put(flowBuilderSlice.actions.saveFlowCompleted(flow));
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
    const draftFlowId = getDraftFlowId(flow.id);
    yield call(removeDraftFlowInternal, draftFlowId);
    yield put(flowBuilderSlice.actions.updateDraftFlowState());
    if (newFlow) {
      yield put(routerSlice.actions.redirect("/automation/flows/" + data.id));
    }
  } catch (e: unknown) {
    //remove menu when save flow
    yield put(flowBuilderSlice.actions.saveFlowCompleted(action.payload));
    const nodeValidation = store.getState().app.flowBuilderState.nodeValidationState;
    const error = e as BadRequestError;
    const validation: NodeValidation = {
      nodeId: error.nodeId,
      flowId: error.flowId,
      errors: [{ message: error.message, field: "error" }],
    };
    const flowIssue = !!error.flowId;
    if (flowIssue) {
      if ((!nodeValidation.errors.length || nodeValidation.flowId !== error.flowId) && error.nodeId) {
        yield put(flowBuilderSlice.actions.setNodeValidation(validation));
        yield handleException(e);
      } else {
        yield handleException(e);
      }
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(completeScope("flowBuilder"));
  }
}

function* postFile(action: PayloadAction<PostFilePayload>) {
  try {
    const editNodeModel: NodeModel | undefined = yield select(selectNodeEditorState);
    const editNodeModelId = editNodeModel?.id;
    if (editNodeModelId) {
      yield put(beginScope(`postFile${action.payload.nodeId}`));
      yield put(beginScope(`postFileEditor${action.payload.nodeId}`));
      yield put(
        flowBuilderSlice.actions.editNode({
          ...editNodeModel,
          flowAction: {
            ...editNodeModel.flowAction,
            fileId: undefined,
            file: null,
            fileInfo: undefined,
          } as FileContainerFlowAction,
        }),
      );

      yield put(flowBuilderSlice.actions.setNodeValidation({ errors: [] }));

      const data: FileData = yield call(FlowBuilderApi.postFile, action.payload);
      const fileInfo: FileData = yield call(FlowBuilderApi.getFile, data.id);
      const flow: FlowModel = yield select(selectFlow);
      const node = flow.nodes.find(node => node.id === editNodeModelId);
      const currentEditNodeModel: NodeModel | undefined = yield select(selectNodeEditorState);
      if (flow && node) {
        const nodeModel = mapToNodeModel(node);
        const newNodeModel = {
          ...nodeModel,
          flowAction: {
            ...nodeModel.flowAction,
            fileId: fileInfo.id,
            file: null,
            fileInfo,
          } as FileContainerFlowAction,
        };
        const nodes: Node[] = flow.nodes.map(x => {
          if (x.id === node.id) {
            return {
              ...newNodeModel,
              data: newNodeModel.flowAction,
            };
          }
          return x;
        });
        if (currentEditNodeModel?.id === node.id) {
          yield put(flowBuilderSlice.actions.editNode(newNodeModel));
        } else {
          yield put(flowBuilderSlice.actions.setNodes(nodes));
        }
      }
    }
  } catch (e: unknown) {
    if (e instanceof BadRequestError) {
      const error: NodeValidation = {
        nodeId: action.payload.nodeId,
        errors: [{ message: e.message, field: "error" }],
      };
      yield put(flowBuilderSlice.actions.setNodeValidation(error));
    }
    yield handleException(e);
  } finally {
    yield put(completeScope(`postFile${action.payload.nodeId}`));
    yield put(completeScope(`postFileEditor${action.payload.nodeId}`));
  }
}

function* getFlow(action: PayloadAction<string>) {
  try {
    yield put(beginScope("flowBuilder"));
    yield put(begin());
    yield put(flowBuilderSlice.actions.updateDraftFlowState());
    const draftFlowId = getDraftFlowId(action.payload);
    let model: FlowModel = yield call(getDraftFlowInternal, draftFlowId);

    if (!model && action.payload === "import") return;

    if (!model) {
      const flowData: FlowData = yield call(FlowBuilderApi.getFlow, action.payload);
      yield getFlowInfo(flowData.botId, action.payload, flowData.id);
      const customVar: CustomVariablesModel[] = yield select(selectCustomVariables);
      const shoppingCartNode = flowData.nodes.find(
        el => el.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction,
      );
      if (shoppingCartNode) {
        yield put(flowBuilderSlice.actions.getDraftYamlFile());
      }
      /* a crutch invented to get the name of the script */
      const newNodes: NodeData[] = yield call(getFlowLookup, flowData);
      const newFlowData: FlowData = { ...flowData, nodes: newNodes };
      model = mapFlowToModel(newFlowData, customVar);
    } else {
      const shoppingCartNode = model.nodes.find(el => {
        const node = el as NodeModel;
        return node.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction;
      });
      if (shoppingCartNode) {
        yield put(flowBuilderSlice.actions.getDraftYamlFile());
      }
      yield put(flowBuilderSlice.actions.updateDraftFlowState(draftFlowId));
    }
    yield getFlowInfo(model.botId, action.payload, model.newFlowId);
    const flowBot: BotModel | undefined = store.getState().app.sidebarState.botList?.items.find(el => el.id === model.botId);
    yield loadNodes(model);
    if (flowBot) {
      yield put(sidebarSlice.actions.selectBot(flowBot));
    }
    const customVariables: CustomVariablesModel[] = yield select(selectCustomVariables);
    const updateTriggers = mapTriggersToData(model, customVariables, convertDateIsotoLocal);

    yield put(flowBuilderSlice.actions.getFlowCompleted({ ...model, triggers: updateTriggers }));
    yield put(flowBuilderSlice.actions.getFlowList({ botId: model.botId, page: 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("flowBuilder"));
    yield put(complete());
  }
}

export function* getFlowList(action: PayloadAction<{ botId: string; page: number; filter?: string }>) {
  try {
    yield put(beginScope("flowList"));
    const currentFlow: FlowModel | undefined = yield select(selectFlow);
    const prevFlowList: FlowListModel = yield select(selectFlowList);
    const flowListData: FlowListData = yield call(
      FlowBuilderApi.getFlowList,
      action.payload.botId,
      action.payload.page,
      action.payload.filter,
    );

    const flowListItems = flowListData.items.map(el => {
      return { id: el.id, title: el.title, botId: el.botId };
    });
    const prevFlowListItems = prevFlowList?.items.map(el => el) ?? [];
    const concatedItems = action.payload.page > 1 ? prevFlowListItems.concat(flowListItems) : flowListItems;
    const excludedCurrentFlowItems = concatedItems.filter(el => el.id !== currentFlow?.id);

    const flowListModel = { ...flowListData, items: excludedCurrentFlowItems };
    yield put(flowBuilderSlice.actions.getFlowListCompleted(flowListModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("flowList"));
  }
}

export function* getOperators(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    const prevOperatorList: OperatorsModel = yield select(selectOperators);
    const operatorsData: OperatorsData = yield call(FlowBuilderApi.getOperators, action.payload.page, action.payload.filter);

    const operatorItems = operatorsData.items.map(el => {
      return { id: el.userId, name: el.username };
    });
    const prevOperatorItems = prevOperatorList?.items.map(el => el) ?? [];
    const concatedItems = action.payload.page > 1 ? prevOperatorItems.concat(operatorItems) : operatorItems;

    const operatorsModel = { ...operatorsData, items: concatedItems };
    yield put(flowBuilderSlice.actions.getOperatorsCompleted(operatorsModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getTeams(action: PayloadAction<{ page: number; filter?: string }>) {
  try {
    const prevTeamList: TeamsModel = yield select(selectTeams);
    const organisationTeamsData: TeamsData = yield call(FlowBuilderApi.getTeams, action.payload.page, action.payload.filter);
    const teamsItems = organisationTeamsData.items.map(el => el);
    const prevTeamItems = prevTeamList?.items.map(el => el) ?? [];
    const concatedItems = action.payload.page > 1 ? prevTeamItems.concat(teamsItems) : teamsItems;

    const teamsModel = { ...organisationTeamsData, items: concatedItems };

    yield put(flowBuilderSlice.actions.getTeamsCompleted(teamsModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* saveDraftFlow() {
  try {
    yield put(begin());
    const flowModel = store.getState().app.flowBuilderState.flow;
    if (flowModel) {
      const draftFlowId = getDraftFlowId(flowModel.id);
      yield call(saveDraftFlowInternal, flowModel, draftFlowId);
      yield put(flowBuilderSlice.actions.updateDraftFlowState(draftFlowId));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* discardFlow(action: PayloadAction<string>) {
  try {
    yield put(begin());
    const draftFlowId = getDraftFlowId(action.payload);
    yield call(removeDraftFlowInternal, draftFlowId);
    yield put(flowBuilderSlice.actions.updateDraftFlowState());
    const flowModel = store.getState().app.flowBuilderState.flow;
    const newFlowId = flowModel?.newFlowId;
    if (flowModel?.id === "new" && flowModel?.botId) {
      yield put(
        flowBuilderSlice.actions.createFlow({
          botId: flowModel.botId,
          flowId: flowModel.id,
          newFlowId: newFlowId,
        }),
      );
    } else if (flowModel?.id === "import" && flowModel?.botId) {
      yield put(
        flowBuilderSlice.actions.createImportFlow({
          botId: flowModel.botId,
        }),
      );
    } else {
      yield call(getFlow, action);
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* editNode(action: PayloadAction<NodeModel>) {
  try {
    const flow = store.getState().app.flowBuilderState.flow;
    if (!flow) {
      return;
    }
    const nodes: Node[] = flow.nodes.map(x => {
      if (x.id === action.payload.id) {
        return {
          ...action.payload,
          data: action.payload.flowAction,
        };
      }
      return x;
    });
    yield put(flowBuilderSlice.actions.setNodes(nodes));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* createCustomVariable(action: PayloadAction<{ variable: CustomVariablesModel; nodeEditorInfo?: unknown }>) {
  try {
    yield put(begin());
    const flowId: string = store.getState().app.flowBuilderState.flow?.id ?? "";
    const newFlowId: string | undefined = store.getState().app.flowBuilderState.flow?.newFlowId;
    const botId: string = store.getState().app.flowBuilderState.flow?.botId ?? "";
    const nodeEditorState = store.getState().app.flowBuilderState.nodeEditor;
    const createdCustomVariable: CustomVariablesModel = yield call(FlowBuilderApi.createCustomVariable, action.payload.variable);
    const cusVarData: CustomVariableDefinitionData[] = yield call(getCustomVariables, {
      botId,
      flowId: newFlowId ?? flowId,
    });

    const cusVarModel: CustomVariablesModel[] = [...cusVarData];

    yield put(flowBuilderSlice.actions.getCustomVariablesCompleted(cusVarModel));
    yield put(flowBuilderSlice.actions.setNodeEvent(null));
    const editNodeQuestion = action.payload.nodeEditorInfo as {
      createCustomMediaVeriable: boolean;
    };

    if (nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.QuestionFlowAction) {
      if (editNodeQuestion.createCustomMediaVeriable) {
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              answerMediaVariableId: createdCustomVariable.id,
            } as QuestionFlowActionModel,
          }),
        );
      } else {
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              targetCustomVariableId: createdCustomVariable.id,
            } as QuestionFlowActionModel,
          }),
        );
      }
    }
    if (nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.SendAppointmentFlowAction) {
      yield put(
        flowBuilderSlice.actions.editNode({
          ...nodeEditorState,
          flowAction: {
            ...nodeEditorState.flowAction,
            targetCustomVariableId: createdCustomVariable.id,
          } as CalendarFlowActionModel,
        }),
      );
    }
    if (nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction) {
      yield put(
        flowBuilderSlice.actions.editNode({
          ...nodeEditorState,
          flowAction: {
            ...nodeEditorState.flowAction,
            targetCustomVariableId: createdCustomVariable.id,
          } as ShoppingCartFlowActionModel,
        }),
      );
    }

    if (nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.CurlFlowAction) {
      const index = action.payload.nodeEditorInfo as number;
      const flowAction = nodeEditorState.flowAction as CurlFlowActionModel;
      const targetCustomVariables = flowAction.targetCustomVariables?.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            targetCustomVariableId: createdCustomVariable.id,
          };
        }
        return el;
      });
      yield put(
        flowBuilderSlice.actions.editNode({
          ...nodeEditorState,
          flowAction: {
            ...nodeEditorState.flowAction,
            targetCustomVariables,
          } as CurlFlowActionModel,
        }),
      );
    }

    if (
      nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.SetVariableValueFlowAction ||
      nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.PutItemIntoArrayFlowAction
    ) {
      const nodeEditorInfo = action.payload.nodeEditorInfo as {
        sourceOrTarget: "targetCustomVariableId" | "sourceCustomVariableId";
        variableType: CustomVariableType;
      };
      const isSource = nodeEditorInfo.sourceOrTarget === "sourceCustomVariableId";
      const isTarget = nodeEditorInfo.sourceOrTarget === "targetCustomVariableId";
      if (isSource && (!nodeEditorInfo.variableType || createdCustomVariable.type === nodeEditorInfo.variableType)) {
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              sourceCustomVariableId: createdCustomVariable.id,
            } as SystemActionFlowActionModel,
          }),
        );
      } else if (isTarget && (!nodeEditorInfo.variableType || createdCustomVariable.type === nodeEditorInfo.variableType)) {
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              targetCustomVariableId: createdCustomVariable.id,
            } as SystemActionFlowActionModel,
          }),
        );
      }
    }

    if (nodeEditorState?.flowAction?.typeDiscriminator === FlowActionType.TakeItemFromArrayFlowAction) {
      if (typeof action.payload.nodeEditorInfo === "number") {
        const index = action.payload.nodeEditorInfo as number;
        const flowAction = nodeEditorState.flowAction as TakeItemFromArrayModel;
        const targetCustomVariables = flowAction.targetCustomVariables?.map((el, i) => {
          if (i === index) {
            return {
              ...el,
              targetCustomVariableId: createdCustomVariable.id,
            };
          }
          return el;
        });
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              targetCustomVariables,
            } as TakeItemFromArrayModel,
          }),
        );
      } else {
        yield put(
          flowBuilderSlice.actions.editNode({
            ...nodeEditorState,
            flowAction: {
              ...nodeEditorState.flowAction,
              sourceCustomVariableId: createdCustomVariable.id,
            } as SystemActionFlowActionModel,
          }),
        );
      }
    }
    const nodeEditorInfo = action.payload.nodeEditorInfo as
      | {
          sourceOrTarget: "targetCustomVariableId" | "sourceCustomVariableId";
          setButtonField: (
            sourceOrTarget: "targetCustomVariableId" | "sourceCustomVariableId",
            createdCustomVariable: CustomVariablesModel,
          ) => void;
        }
      | undefined;
    if (nodeEditorInfo?.setButtonField && nodeEditorInfo?.sourceOrTarget) {
      nodeEditorInfo.setButtonField(nodeEditorInfo?.sourceOrTarget, createdCustomVariable);
    }

    if (nodeEditorState?.type === NodeType.Trigger) {
      let nodeEditor = nodeEditorState as TriggerNodeModel;
      nodeEditor = { ...nodeEditor, newVariableId: createdCustomVariable.id };
      yield put(flowBuilderSlice.actions.editNode(nodeEditor));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* setTrigger(action: PayloadAction<{ triggers: TriggerModel[]; trigger: TriggerModel }>) {
  try {
    yield put(begin());
    action.payload.trigger.command?.trim();
    validateTriggerNode(action.payload.trigger);

    yield put(
      notificationSlice.actions.notify({
        message: "Applied successfully!",
        type: "success",
      }),
    );
    yield put(flowBuilderSlice.actions.setNodeValidation({ errors: [] }));

    const triggers = action.payload.triggers;
    // update if exists
    let newTriggers = triggers.map(el => {
      if (el.id === action.payload.trigger.id) {
        return {
          ...action.payload.trigger,
        };
      }
      return el;
    });

    // add if new
    if (!newTriggers.find(el => el.id === action.payload.trigger.id)) {
      newTriggers = [...newTriggers, action.payload.trigger];
    }

    yield put(flowBuilderSlice.actions.setTriggerCompleted(newTriggers));
  } catch (e: unknown) {
    if (e instanceof NodeValidationError) {
      yield put(flowBuilderSlice.actions.setNodeValidation(e.validationData));
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(complete());
  }
}

function* validateTrigger(action: PayloadAction<TriggerModel>) {
  try {
    yield put(begin());
    yield put(flowBuilderSlice.actions.setNodeValidation({ errors: [] }));
    validateTriggerNode(action.payload);
  } catch (e: unknown) {
    if (e instanceof NodeValidationError) {
      yield put(flowBuilderSlice.actions.setNodeValidation(e.validationData));
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(complete());
  }
}

const getDraftFlowId = (flowId: string) => {
  return `flowData-${flowId}`;
};

const validateTriggerNode = (trigger: TriggerModel) => {
  const result: NodeValidation = {
    errors: [],
  };

  switch (trigger.typeDiscriminator) {
    case TriggerTypeDiscriminator.KeywordMatch: {
      if (trigger.keywordMatchType === KeywordMatchType.Any) break;
      if (!trigger.keywords?.length) {
        result.errors.push({
          message: "Please enter keyword",
          field: `keywordTrigger`,
        });
      }
      break;
    }

    case TriggerTypeDiscriminator.ButtonClick: {
      if (!trigger.buttonIds?.length) {
        result.errors.push({
          message: "Please enter button payload",
          field: `buttonTrigger`,
        });
      }
      break;
    }

    case TriggerTypeDiscriminator.CommandMatch: {
      if (!trigger.command) {
        result.errors.push({
          message: "Please enter command",
          field: `commandTrigger`,
        });
      }
      if (trigger.command?.split("").find(element => element === " ")) {
        result.errors.push({
          message: "Command can't contain spaces",
          field: `commandTrigger`,
        });
      }
      if (trigger.command?.includes("/")) {
        result.errors.push({
          message: "Please remove additional slash symbol in command input",
          field: `commandTrigger`,
        });
      }
      break;
    }
    case TriggerTypeDiscriminator.Schedule: {
      const schedule = trigger.scheduler;
      const isConditionError = trigger?.conditions?.find(
        el =>
          !el.conditionCustomVariableId ||
          !el.condition ||
          (!el.value && !ConditionsByField.noValueConditions.includes(el.condition)),
      );
      if (isConditionError) {
        result.errors.push({
          message: "All conditions must be specified",
          field: `scheduleTrigger`,
        });
      }
      if (!schedule?.scheduledTime && schedule?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.once) {
        result.errors.push({
          message: "Please enter schedule time",
          field: `scheduleTrigger`,
        });
      }
      if (!schedule?.startTime && schedule?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur) {
        result.errors.push({
          message: "Please enter start time",
          field: `scheduleTrigger`,
        });
      }
      if (schedule?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur && !schedule?.value) {
        result.errors.push({
          message: "Please enter interval",
          field: `scheduleTrigger`,
        });
      }
      if (schedule?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur && !schedule.endTime) {
        result.errors.push({
          message: "Please enter end time",
          field: `scheduleTrigger`,
        });
      }
      if (
        schedule?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur &&
        schedule.startTime &&
        schedule.endTime &&
        new Date(schedule.endTime) <= new Date(schedule.startTime)
      ) {
        result.errors.push({
          message: "End time is less than start time",
          field: `scheduleTrigger`,
        });
      }
      break;
    }
  }
  if (result.errors.length) {
    throw new NodeValidationError(result);
  }
};

const saveDraftFlowInternal = (model: FlowModel, draftFlowId: string) => {
  localStorage.setItem(draftFlowId, JSON.stringify(model));
};

const getDraftFlowInternal = (draftFlowId: string) => {
  const flowJson = localStorage.getItem(draftFlowId);
  return flowJson ? JSON.parse(flowJson) : undefined;
};

const removeDraftFlowInternal = (draftFlowId: string) => {
  localStorage.removeItem(draftFlowId);
};

function* loadNodes(flow: FlowModel) {
  let documentNodes = flow.nodes.filter(
    x =>
      x.data &&
      (x.data.typeDiscriminator === FlowActionType.SendDocumentFlowAction ||
        x.data.typeDiscriminator === FlowActionType.SendVideoFlowAction ||
        x.data.typeDiscriminator === FlowActionType.SendAudioFlowAction),
  );
  const payloads = documentNodes.map(x => {
    return { nodeId: x.id, fileId: x.data.fileId };
  });

  if (payloads.every(el => el.fileId)) {
    const results: FileData[] = yield all(payloads.map(x => call(FlowBuilderApi.getFile, x.fileId)));

    const updDocumentNodes = documentNodes.map(el => {
      const nodeResult = results.find(x => x.id === el.data.fileId);
      if (!nodeResult) {
        return el;
      } else {
        const elData = { ...el.data, fileInfo: mapToFileInfoModel(nodeResult) };
        return { ...el, data: elData };
      }
    });

    documentNodes = updDocumentNodes;
    const newNodes = flow.nodes.map(el => {
      if (documentNodes.find(x => x.id === el.id)) {
        return documentNodes.find(x => x.id === el.id) ?? el;
      }
      return el;
    });
    flow.nodes = newNodes;
  }
}

function* getFlowInfo(botId: string, flowId: string, newFlowId?: string) {
  try {
    const cusVarData: CustomVariableDefinitionData[] = yield call(getCustomVariables, {
      botId: botId,
      flowId: newFlowId ?? flowId,
    });
    const cusVarModel: CustomVariablesModel[] = [...cusVarData];
    yield put(flowBuilderSlice.actions.getTeams({ page: 1 }));
    yield put(flowBuilderSlice.actions.getOperators({ page: 1 }));
    yield put(flowBuilderSlice.actions.getFlowList({ botId: botId, page: 1 }));
    yield put(flowBuilderSlice.actions.getCustomVariablesCompleted(cusVarModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getFlowLookup(flowData: FlowData) {
  const newNodes: NodeData[] = [];
  for (let i = 0; i < flowData.nodes.length; i++) {
    switch (flowData.nodes[i].flowAction?.typeDiscriminator) {
      case FlowActionType.StartSubFlowAction:
        {
          let temp = flowData.nodes[i].flowAction as StartFlowActionData;
          const modal: FlowLookModel = yield call(FlowBuilderApi.getFlowTitle, temp.flowId);
          temp = { ...temp, title: modal.title };
          newNodes.push({ ...flowData.nodes[i], flowAction: temp });
        }
        break;
      default:
        newNodes.push(flowData.nodes[i]);
        break;
    }
  }
  return newNodes;
}

function* updateDraft(flowId: string) {
  try {
    const draftFlowId = getDraftFlowId(flowId);
    const draftFlowModel: FlowModel = yield call(getDraftFlowInternal, draftFlowId);
    if (draftFlowModel) {
      yield put(flowBuilderSlice.actions.updateDraftFlowState(draftFlowId));
      yield loadNodes(draftFlowModel);
      yield put(flowBuilderSlice.actions.getFlowCompleted(draftFlowModel));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getCustomVariables(info: { botId: string; flowId: string }) {
  const flowVariables: CustomVariableDefinitionData[] = yield call(FlowBuilderApi.getFlowCustomVariables, info.flowId);
  const botVariables: CustomVariableDefinitionData[] = yield call(FlowBuilderApi.getBotCustomVariables, info.botId);
  const contactVariables: CustomVariableDefinitionData[] = yield call(FlowBuilderApi.getContactCustomVariables);
  const systemVariables: CustomVariableDefinitionData[] = yield call(FlowBuilderApi.getSystemVariables);
  return systemVariables.concat(contactVariables, botVariables, flowVariables);
}
