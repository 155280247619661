import { Box, Flex } from "@chakra-ui/react";
import { FlowListContainer } from "../../../../automation/components/FlowListContainer";

export const FlowPage = () => {
  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <Box bg="bgLight" height="100%">
        <FlowListContainer />
      </Box>
    </Flex>
  );
};
