import { CustomVariableModel } from "../../../../../../../../common/AppEnums";
import { AppSettings } from "../../../../../../../../common/AppSettings";
import {
  ConversationInfoData,
  ConversationSendMessageData,
  MessageData,
  MessageTypeDiscriminator,
  OutgoingTypeDiscriminator,
} from "../../../../../../../conversation/ConversationData";
import {
  ConversationInfoModel,
  ConversationMessageModel,
  LastMessageModel,
  MessageModel,
} from "../../../../../../../conversation/ConversationModel";
import { CustomVariablesModel } from "../../../../../../../flowBuilder/FlowBuilderModel";

declare const appSettings: AppSettings;

export const mapConversationInfoDataToConversationInfoModel = (data: ConversationInfoData): ConversationInfoModel => {
  return {
    ...data,
    contact: data.contact
      ? {
          ...data.contact,
          fullName: `${data.contact.firstName} ${data.contact.lastName}`.trim(),
        }
      : undefined,
  };
};

export const mapMessageToArray = (
  message: ConversationSendMessageData,
  customVariables: CustomVariablesModel[] | undefined,
): ConversationSendMessageData[] => {
  const result: ConversationSendMessageData[] = [];

  switch (message.typeDiscriminator) {
    case OutgoingTypeDiscriminator.OutgoingTextMessage: {
      return [
        {
          ...message,
          ...(customVariables && {
            customVariableIds: getTextVariableIds(message.text, customVariables),
          }),
        },
      ];
    }
    case OutgoingTypeDiscriminator.NoteTextConversation: {
      return [
        {
          ...message,
          ...(customVariables && {
            customVariableIds: getTextVariableIds(message.text, customVariables),
          }),
        },
      ];
    }
    case OutgoingTypeDiscriminator.OutgoingDocumentMessage:
    case OutgoingTypeDiscriminator.OutgoingPhotoMessage: {
      return [
        {
          ...message,
          ...(customVariables && {
            customVariableIds: getTextVariableIds(message.text, customVariables),
          }),
        },
      ];

      break;
    }
  }

  return result;
};

export const getTextVariableIds = (text: string, vars: CustomVariableModel[]): string[] => {
  const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
  const variables = text.match(regex);
  if (!variables) {
    return [];
  }

  const variableIds = variables
    .map(el => {
      const scopeAndKey = el.replace(regex, "$1:$2");
      const [scope, key] = scopeAndKey.split(":");
      const customVariable = vars.find(el => key && el.key === key && el.scope === scope);
      return customVariable?.id ?? "";
    })
    .filter(x => x.length > 0);
  return variableIds;
};

export const mapMessageToModel = (data: MessageData): MessageModel => {
  switch (data.typeDiscriminator) {
    case MessageTypeDiscriminator.SystemMessage: {
      return data;
    }
    case MessageTypeDiscriminator.NoteTextConversation: {
      return data;
    }
    case MessageTypeDiscriminator.Message:
    default: {
      const result: ConversationMessageModel = {
        ...data,
        photos: data.photos.map(p => {
          return {
            ...p,
            url: p.fileId != null ? makeFileUrl(p.fileId) : "/noimage",
          };
        }),
      };
      return result;
    }
  }
};

export const mapMessageModelToLastMessage = (message?: ConversationMessageModel) => {
  if (!message) {
    return;
  }
  const lastMessage: LastMessageModel = {
    text: message.text,
    date: message.date.toString(),
    isPhoto: !!message.photos[0],
    isDocument: !!message.documents[0],
    isContact: !!message.contact,
  };
  return lastMessage;
};

const makeFileUrl = (fileId: string) => {
  return `${appSettings.apiBaseUrl}/file/${fileId}`;
};

export const mapMessagesToSortMessages = (data: MessageModel[]): MessageModel[] => {
  const sortData = data.sort(compareNumeric);
  return sortData;
};

function compareNumeric(a: MessageModel, b: MessageModel) {
  if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
  if (new Date(a.date).getTime() === new Date(b.date).getTime()) {
    if (Number(a.externalId) > Number(b.externalId)) return 1;
    if (Number(a.externalId) < Number(b.externalId)) return -1;
    if (Number(a.externalId) === Number(b.externalId)) return 0;
  }
  if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
  return 0;
}
