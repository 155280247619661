export const BotAddEvents = {
  BotCreate: "BotCreate",
  BotNewCreate: "BotNewCreate",
  BotCreateComleted: "BotCreateComleted",
  BotConneсt: "BotConneсt",
  BotConneсtComleted: "BotConneсtComleted",
};

export const BotTabsEvents = {
  BotCreateTab: "BotCreateTab",
  BotConneсtTab: "BotConneсtTab",
};

export const BotFlowEvents = {
  BotNewFlow: "BotNewFlow",
};

export const BotInteractionEvents = {
  BotView: "BotView",
  BotRefresh_Token: "BotRefresh_Token",
  BotRefresh_Token_Confirm: "BotRefresh_Token_Confirm",
  BotRefresh_Token_Completed: "BotRefresh_Token_Completed",
};

export const BotDeleteEvents = {
  BotDelete: "BotDelete",
  BotDeleteCheckBox: "BotDeleteCheckBox",
  BotDeleteCancel: "BotDeleteCancel",
  BotDeleteConfirm: "BotDeleteConfirm",
  BotDeleteCompleted: "BotDeleteCompleted",
  BotDeleteFailed: "BotDeleteFailed",
};

export const BotCommandsEvents = {
  BotCommands: "BotCommands",
  BotCommandsActivate: "BotCommandsActivate",
  BotCommandDeactivate: "BotCommandDeactivate",
  BotCommandsInteract: "BotCommandsInteract",
  BotCommandsNew: "BotCommandsNew",
  BotCommandsNewCompleted: "BotCommandsNewCompleted",
  BotCommandsNewCreate: "BotCommandsNewCreate",
  BotCommandsCreateFailed: "BotCommandsCreateFailed",
  BotCommandsNewCancel: "BotCommandsNewCancel",
  BotCommandsDelete: "BotCommandsDelete",
  BotCommandsDeleteComplete: "BotCommandsDeleteComplete",
};

export const BotFieldsEvents = {
  BotFields: "BotFields",
  BotFieldsNew: "BotFieldsNew",
  BotFieldsCreate: "BotFieldsCreate",
  BotFieldsType: "BotFieldsType",
  BotFieldsCancel: "BotFieldsCancel",
  BotFieldsCreateComplete: "BotFieldsCreateComplete",
  BotFieldsCreateFailed: "BotFieldsCreateFailed",
  BotFieldsDelete: "BotFieldsDelete",
  BotFieldsDeleteCancel: "BotFieldsDeleteCancel",
  BotFieldsDeleteComplete: "BotFieldsDeleteComplete",
};
