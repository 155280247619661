import {
  Button,
  Flex,
  Text,
  Box,
  Skeleton,
  Icon,
  AccordionButton,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import s from "./GroupChatInfo.module.scss";
import { GroupChatInfoAvatar } from "./components/GroupChatInfoAvatar/GroupChatInfoAvatar";
import { ReactComponent as ContactsIcon } from "../../../../../../assets/icons/sidebar/contactsIcon.svg";
import { ReactComponent as BackArrowIcon } from "../../../../../../assets/icons/backArrowIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/icons/cross.svg";
import { ReactComponent as StopIcon } from "../../../../../../assets/icons/removeTimeIcon.svg";
import { ReactComponent as ProfanityFilterIcon } from "../../../../../../assets/icons/profanityFilterIcon.svg";
import { ReactComponent as ImageChatIcon } from "../../../../../../assets/icons/imageChatIcon.svg";
import { ReactComponent as FileIcon } from "../../../../../../assets/icons/fileIcon.svg";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { getGroupChatInfo, selectGroupChatInfo, setIsOpenGroupChatInfo } from "./GroupChatInfoSlice";
import { useParams } from "react-router-dom";
import { selectGroupChatList } from "../../../GroupChatList/GroupChatListSlice";
import { useGetLoadingState } from "../../../../../../common/loading/hooks/useGetLoadingState";

export const GroupChatInfo = () => {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const [accordionText, setAccordionText] = useState("Show");
  const dispatch = useAppDispatch();
  const groupChatInfo = useAppSelector(selectGroupChatInfo);
  const params = useParams();
  const id = params["conversationId"];
  const groupChatList = useAppSelector(selectGroupChatList);
  const groupId = groupChatList && groupChatList.items.find(el => el.id === id)?.groupId;
  const loadingGroupChatInfo = useGetLoadingState("getGroupChatInfo");

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupChatInfo(groupId));
    }
    return () => {
      dispatch(setIsOpenGroupChatInfo(false));
    };
  }, [groupId]);

  return (
    <Flex className={s.contaier}>
      <Icon
        className={s.crossIcon}
        as={CrossIcon}
        boxSize="42px"
        onClick={() => {
          dispatch(setIsOpenGroupChatInfo(false));
        }}
      />
      <GroupChatInfoAvatar contactInfo={groupChatInfo} />
      <Flex flexDir="column" pt="24px" gap="24px" overflow="auto">
        {false && (
          <>
            <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
              <Button
                className={s.buttonInfo}
                borderRadius="8px"
                border="1px solid var(--chakra-colors-newGrey)"
                w="100%"
                leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={ContactsIcon} />}
                rightIcon={<Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />}
              >
                <Flex w="100%" align="center" justifyContent="space-between">
                  <Text variant="medium">Members</Text>
                  <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                    {groupChatInfo?.membersCount}
                  </Text>
                </Flex>
              </Button>
            </Skeleton>
            <Flex flexDirection="column" gap="8px">
              <Text variant="large">Useful functions</Text>
              <Flex borderRadius="8px" overflow="hidden" border="1px solid var(--chakra-colors-newGrey)" flexDir="column">
                <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                  <Button
                    className={`${s.buttonInfo} ${s.buttonFirst}`}
                    borderRadius="0px"
                    w="100%"
                    leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={ProfanityFilterIcon} />}
                    rightIcon={
                      <Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />
                    }
                  >
                    <Flex w="100%" align="center" justifyContent="space-between">
                      <Text variant="medium">Profanity filter</Text>
                      <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                        Вкл
                      </Text>
                    </Flex>
                  </Button>
                </Skeleton>
                <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                  <Button
                    className={s.buttonInfo}
                    borderRadius="0px"
                    w="100%"
                    leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={StopIcon} />}
                    rightIcon={
                      <Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />
                    }
                  >
                    <Flex w="100%" align="center" justifyContent="space-between">
                      <Text variant="medium">Access control</Text>
                      <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                        Выкл
                      </Text>
                    </Flex>
                  </Button>
                </Skeleton>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap="8px">
              <Text variant="large">Files</Text>
              <Flex borderRadius="8px" overflow="hidden" border="1px solid var(--chakra-colors-newGrey)" flexDir="column">
                <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                  <Button
                    className={`${s.buttonInfo} ${s.buttonFirst}`}
                    borderRadius="0px"
                    w="100%"
                    leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={ImageChatIcon} />}
                    rightIcon={
                      <Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />
                    }
                  >
                    <Flex w="100%" align="center" justifyContent="space-between">
                      <Text variant="medium">Media</Text>
                      <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                        184
                      </Text>
                    </Flex>
                  </Button>
                </Skeleton>
                <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                  <Button
                    className={s.buttonInfo}
                    borderRadius="0px"
                    w="100%"
                    leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={FileIcon} />}
                    rightIcon={
                      <Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />
                    }
                  >
                    <Flex w="100%" align="center" justifyContent="space-between">
                      <Text variant="medium">Documents</Text>
                      <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                        64
                      </Text>
                    </Flex>
                  </Button>
                </Skeleton>
              </Flex>
            </Flex>
          </>
        )}
        <Flex flexDirection="column" gap="8px">
          <Text>{groupChatInfoTranslation("Group information")}</Text>
          <Accordion
            variant="stepsAccordion"
            onChange={e => (e.valueOf() === -1 ? setAccordionText("Show") : setAccordionText("Hide"))}
            allowToggle
          >
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {commonWords(accordionText)}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel display="flex" flexDirection="column" p="8px" gap="8px">
                <Flex flexDirection="column" gap="4px">
                  <Text color="var(--chakra-colors-darkGrey)" variant="small">
                    {groupChatInfoTranslation("Description")}
                  </Text>
                  <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                    <Text color={groupChatInfo?.description ? "" : "grey"} variant="medium">
                      {groupChatInfo?.description || groupChatInfoTranslation("No description")}
                    </Text>
                  </Skeleton>
                </Flex>
                {false && (
                  <Flex flexDirection="column" gap="4px">
                    <Text color="var(--chakra-colors-darkGrey)" variant="small">
                      {groupChatInfoTranslation("Administrators")}
                    </Text>
                    {[1, 2, 3].map(el => (
                      <Text key={el} variant="medium">
                        Hukuma_bot
                      </Text>
                    ))}
                  </Flex>
                )}
                <Flex flexDirection="column" gap="4px">
                  <Text color="var(--chakra-colors-darkGrey)" variant="small">
                    {groupChatInfoTranslation("Сhat_id")}
                  </Text>
                  <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
                    <Text variant="medium">{groupChatInfo?.externalId || "0000000000000"}</Text>
                  </Skeleton>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Flex>
    </Flex>
  );
};
