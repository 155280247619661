import { Flex, Button } from "@chakra-ui/react";
import { closeConversation } from "../../../conversation/ConversationSlice";
import s from "../../ContactInfo.module.scss";
import { ContactInfoAssignedOperator } from "./components/ContactInfoAssignedOperator/ContactInfoAssignedOperator";
import { ContactInfoAssignedTeam } from "./components/ContactInfoAssignedTeam/ContactInfoAssignedTeam";
import { AssignedItemModel, ConversationInfoModel } from "../../../conversation/ConversationModel";
import { useAppDispatch } from "../../../../common/state/store";
import { Dispatch, SetStateAction } from "react";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../../../organisation/OrganisationModel";
import { useTranslation } from "react-i18next";
import GTM from "../../../../common/ga/GAEventTracker";
import { ChatSettings } from "../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  onMenuClose: (type: "Team" | "Operator") => void;
  getAdditionalItems: (item: "Team" | "Operator") => void;
  assignState: {
    team?: AssignedItemModel | undefined;
    operator?: AssignedItemModel | undefined;
  };
  conversationInfo: ConversationInfoModel | undefined;
  onSearchTextChange: (value: string, type: "Team" | "Operator") => void;
  operatorSearchValue: string;
  teamSearchValue: string;
  setAssignState: Dispatch<
    SetStateAction<{
      team?: AssignedItemModel | undefined;
      operator?: AssignedItemModel | undefined;
    }>
  >;
  teams: OrganisationTeamsModel | undefined;
  operators: OrganisationUsersModel | undefined;
}

export default function ContactInfoAssigned({
  onMenuClose,
  getAdditionalItems,
  assignState,
  conversationInfo,
  onSearchTextChange,
  operators,
  setAssignState,
  teamSearchValue,
  teams,
  operatorSearchValue,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const trackEvent = GTM(EventCategories.Chats);

  return (
    <Flex className={s.assignContainer} flexDir={"column"} justifyContent={"center"}>
      <ContactInfoAssignedTeam
        assignState={assignState}
        onMenuClose={onMenuClose}
        teamSearchValue={teamSearchValue}
        getAdditionalItems={getAdditionalItems}
        onSearchTextChange={onSearchTextChange}
        setAssignState={setAssignState}
        teams={teams}
        isDisabled={conversationInfo?.isBlocked}
      />
      <ContactInfoAssignedOperator
        assignState={assignState}
        onMenuClose={onMenuClose}
        getAdditionalItems={getAdditionalItems}
        onSearchTextChange={onSearchTextChange}
        operatorSearchValue={operatorSearchValue}
        setAssignState={setAssignState}
        operators={operators}
        isDisabled={conversationInfo?.isBlocked}
      />
      <Button
        mt="16px"
        isDisabled={conversationInfo?.status === "Closed"}
        variant="dominoOutlineRed"
        flexShrink={0}
        onClick={() => {
          dispatch(closeConversation({ conversationId: conversationInfo?.id ?? "" }));
          trackEvent(ChatSettings.ChatStopConversation);
        }}
        data-pw="close-conversation-btn"
      >
        {conversationInfo?.status === "Closed" ? t("Conversation is closed") : t("Сlose Сonversation")}
      </Button>
    </Flex>
  );
}
