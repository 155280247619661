import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { GroupChatPageHeader } from "./GroupChatPageHeader";
import { GroupChatContainer } from "../../../groupChat/GroupChatContainer";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { useNowWidthView } from "../../LayoutHelper/ResolutionHooks";
import { TABLET_VIEW, MOBILE_VIEW } from "../../LayoutHelper/ResolutionConst";
import s from "./GroupChatPage.module.scss";

export const GroupChatPage = () => {
  const nowWidth = useNowWidthView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  return (
    <Flex className={s.groupPageContainer}>
      <ProtectedHeaderContainer>
        <GroupChatPageHeader />
      </ProtectedHeaderContainer>
      <Box
        bg="bgLight"
        height={
          nowWidth <= TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "180px" : "120px"})`
            : "calc(100dvh - 60px)"
        }
      >
        <GroupChatContainer />
      </Box>
    </Flex>
  );
};
