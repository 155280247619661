import {
  ShoppingCartFlowActionModel,
  FlowActionModel,
  NodeModel,
  NodeValidationProp,
  PaymentFlowActionModel,
} from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  // DrawerFooter,
  DrawerHeader,
  Flex,
  Box,
  Text,
  Link,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { Fallback } from "../Fallback";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { ButtonsList } from "../ButtonsList";
import { getButtonsWithExactType } from "../../../utils";
import { ButtonModel, ReplyKeyboardButtonModel } from "../../../../../common/AppButtonsModel";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import {
  clearYamlFile,
  createCustomVariable,
  editNode,
  loadYamlFile,
  selectCustomVariables,
  selectCustomVariablesWithoutSystem,
  selectIsAvaliableShoppingCartFile,
} from "../../../FlowBuilderSlice";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/icons/fileIcon.svg";
import { ReactComponent as PlayIcon } from "../../../../../assets/icons/Play.svg";

import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import s from "./CartNodePopup.module.scss";
import { useState, DragEvent, ChangeEvent } from "react";
import { notify } from "../../../../../common/notifications/NotificationSlice";
import { CloseIcon } from "@chakra-ui/icons";
import { selectLanguage } from "../../../../../common/user/UserSlice";
import { getOrganisationIdFromStorage } from "../../../../../common/BaseApi";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import { AppSettings } from "../../../../../common/AppSettings";

declare const appSettings: AppSettings;

const shoppingCartUiName = appSettings.uiShoppingCartUrl;

interface Props {
  flowAction: FlowActionModel;
  node: NodeModel;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  addCustomVariableText: (variable: CustomVariableModel, variableMaxLength: number) => void;
  onClose: () => void;
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  deleteButton: (buttonId: string) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
}
const MAX_SIZE_10MB = 20971520;

export default function CartNodePopup(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = props.flowAction as ShoppingCartFlowActionModel;
  const currentBot = useAppSelector(selectCurrentBot);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lng = useAppSelector(selectLanguage);
  const dispatch = useAppDispatch();
  const customVariables = useAppSelector(selectCustomVariablesWithoutSystem);
  const [isDragging, setDragging] = useState(false);
  const isAvaliableShoppingCartFile = useAppSelector(selectIsAvaliableShoppingCartFile);

  const variables = useAppSelector(selectCustomVariables);

  const chosenCustomVariable = variables?.find(el => {
    const targetCustomVariableId = flowAction.targetCustomVariableId;
    return el.id === targetCustomVariableId;
  });
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!flowAction.fileName) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  const deleteFile = () => {
    props.onDataChange({ ...flowAction, fileName: undefined } as ShoppingCartFlowActionModel);
    dispatch(clearYamlFile());
  };

  const addFile = (fileName: string) => {
    props.onDataChange({ ...flowAction, fileName: fileName } as ShoppingCartFlowActionModel);
  };

  const chooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];

    if (file) {
      const [, type] = file.name.split(".");

      if (type !== "yaml") {
        dispatch(
          notify({
            message: t("Select a YML file"),
            type: "error",
          }),
        );
      } else {
        if (file && file.size && file.size <= MAX_SIZE_10MB) {
          dispatch(loadYamlFile({ file: e.target?.files?.[0] ?? null, addFile: addFile }));
        } else if (file && file.size && file.size > MAX_SIZE_10MB) {
          dispatch(notify({ message: t("File is too big"), type: "error" }));
        }
      }
    }
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [, ext] = file.type ? file.type.split("/") : file.name.split(".");
      if (ext === "yaml" || ext === "x-yaml") {
        if (file && file.size && file.size <= MAX_SIZE_10MB) {
          dispatch(loadYamlFile({ file: file ?? null, addFile: addFile }));
        } else if (file && file.size && file.size > MAX_SIZE_10MB) {
          dispatch(notify({ message: t("File is too big"), type: "error" }));
        }
      } else {
        dispatch(notify({ message: t("Select a YML file"), type: "error" }));
      }
    }
  };

  const setCustomVariable = (variableId: string) => {
    props.validate({
      ...flowAction,
      targetCustomVariableId: variableId,
    } as ShoppingCartFlowActionModel);
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          targetCustomVariableId: variableId,
        } as ShoppingCartFlowActionModel,
      }),
    );
  };

  const selectVariable = (variable: CustomVariableModel) => {
    if (variable.id) {
      setCustomVariable(variable.id);
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };
  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <Flex h="44px" gap="8px">
            <DrawerHeader flex={1}>{t("Catalog")}</DrawerHeader>
          </Flex>
          <DrawerBody>
            <MessageTextEditor
              variables={variables || []}
              text={flowAction.text ?? ""}
              maxLength={2000}
              onChange={t =>
                props.onDataChange({
                  ...flowAction,
                  text: t,
                } as PaymentFlowActionModel)
              }
              validate={props.validate}
              flowAction={flowAction}
              addCustomVariableText={props.addCustomVariableText}
              borderRadiusTop="8px"
              borderRadiusBottom="8px"
              maxHeightWithoutScroll={500}
            />
            <Box mt="12px">
              <ButtonsList
                node={props.node}
                buttons={
                  (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                    | ButtonModel[][]
                    | ReplyKeyboardButtonModel[][]) ?? []
                }
                flowAction={flowAction}
                setButtons={props.setButtons}
                addButton={props.addButton}
                deleteButton={props.deleteButton}
                editButton={props.editButton}
              />
            </Box>
            <Box mt="24px">
              <Text variant="medium">{t("editNodePopup.Select the YML file containing your data")}</Text>
              <Link
                className={s.downloadLink}
                href={
                  lng === "ru"
                    ? "https://cdn.domino-crm.com/catalog_template_ru.yaml"
                    : "https://cdn.domino-crm.com/catalog_template_en.yaml"
                }
                data-pw="download-YML"
              >
                {t("editNodePopup.Download a sample YML file")}
              </Link>
            </Box>
            <Box mt="12px">
              {isAvaliableShoppingCartFile ? (
                <Box bg="#EFF5FE" className={s.dragAndDropBox}>
                  <Text variant="largeBold">{t("editNodePopup.Catalog Uploaded")}</Text>
                  <Button
                    data-pw="preview"
                    onClick={onOpen}
                    leftIcon={<PlayIcon height="20px" width="20px" />}
                    variant="dominoViolet"
                  >
                    {t("editNodePopup.Preview")}
                  </Button>
                  <Box cursor="pointer" onClick={deleteFile} className={s.deleteIcon} data-pw="deleteFileIcon">
                    <CloseIcon boxSize="10px" stroke={"#8592A3"} color={"#8592A3"} />
                  </Box>
                </Box>
              ) : (
                <Box
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={onDropFile}
                  className={s.dragAndDropBox}
                  bg={isDragging ? "#EFF5FE" : "white"}
                >
                  <Loading scope="loadYamlFile" />
                  <Icon color="#DCE7FB" as={FileIcon} boxSize="32px" />
                  <Flex flexDirection="column" align="center">
                    <Flex gap="4px">
                      <Text variant="medium">{t("editNodePopup.Drop file here")}</Text>
                      <Text variant="medium" color="darkGrey">
                        {t("editNodePopup.or")}
                      </Text>
                    </Flex>
                    <Button
                      h="max-content"
                      cursor="pointer"
                      bg="transparent"
                      _hover={{ bg: "transparent" }}
                      htmlFor="file-upload"
                      as="label"
                      data-pw="selectFile"
                    >
                      <Text color="#325EE6" variant="medium">
                        {t("editNodePopup.select file")}
                      </Text>
                    </Button>
                    <Input
                      display="none"
                      id="file-upload"
                      value=""
                      onChange={chooseFile}
                      variant="unstyled"
                      type="file"
                      accept=".yaml"
                      data-pw="inputFile"
                    />
                  </Flex>
                </Box>
              )}
            </Box>
            <Box mt="24px">
              <h4>{t("editNodePopup.Save Response to a Custom Field")}</h4>
              <Box mt="8px">
                <CustomVariableContextMenu
                  mt="0px"
                  selectCustomVariable={selectVariable}
                  clearVariable={() => setCustomVariable("")}
                  type={CustomVariableContextMenuType.Dropdown}
                  addVariable={onCreateVariable()}
                  variables={customVariables?.filter(el => el.type === CustomVariableType.Order) || []}
                  chosenVariable={chosenCustomVariable}
                  onlyVariableType={CustomVariableType.Order}
                />
                {chosenCustomVariable && (
                  <Box position={"absolute"} right={"5px"} bottom={"7px"} onClick={() => setCustomVariable("")}>
                    <CrossIcon opacity={0} cursor={"pointer"} className={s.crossIcon} />
                  </Box>
                )}
              </Box>
            </Box>
            <Fallback
              isNodeFallback
              validate={props.validate}
              flowAction={flowAction}
              dataChange={props.onDataChange}
              maxLength={500}
              text={""}
              data-pw="cartFallback"
              onChange={e =>
                props.onDataChange({
                  ...flowAction,
                  fallbackMessage: e,
                } as PaymentFlowActionModel)
              }
            />
          </DrawerBody>
          <Button variant="dominoViolet" w={"auto"} margin={"24px 16px 16px 16px"} onClick={props.onClose} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay zIndex={10001} />
        <ModalContent
          overflow="hidden"
          borderRadius="24px"
          containerProps={{
            zIndex: "10001",
          }}
          h="80dvh"
          maxH="670px"
          w="375px"
        >
          <ModalHeader borderBottom="1px solid #F1F4F9" p="10px 20px">
            <Text variant="h3">{currentBot?.name}</Text>
          </ModalHeader>

          <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody p="0px">
            <iframe
              title="cart-iframe"
              src={`${shoppingCartUiName}/draft?orgId=${getOrganisationIdFromStorage()}&lng=${lng}`}
              name="iframe985426"
              width="100%"
              height="100%"
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
