/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";

export interface GroupChatState {
  name?: string;
}

const initialState: GroupChatState = {};

export const groupChatSlice = createSlice({
  name: "groupChatSlice",
  initialState,
  reducers: {
    getBots: (state, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
  },
});

export const { getBots } = groupChatSlice.actions;

export const selectBots = (state: RootState) => state.app.dashboardState.botList;

export default groupChatSlice.reducer;
