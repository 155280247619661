import { formatDateTimeToString } from "../../common/utils/formatDate";
import { SchedulerTriggerData } from "../flowBuilder/FlowBuilderData";
import { ScheduleTriggerTypeDiscriminator, TriggerTypeDiscriminator } from "../flowBuilder/FlowBuilderModel";
import { FlowListData, TriggerListData, UsedFlowsInFlowData } from "./AutomationData";
import { UsedFlowModel } from "./AutomationModel";

export const getBotFlows = (resData: FlowListData, botId: string) => {
  const newItems = resData.items.filter(el => el.botId === botId);
  return { ...resData, items: newItems };
};

export const getTriggerModel = (data: TriggerListData) => {
  const triggers = data.items.map(el => {
    if (el.trigger.typeDiscriminator === TriggerTypeDiscriminator.Schedule) {
      const triggerScheduler = el.trigger as SchedulerTriggerData;
      const schedulerTypeDiscriminator = triggerScheduler.scheduler.typeDiscriminator;
      const isOnceTypeDiscriminator = schedulerTypeDiscriminator === ScheduleTriggerTypeDiscriminator.once;
      let scheduledTime;
      let scheduledValue;
      if (isOnceTypeDiscriminator) {
        scheduledTime = isOnceTypeDiscriminator ? formatDateTimeToString(new Date(triggerScheduler.scheduler.value)) : null;
        scheduledValue = undefined;
      } else {
        scheduledTime = null;
        scheduledValue = triggerScheduler.scheduler.value;
      }

      return {
        ...el,
        trigger: { ...el.trigger, scheduler: { ...triggerScheduler.scheduler, scheduledTime, value: scheduledValue } },
      };
    }
    return el;
  });
  const result = { ...data, items: [...triggers] };
  return result;
};

export const mapUsedFlowsInFlowData = (data: UsedFlowsInFlowData) => {
  const flows: UsedFlowModel[] = [];
  for (const flow in data) {
    flows.push(data[flow]);
  }
  return flows;
};
