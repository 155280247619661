import { Grid, GridItem } from "@chakra-ui/react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getTokenFromStorage } from "../../common/auth/AuthRepo";
import { getBillingPlan, getProfile, selectIsSuperAdmin } from "../../common/user/UserSlice";
import { RouterContainer } from "../../common/router/RouterContainer";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { SidebarContainer } from "../sidebar/SidebarContainer";
import { getOrganisation, setOrganization } from "../organisation/OrganisationSlice";

export function ProtectedLayout(props: { children: React.ReactNode }) {
  const token = getTokenFromStorage();
  const currentUrl = window.location.pathname + window.location.search;
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const dispatch = useAppDispatch();
  const organizationId = new URLSearchParams(window.location.search).get("organizationId");

  useEffect(() => {
    if (organizationId) {
      dispatch(setOrganization(organizationId));
      const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
      const newOrganisationId: { [key: string]: string } = sessionOrgId ? JSON.parse(sessionOrgId) : {};
      const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
      newOrganisationId[username] = organizationId;
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    }
    dispatch(getOrganisation());
    dispatch(getProfile());
    dispatch(getBillingPlan());

    saveUrlToSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveUrlToSessionStorage = () => sessionStorage.setItem("currentUrl", currentUrl);

  return (
    <>
      <RouterContainer>
        {token ? (
          <Grid h="100dvh" templateColumns={{ base: "minmax(0, 1fr) auto", md: "auto minmax(0, 1fr)" }}>
            <GridItem rowSpan={2} gridColumn={{ base: "2", md: "1" }}>
              <SidebarContainer isSuperAdmin={isSuperAdmin} />
            </GridItem>

            <Grid
              h="100%"
              templateColumns={{ base: "1fr", md: "100%" }}
              gridRow={{ base: "1/3", md: "span 2" }}
              overflow={{ base: "hidden", md: "visible" }}
            >
              {props.children}
            </Grid>
          </Grid>
        ) : (
          <Navigate to="/auth" />
        )}
      </RouterContainer>
    </>
  );
}
