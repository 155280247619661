import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { getTriggerList, selectTriggerList } from "../AutomationSlice";
import { Box } from "@chakra-ui/react";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../../sidebar/SidebarSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TriggerList } from "./TriggerList";
import { useEffect } from "react";
import { PaginatorWithSearchParams } from "../../../common/paginator/components/PaginatorWithSearchParams";
import { EventCategories } from "../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useGAWithCustomParameters } from "../../../common/ga/GAEventTracker";
import { FlowEvents } from "../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

export const TriggerListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);

  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const triggerList = useAppSelector(selectTriggerList);

  const paginatorData = { ...triggerList, items: triggerList.items || [] };

  const [searchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);

  useEffect(() => {
    if (currentBot) {
      dispatch(getTriggerList({ botId: currentBot.id, page: currentPage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentBot]);

  useEffect(() => {
    if (triggerList) {
      trackEvent(FlowEvents.FlowTriggerUpload, "", { pagesCount: triggerList.totalPages });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerList]);

  if (!currentBot && !isCurrentBotLoading) {
    navigate("flows");
    return null;
  }

  if (triggerList?.totalItems === 0) {
    navigate("flows");
    return null;
  }

  return (
    <>
      <Box padding="0px 16px 32px 16px" pb={"12px"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        <>
          <TriggerList currentPage={currentPage} triggerList={triggerList} currentBot={currentBot} />
          <PaginatorWithSearchParams
            loadingScope="getTriggerList"
            sizeItems={10}
            data={paginatorData}
            typeOfPage={"TriggerList"}
          />
        </>
      </Box>
    </>
  );
};
