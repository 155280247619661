import {
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  List,
  ListItem,
  Link,
} from "@chakra-ui/react";
import s from "../AddFirstGroupChatContainer.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BotFatherLink } from "../../../../../UI/atoms/botFatherLink/BotFatherLink";
import { InstructionStep } from "../../../../../UI/atoms/instructionStep/InstructionStep";
import { TranslationWithStrongWord } from "../../../../../UI/atoms/textTranslation/TextTranslation";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";

export const AddBotStep = ({ action }: { action?: () => void }) => {
  const groupTranslation = useTranslation("translation", { keyPrefix: "groupChat.addingBot" }).t;
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [accordionText, setAccordionText] = useState("Show");
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <>
      <Link
        className={s.link}
        isExternal
        href={`https://t.me/${currentBot?.username}?startgroup&admin=change_info+post_messages+edit_messages+delete_messages+restrict_members+invite_users+
pin_messages+manage_topics+promote_members+manage_chat`}
        w="100%"
        variant="dominoViolet"
      >
        {groupTranslation("Select a group for Telegram")}
      </Link>
      {/* <Flex flexDirection="column" w="100%" gap="12px">
        <Separator>
          <Text variant="medium" px="8px">
            {commonWords("or")}
          </Text>
        </Separator>
        <Button w="100%" variant="dominoOutlineViolet">
          {groupTranslation("Send a link to your phone")}
        </Button>
      </Flex> */}
      <Flex mt="8px" w="100%" flexDirection="column" gap="12px">
        <Text variant="large" fontWeight={500}>
          {groupTranslation("Instructions for adding bots")}
        </Text>
        <Accordion
          variant="stepsAccordion"
          onChange={e => (e.valueOf() === -1 ? setAccordionText("Show") : setAccordionText("Hide"))}
          allowToggle
        >
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {commonWords(accordionText)}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <List className={s.instructionStepsList} spacing={4}>
                <ListItem className={s.listItem}>
                  <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={1}>
                    <BotFatherLink i18nKey="groupChat.addingBot.First step" />
                  </InstructionStep>
                </ListItem>
                <ListItem className={s.listItem}>
                  <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={2}>
                    <TranslationWithStrongWord i18nKey="groupChat.addingBot.Seconde step">
                      <Text as="span" className={s.strongWord} />
                    </TranslationWithStrongWord>
                  </InstructionStep>
                </ListItem>
                <ListItem className={s.listItem}>
                  <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={3}>
                    <TranslationWithStrongWord i18nKey="groupChat.addingBot.Third step">
                      <Text as="span" className={s.strongWord} />
                    </TranslationWithStrongWord>
                  </InstructionStep>
                </ListItem>
                <ListItem className={s.listItem}>
                  <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={3}>
                    <TranslationWithStrongWord i18nKey="groupChat.addingBot.Fourth step">
                      <Text as="span" className={s.strongWord} />
                    </TranslationWithStrongWord>
                  </InstructionStep>
                </ListItem>
                <ListItem className={s.listItem}>
                  <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={3}>
                    <TranslationWithStrongWord i18nKey="groupChat.addingBot.Fifth step">
                      <Text as="span" className={s.strongWord} />
                    </TranslationWithStrongWord>
                  </InstructionStep>
                </ListItem>
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
};
