import { Flex, Icon, List, ListItem, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BotFatherLink } from "../../../../UI/atoms/botFatherLink/BotFatherLink";

import { ReactComponent as warningIcon } from "../../../../assets/icons/attention.svg";

import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";
import { InstructionStep } from "../../../../UI/atoms/instructionStep/InstructionStep";
import { TranslationWithStrongWord } from "../../../../UI/atoms/textTranslation/TextTranslation";

export const ConnectBotTab = () => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  return (
    <>
      <Text variant="h2">{t("Connection instruction")}</Text>
      <Flex className={s.warningBlock}>
        <Icon className={s.warningIcon} as={warningIcon} data-pw="warning-icon" />
        {t("Connect Bot warning")}
      </Flex>
      <List className={s.instructionStepsList} spacing={4}>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={1}>
            <BotFatherLink i18nKey="addBot.First connect bot instruction step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={2}>
            <TranslationWithStrongWord i18nKey="addBot.Second connect bot instruction step">
              <Text as="span" className={s.strongWord} />
            </TranslationWithStrongWord>
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep flexProps={{ className: s.instructionStep }} stepNumber={3}>
            <TranslationWithStrongWord i18nKey="addBot.Third connect bot instruction step">
              <Text as="span" className={s.strongWord} />
            </TranslationWithStrongWord>
          </InstructionStep>
        </ListItem>
      </List>
    </>
  );
};
