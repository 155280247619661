import { useTranslation } from "react-i18next";
import { ChangeEvent, Dispatch, forwardRef, SetStateAction } from "react";
import { customDateInput, DatePicker } from "../../../../../UI/atoms/datePicker/DatePicker";
import { BooleanVariableValues, CustomVariableType } from "../../../../../common/AppEnums";
import s from "../EditContact.module.scss";
import dayjs from "dayjs";
import { Flex, Input, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ContactVariableModel } from "../../../ContactModel";

interface DefaultProps {
  contactVar: ContactVariableModel;
  getVariableValuePlaceholder: (type: CustomVariableType) => string;
  isMobile: boolean;
}

interface StateProps {
  variablesText: Record<string, unknown>;
  setVariablesText: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  valueMobileModal: string | null;
  setValueMobileModal: Dispatch<SetStateAction<string | null>>;
}

interface HandlerProps {
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlur: (
    e: React.FocusEvent<HTMLInputElement>,
    contactVar: ContactVariableModel,
    variablesText: Record<string, unknown>,
  ) => void;
}

type Props = DefaultProps & HandlerProps & StateProps;

export const EditContactValueInputMobile = (props: Props) => {
  const t = useTranslation("translation", { keyPrefix: "contact" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const CustomInput = forwardRef(customDateInput);
  const locale = localStorage.getItem("i18nextLng");

  function changeDatePicker(date: string | undefined) {
    props.setValueMobileModal(date ?? "");
  }

  function changeDate(date: string | undefined) {
    props.setValueMobileModal(date ?? "");
  }

  function changeBoolean(el: string) {
    props.setValueMobileModal(el);
  }

  function changeNumber(e: ChangeEvent<HTMLInputElement>) {
    props.setValueMobileModal(e.target.value);
  }

  function changeText(e: ChangeEvent<HTMLInputElement>) {
    props.setValueMobileModal(e.target.value);
  }

  switch (props.contactVar?.type) {
    case CustomVariableType.DateTime: {
      return (
        <DatePicker
          dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
          wrapperClassName={s.contactDatePicker}
          selected={
            props.valueMobileModal
              ? new Date(props.valueMobileModal)
              : (props.variablesText[props.contactVar.id] as string)
              ? new Date(props.variablesText[props.contactVar.id] as string)
              : null
          }
          onChange={scheduledDate => {
            changeDatePicker(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DDTHH:mmZZ") : undefined);
          }}
          placeholderText={`${t("Not set")}`}
          showTimeSelect
          customInput={<CustomInput ml="-16px" borderColor={"line"} placeholdertext={ct("placeholderDateTime")} />}
          strictParsing
          shouldCloseOnSelect={true}
        />
      );
    }
    case CustomVariableType.Date: {
      return (
        <DatePicker
          wrapperClassName={s.contactDatePicker}
          dateFormat="dd.MM.yyyy"
          selected={
            props.valueMobileModal
              ? new Date(props.valueMobileModal)
              : (props.variablesText[props.contactVar.id] as string)
              ? new Date(props.variablesText[props.contactVar.id] as string)
              : null
          }
          onChange={scheduledDate => {
            changeDate(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : undefined);
          }}
          placeholderText={`${t("Not set")}`}
          customInput={
            <CustomInput
              ml="-16px"
              borderColor={props.isMobile ? "line" : "transparent"}
              placeholdertext={ct("placeholderDate")}
            />
          }
          strictParsing
          shouldCloseOnSelect={true}
        />
      );
    }
    case CustomVariableType.Boolean: {
      const borderColor = props.isMobile ? "line" : "transparent";
      return (
        <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
          <MenuButton
            h="36px"
            border="1px"
            borderRadius="8px"
            borderColor={borderColor}
            ml="-16px"
            color={"black"}
            bg="white"
            w="100%"
            _hover={{ bgColor: "white", borderColor: "mainPurple" }}
            onClick={e => e.stopPropagation()}
          >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Flex w={"90%"} alignItems={"center"} ml={"16px"}>
                {props.valueMobileModal
                  ? props.valueMobileModal
                  : props.variablesText[props.contactVar.id]
                  ? ct(props.variablesText[props.contactVar.id] as string)
                  : ct("Not Set")}
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList>
            {Object.keys(BooleanVariableValues).map((el, i) => {
              return (
                <MenuItem
                  key={i}
                  value={el}
                  onClick={() => {
                    changeBoolean(el !== BooleanVariableValues["Not Set"] ? el : "");
                  }}
                >
                  {ct(el)}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      );
    }
    case CustomVariableType.Number: {
      return (
        <Input
          ml="-16px"
          borderColor={props.isMobile ? "line" : "transparent"}
          bg="white"
          placeholder={`${t("Not set")}`}
          value={props.valueMobileModal ? props.valueMobileModal : (props.variablesText[props.contactVar.id] as string)}
          onKeyDown={e => props.handleKeyPress(e)}
          type={"text"}
          onChange={e => changeNumber(e)}
          onFocus={e => (e.target.placeholder = props.getVariableValuePlaceholder(props.contactVar.type))}
        />
      );
    }
    case CustomVariableType.Array:
    case CustomVariableType.Text:
    case CustomVariableType.Order: {
      return (
        <Input
          ml="-16px"
          borderColor={props.isMobile ? "line" : "transparent"}
          bg="white"
          placeholder={`${t("Not set")}`}
          value={props.valueMobileModal ? props.valueMobileModal : (props.variablesText[props.contactVar.id] as string)}
          onKeyDown={e => props.handleKeyPress(e)}
          type={props.contactVar.type}
          onChange={e => changeText(e)}
          onFocus={e => {
            e.target.placeholder = props.getVariableValuePlaceholder(props.contactVar.type);
          }}
        />
      );
    }
  }
};
